// src/components/FilterChips.js

import React, { FC } from 'react';
import { useListContext, useDataProvider } from 'react-admin';
import Chip from '@mui/material/Chip';
import { JSONTree } from 'react-json-tree';
// import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles({
//     chip: {
//         margin: '0 4px 4px 0',
//     },
// });
interface FilterChipsProps {
  excludeKeys?: string[];
  searchKeysName?: string[]
}
const FilterChips: FC<FilterChipsProps> = ({ excludeKeys = [], searchKeysName = ['q'] }) => {
  const { filterValues, setFilters } = useListContext();
  // const classes = useStyles();

  const handleDelete = (filterName) => {
    const newFilters = { ...filterValues };
    delete newFilters[filterName];
    setFilters(newFilters, []);
  };
  const getFilterLabel = (filterName: string, filterValue: any) => {


    // Check if the value is an array
    if (Array.isArray(filterValue)) {
      // Handle array of objects or primitives
      return filterValue
        .map((item) => (typeof item === 'object' && item !== null ? item.label : item))
        .join(', ');
    }

    // Check if the value is an object
    else if (typeof filterValue === 'object' && filterValue !== null) {
      // Handle single object
      return filterValue.label || JSON.stringify(filterValue);
    }

    // Handle primitive values
    return `${filterValue}`;
  };

  const MAX_LABEL_LENGTH = 100; // Define your desired maximum length

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };
  return (
    <div style={{ marginBottom: '1em', width: 'auto', height: 'auto', display: 'flex', flexWrap: 'wrap', gap: '0.5em' }}>
      {Object.keys(filterValues)
        .filter(fname => !excludeKeys?.includes(fname))
        .map(filterName => {

          const fullText = getFilterLabel(filterName, filterValues[filterName]);
          const statusMap = {
            "1,0": "Active,Inactive",
            "1": "Active",
            "0": "Inactive"
          };
          const trimmedFullText = fullText.replace(/\s+/g, '');
          let finalStatus = "";
          if (searchKeysName.includes(filterName))
            finalStatus = fullText;
          else
            finalStatus = statusMap[trimmedFullText] || fullText;

          console.log("fullText", fullText, filterName)
          // console.log("fullText", searchKeys.includes(filterValues),searchKeys)
          const truncatedText = truncateText(finalStatus, MAX_LABEL_LENGTH);
          return (
            <div >

              <Chip
                key={filterName}
                label={
                  <span
                    style={{ whiteSpace: 'normal', wordBreak: 'break-word', textOverflow: 'ellipsis' }}
                    title={fullText} // Show full text on hover
                  >
                    {truncatedText}
                  </span>
                }
                onDelete={() => handleDelete(filterName)}
              />
              <br />
              <br />
              <br />

            </div>
          );
        })}
    </div>

  );
};

export default FilterChips;
