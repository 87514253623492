import React, { useEffect } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Controller, useFormContext, useFieldArray } from "react-hook-form";
import { JsonToTable } from 'react-json-to-table';
import { JSONTree } from 'react-json-tree';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InputField2 from '../../common/InputComponents/InputField2';
import InputCurrencyField from '../components/inputComponents/InputCurrencyFiled';
import InputCurrencyFieldOrginal from '../../common/InputComponents/InputCurrencyFieldOrginal';
import InputSelectField from '../../common/InputComponents/InputSelectField';
import InputCheckBoxField from '../../common/InputComponents/InputCheckBoxField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';


function SummaryTable({ sourceEmpIndex, fileIndex }) {
    const [expanded, setExpanded] = React.useState<string | false>('');
    const { control, getValues, register, watch, setValue } = useFormContext();
    const { fields: earningFields, append: earningAppend, remove: earningRemove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: `employer.${sourceEmpIndex}.files.${fileIndex}.earnings`, // unique name for your Field Array
    });

    const { fields: deductionFields, append: deductionAppend, remove: deductionRemove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: `employer.${sourceEmpIndex}.files.${fileIndex}.deduction`, // unique name for your Field Array
    });

    const handleChangeCollapse =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };
       
    const eraningTOCalculateCurrent = watch(`employer.${sourceEmpIndex}.files.${fileIndex}.earnings`);
    const deductionCurrentCalCulate = watch(`employer.${sourceEmpIndex}.files.${fileIndex}.deduction`);


    useEffect(() => {
        let totalCurrent = 0;
        let totalYtd = 0;
        if (Array.isArray(eraningTOCalculateCurrent)) {
        for (let item of eraningTOCalculateCurrent) {
            // 
            totalCurrent += Number(item?.current);
            totalYtd += Number(item.ytd)
        }
    }
        setValue(`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${0}.calculatedCurrent`, parseFloat(totalCurrent.toString()).toFixed(2));
        setValue(`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${0}.calculatedYtd`, parseFloat(totalYtd.toString()).toFixed(2));
    }, [deductionCurrentCalCulate])


    useEffect(() => {
        let preTaxTotalCurrent = 0;
        let postTaxTotalCurrent = 0;
        let taxTotalCurrent = 0;

        let preTaxTotalYtd = 0;
        let postTaxTotalYtd = 0;
        let taxTotalYtd = 0;
        if (Array.isArray(deductionCurrentCalCulate)) {
        for (let item of deductionCurrentCalCulate) {
            if (item.deduction_type == 'Pre-Tax') {
                preTaxTotalCurrent += Number(item.current)
                preTaxTotalYtd += Number(item.ytd)
            } else if (item.deduction_type == 'Post-Tax') {
                postTaxTotalCurrent += Number(item.current)
                postTaxTotalYtd += Number(item.ytd)
            } else {
                taxTotalCurrent += Number(item.current)
                taxTotalYtd += Number(item.ytd)
            }
            
            // totalCurrent += Number(item?.current);
            // totalYtd += Number(item.ytd)
        }
    }
        setValue(`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${4}.calculatedCurrent`, parseFloat(preTaxTotalCurrent.toString()).toFixed(2));
        setValue(`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${5}.calculatedCurrent`, parseFloat(postTaxTotalCurrent.toString()).toFixed(2));
        setValue(`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${2}.calculatedCurrent`, parseFloat(taxTotalCurrent.toString()).toFixed(2));
        setValue(`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${4}.calculatedYtd`, parseFloat(preTaxTotalYtd.toString()).toFixed(2));
        setValue(`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${5}.calculatedYtd`, parseFloat(postTaxTotalYtd.toString()).toFixed(2));
        setValue(`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${2}.calculatedYtd`, parseFloat(taxTotalYtd.toString()).toFixed(2));
    }, [deductionCurrentCalCulate])

    return (
        <>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChangeCollapse('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Summary
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="row">
                        <div className='col-12'>
                            <div className="row bg-grey border">
                                <div className="col fw-bold small">Summary</div>
                                <div className="col fw-bold small">Displayed Current</div>
                                <div className="col fw-bold small">Calculated Current</div>
                                <div className="col fw-bold small">Displayed YTD</div>
                                <div className="col fw-bold small">Calculated YTD</div>
                            </div>
                            <div className="row border">
                                <div className="col bg-grey fw-bold">Gross Earnings</div>

                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${0}.displayedCurrent`} />
                                </div>
                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${0}.calculatedCurrent`} />
                                </div>
                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${0}.displayedYtd`} />
                                </div>
                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${0}.calculatedYtd`} />
                                </div>

                            </div>
                            <div className="row border">
                                <div className="col fw-bold small bg-grey">Pre-Tax Deductions</div>

                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${4}.displayedCurrent`} />
                                </div>
                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${4}.calculatedCurrent`} />
                                </div>
                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${4}.displayedYtd`} />
                                </div>
                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${4}.calculatedYtd`} />
                                </div>

                            </div>
                            <div className="row border">
                                <div className="col fw-bold small bg-grey">Taxes</div>

                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${2}.displayedCurrent`} />
                                </div>
                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${2}.calculatedCurrent`} />
                                </div>
                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${2}.displayedYtd`} />
                                </div>
                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${2}.calculatedYtd`} />
                                </div>

                            </div>
                            <div className="row border">
                                <div className="col fw-bold small bg-grey">Post-Tax Deductions</div>

                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${5}.displayedCurrent`} />
                                </div>
                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${5}.calculatedCurrent`} />
                                </div>
                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${5}.displayedYtd`} />
                                </div>
                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${5}.displayedYtd`} />
                                </div>

                            </div>
                            <div className="row border">
                                <div className="col fw-bold small bg-grey">Reimbursements</div>

                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${16}.displayedCurrent`} />
                                </div>
                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${16}.calculatedCurrent`} />
                                </div>
                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${16}.displayedYtd`} />
                                </div>
                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${16}.calculatedYtd`} />
                                </div>

                            </div>
                            <div className="row border">
                                <div className="col fw-bold small bg-grey">Net Pay</div>

                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${17}.displayedCurrent`} />
                                </div>
                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${17}.calculatedCurrent`} />
                                </div>
                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${17}.displayedYtd`} />
                                </div>
                                <div className="col p-1">
                                    <InputCurrencyFieldOrginal
                                        notRequired={true}
                                        labelRequired={false}
                                        label={``}
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.summaryTable.${17}.calculatedYtd`} />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-6">
                            <Stack spacing={2} direction="row">
                                <button
                                    onClick={() => {
                                        earningAppend({
                                            current: 0,
                                            ytd: 0
                                        })
                                    }}
                                    type='button'
                                    className="btn btn-primary m-2"
                                    style={{
                                        backgroundColor: 'rgb(45, 195, 232)',
                                        color: 'white',
                                        padding: '8px 15px 8px 15px',
                                    }}
                                > Add Earning</button>
                                {watch(`employer.${sourceEmpIndex}.files.${fileIndex}.earnings`)?.findIndex(item => item.selected == true) != '-1' && <button
                                    type='button'
                                    className="btn btn-primary m-2"
                                    style={{
                                        backgroundColor: 'red',
                                        color: 'white',
                                        padding: '8px 15px 8px 15px',
                                    }}
                                    onClick={() => {
                                        const list = getValues(`employer.${sourceEmpIndex}.files.${fileIndex}.earnings`)
                                        let i = 0;
                                        for (let item of list) {
                                            if (item.selected == true) {
                                                earningRemove(i)
                                            }
                                            i++;
                                        }
                                    }}
                                > Delete</button>}
                            </Stack>
                            <div className='row mx-1 border justify-content-start bg-grey'>
                                <div className="col-1"></div>
                                <div className="col-3 fw-bold">Earning</div>
                                <div className="col-3 fw-bold">Type</div>
                                <div className="col fw-bold small">Current</div>
                                <div className="col fw-bold small">YTD</div>
                            </div>
                            {earningFields.map((row: any, index) => (
                                <div key={row.id} className='row mx-1 border justify-content-start align-items-start pt-2'>
                                    <div className="col-1"> <InputCheckBoxField
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.earnings.${index}.selected`}
                                        notRequired={true}
                                    /></div>
                                    <div className="col-3">
                                        <InputField2 labelRequired={false} label={''} placeholder={''}
                                            source={`employer.${sourceEmpIndex}.files.${fileIndex}.earnings.${index}.earning_name`} />
                                    </div>
                                    <div className="col-3">
                                        <select style={{ fontSize: '14px' }} className='form-select' {...register(`employer.${sourceEmpIndex}.files.${fileIndex}.earnings.${index}.earning_type`)}>
                                            <option value={''} disabled>Select</option>
                                            <option value={'Recurring'}>Recurring</option>
                                            <option value={'One Time'}>One Time</option>
                                            <option value={'No Count'}>No Count</option>
                                        </select>
                                    </div>
                                    <div className="col">
                                        <InputCurrencyFieldOrginal
                                            notRequired={true}
                                            labelRequired={false}
                                            label={``}
                                            name={`employer.${sourceEmpIndex}.files.${fileIndex}.earnings.${index}.current`} />

                                    </div>
                                    <div className="col">
                                        <InputCurrencyFieldOrginal
                                            notRequired={true}
                                            labelRequired={false}
                                            label={``}
                                            name={`employer.${sourceEmpIndex}.files.${fileIndex}.earnings.${index}.ytd`} />

                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="col-6">
                            <Stack spacing={2} direction="row">
                                <button
                                    onClick={() => {
                                        deductionAppend({
                                            current: 0,
                                            ytd: 0
                                        })
                                    }}
                                    type='button'
                                    className="btn btn-primary m-2"
                                    style={{
                                        backgroundColor: 'rgb(45, 195, 232)',
                                        color: 'white',
                                        padding: '8px 15px 8px 15px',
                                    }}
                                > Add Deduction</button>
                                {watch(`employer.${sourceEmpIndex}.files.${fileIndex}.deduction`)?.findIndex(item => item.selected == true) != '-1' && <button
                                    type='button'
                                    className="btn btn-primary m-2"
                                    onClick={() => {
                                        const list = getValues(`employer.${sourceEmpIndex}.files.${fileIndex}.deduction`)
                                        let i = 0;
                                        for (let item of list) {
                                            if (item.selected == true) {
                                                deductionRemove(i)
                                            }
                                            i++;
                                        }
                                    }}
                                    style={{
                                        backgroundColor: 'red',
                                        color: 'white',
                                        padding: '8px 15px 8px 15px',
                                    }}
                                > Delete</button>}
                            </Stack>
                            <div className='row mx-1 border justify-content-start bg-grey'>
                                <div className="col-1"></div>
                                <div className="col-3 fw-bold small">Deduction</div>
                                <div className="col-3 fw-bold small">Type</div>
                                <div className="col fw-bold small">Current</div>
                                <div className="col fw-bold small">YTD</div>
                            </div>
                            {deductionFields.map((row: any, index) => (
                                <div key={row.id} className='row mx-1 border justify-content-start align-items-start pt-2'>
                                    {/* <JSONTree data={row}/> */}
                                    <div className="col-1"> <InputCheckBoxField
                                        name={`employer.${sourceEmpIndex}.files.${fileIndex}.deduction.${index}.selected`}
                                        notRequired={true}
                                    /></div>
                                    <div className="col-3">
                                        <InputField2 labelRequired={false} label={''} placeholder={''}
                                            source={`employer.${sourceEmpIndex}.files.${fileIndex}.deduction.${index}.deduction_name`} />
                                    </div>
                                    <div className="col-3">
                                        <select className='form-select' style={{ fontSize: '14px' }}  {...register(`employer.${sourceEmpIndex}.files.${fileIndex}.deduction.${index}.deduction_type`)}>
                                            <option value={''} disabled>Select</option>
                                            <option value={'Tax'}>Tax</option>
                                            <option value={'Pre-Tax'}>Pre-Tax</option>
                                            <option value={'Post-Tax'}>Post-Tax</option>
                                        </select>
                                        {/* <InputSelectField label={''}
                                            labelRequired={false}
                                            
                                            options={[{ label: 'Tax', value: 'Tax', }, { label: 'Pre-Tax', value: 'Pre-Tax', }, { label: 'Post-Tax', value: 'Post-Tax', }]}
                                            source={`employer.${sourceEmpIndex}.files.${fileIndex}.deduction.${index}.deduction_type`} /> */}

                                    </div>
                                    <div className="col">
                                        <InputCurrencyFieldOrginal
                                            notRequired={true}
                                            labelRequired={false}
                                            label={``}
                                            name={`employer.${sourceEmpIndex}.files.${fileIndex}.deduction.${index}.current`} />

                                    </div>
                                    <div className="col">
                                        <InputCurrencyFieldOrginal
                                            notRequired={true}
                                            labelRequired={false}
                                            label={``}
                                            name={`employer.${sourceEmpIndex}.files.${fileIndex}.deduction.${index}.ytd`} />

                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default SummaryTable