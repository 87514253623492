import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useFormContext, useController } from "react-hook-form";

const ToggleButtons = ({
  toggleButton,
  label="",
  source,
  notRequired = false,
  labelRequired = true,
  disabled = false,
  labelClass,
  onClickHandler = (event) => {},
}) => {
  const { control, setValue, watch } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({
    name: source,
    control,
  });

  return (
    <div className="form-label">
      {label !== "" && labelRequired && (
        <>
          <label
            className={`${labelClass} ${error && "text-danger"}`}
            htmlFor="inputs"
          >
            {label} {!notRequired && <span className="color-red">&#42;</span>}
          </label>
          <br />
        </>
      )}

      <ToggleButtonGroup
        value={field?.value}
        exclusive
        onChange={(event, newAlignment) => {
          if (newAlignment !== null) {
            field?.onChange(newAlignment);
          }
        }}
        aria-label="text alignment"
        sx={{
          height: "36px",
          borderRadius: "4px",
          background: "#EEE",
        }}
      >
        {toggleButton?.map((item, index) => {
          return (
            <ToggleButton
              value={item?.value}
              aria-label={item?.value}
              key={index + 1}
              disabled = {disabled}
              sx={{
                border: "none",
                textTransform: "capitalize",
                fontFamily: "OpenSans-Regular",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
                "&.Mui-disabled":{
                  border: "none",
                },
                "&.MuiButtonBase-root.MuiToggleButton-root.MuiToggleButton-standard.MuiToggleButtonGroup-grouped.Mui-selected":
                  {
                    margin: "2px",
                    height: "32px",
                    borderRadius: "3px",
                    border: `0.5px solid ${item?.borderColor}`,
                    background: item?.backgroundColor,
                    boxShadow: `4px 8px 20px 0px ${item?.boxShadowColor}`,
                    color: item?.color,
                  },
              }}
              onClick={(event) => {
                onClickHandler(event);
              }}
            >
              {item?.icon} &nbsp;
              {field?.value === item?.value ? item?.label : ""}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      <div
        className={error !== undefined ? "invalid-feedback" : ""}
        style={{ display: "block", fontSize: "70%" }}
      >
        {error !== undefined && error?.message}
      </div>
    </div>
  );
};

export default ToggleButtons;
