import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button } from "react-admin";
import { useFormContext } from "react-hook-form";
import InputCurrencyField from "../components/inputComponents/InputCurrencyFiled";
import InputField2 from "../../common/InputComponents/InputField2";
import InputDateField from "../../common/InputComponents/InputDateFieldOld";
import InputSelectField from "../../common/InputComponents/InputSelectField";
import {
  useDataProvider,
  useGetOne,
  useNotify,
  useGetIdentity,
} from "react-admin";
import { useMutation, useQueryClient } from "react-query";
import NewMetaDataRules from "../components/NewMetaDataRules";
import ButtonGroup from "@mui/material/ButtonGroup";
import moment from "moment";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteFileConfirmBtn from "../../common/DeleteFileConfirmBtn";
import SummaryTable from "./SummaryTable";
import DeleteUnsaveRecords from "../../common/DeleteUnsaveRecords";
import CustomPreviewUpload from "../../common/CustomPreviewUpload";
import { JSONTree } from "react-json-tree";
import { NumericFormat } from "react-number-format";
import SendIcon from '@mui/icons-material/Send';
import MuiButton from '@mui/material/Button';
import { start } from "repl";

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            paddingLeft: 3,
            paddingRight: 3,
            paddingBottom: 3,
            paddingTop: 1,
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const calculatePayFrequency = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return { label: "Select", value: "" };
  }
  const startDate_ = moment(startDate);
  const endDate_ = moment(endDate);
  const days = endDate_.diff(startDate_, "days");
  const monthDays = startDate_.daysInMonth();

  if (days < 1) return pay_frequency[5];    //2
  if (days < 7) return pay_frequency[0];   //8
  if (days < 14) return pay_frequency[1];   //16
  if (days < 16) return pay_frequency[3];   //17
  if (days < monthDays) return pay_frequency[2];    //32
  if (days < 366) return pay_frequency[4];
  else return { label: "Select", value: "" };
};
const calCulateMonthlyGross = (gross_, pay_frequncy_) => {
  if (!pay_frequncy_?.value) return 0;
  if (pay_frequncy_.value == "Weekly") {
    return (gross_ * 52) / 12;
  } else if (pay_frequncy_.value == "BiWeekly") {
    return (gross_ * 26) / 12;
  } else if (pay_frequncy_.value == "Monthly") {
    return (gross_ * 12) / 12;
  } else if (pay_frequncy_.value == "BiMonthly") {
    return (gross_ * 24) / 12;
  } else if (pay_frequncy_.value == "OneTime") {
    return gross_ / 12;
  } else {
    return gross_ / 12;
  }
};

// function for handled a logic of auto set start value based on pay_frequency and end date
// const calculateStartDate = (pay_frequency, endDate) => {

//   let startDate = new Date(endDate);
//   switch (pay_frequency?.value) {
//     case "Weekly":
//       startDate.setDate(startDate.getDate() - 6);
//       break;
//     case "BiWeekly":
//       startDate.setDate(startDate.getDate() - 13);
//       break;
//     case "Monthly":
//       startDate.setDate(startDate.getDate() - 29);
//       break;
//     case "BiMonthly":
//       startDate.setDate(startDate.getDate() - 14);
//       break;
//     case "Yearly":
//       startDate.setDate(startDate.getDate() - 364);
//       break;
//   }
//   return new Date(startDate);
// };
const calculateStartDate = (pay_frequency, endDate) => {

  const endDate_ = moment(endDate);  // Convert endDate to Moment.js object
  const monthDays = endDate_.daysInMonth();  // Get the number of days in the month of the endDate

  let startDate_;

  switch (pay_frequency?.value) {
     case "Weekly":
      startDate_ = endDate_.subtract(6, 'days');  // Subtract 6 days for a weekly frequency
      break;
    case "BiWeekly":
      startDate_ = endDate_.subtract(13, 'days');  // Subtract 13 days for a bi-weekly frequency
      break;
    case "Monthly":
      startDate_ = endDate_.subtract(monthDays - 1, 'days');  // Subtract (monthDays - 1) days to get to the start of the month
      break;
    case "BiMonthly":
      startDate_ = endDate_.subtract(15, 'days');  // Subtract 15 days for a bi-monthly frequency
      break;
    case "Yearly":
      startDate_ = endDate_.subtract(365, 'year');  // Subtract 1 year for a yearly frequency
      break;

  }

  return startDate_?.toDate();  // Convert Moment.js object back to native Date object
};


// function for handled a logic of auto set start value based on pay_frequency and startDate
const calculateEndDate = (pay_frequency, startDate) => {
  const startDate_ = moment(startDate);
  const monthDays = startDate_.daysInMonth();
  let endDate = new Date(startDate);
  switch (pay_frequency?.value) {
    case "Weekly":
      endDate.setDate(endDate.getDate() + 6);
      break;
    case "BiWeekly":
      endDate.setDate(endDate.getDate() + 13);
      break;
    case "Monthly":
      endDate.setDate(endDate.getDate() + (monthDays - 1));
      break;
    case "BiMonthly":
      endDate.setDate(endDate.getDate() + 15);
      break;
    case "Yearly":
      endDate.setDate(endDate.getDate() + 364);
      break;
  }
  return new Date(endDate);
};

const pay_frequency = [
  { value: "Weekly", label: "Weekly" },
  { value: "BiWeekly", label: "Bi-Weekly" },
  { value: "Monthly", label: "Monthly" },
  { value: "BiMonthly", label: "Bi-Monthly" },
  { value: "Yearly", label: "Yearly" },
  { value: "OneTime", label: "One-Time" },
];
const socr_flags = [
  { label: "Gross pay", value: "Gross pay" },
  { label: "Net pay", value: "Net pay" },
  { label: "Pay period start date", value: "Pay period start date" },
  { label: "Pay period end date", value: "Pay period end date" },
  { label: "Pay date", value: "Pay date" },
  { label: "Pay frequency", value: "Pay frequency" },
];

export default function EmpFileField({
  removeManualRecord,
  empSource,
  setError,
  field,
  index,
  value,
  // handleOnChange,
  sourceEmpIndex,
  role,
  mlClassDetails,
  trn_id,
}) {
  const dataProvider = useDataProvider();
  const {
    control,
    watch,
    getValues,
    setValue: setFormValue,
    formState,
    trigger,
  } = useFormContext();
  const { identity, isLoading: identityLoading } = useGetIdentity();
  const { data: meta_d_response } = useGetOne('getMetaData', { id: 3, meta: { trn_id } }, { refetchOnWindowFocus: false, staleTime: 5 })
  const notify = useNotify();
  const queryClient = useQueryClient();
  const [mlFlag, setMlFlag] = React.useState(false);
  const {
    mutate: paystubProcessing,
    isLoading: deletePaystubProcessing,
    isSuccess: paystubProcessingSuccess,
  } = useMutation(
    "paystubProcessing",
    (data: any) => dataProvider.paystubProcessing({ ...data }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["income"]);
        notify("Paystub processing done successfully...", {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
      onError: (data) => {
        alert("Fail processing paystub");
      },
      onMutate: () => {
        notify("Paystub is processing...", {
          type: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
    }
  );
  const handleFlagPaystub = async (index) => {
    const values = watch(`employer.${sourceEmpIndex}.files.${index}`);

    if (values.paystub_type) {
      //
      paystubProcessing({
        subUrl: "/ml/flagPaystub",
        IncomeRowID: values.income_row_id,
        ApplicationEmployerID: empSource.id,
        applicationDocumentID: values?.application_document_id,
        application_id: empSource?.application_id,
        filename: values.file_name,
        file_path: values.path,
        old_type: values.doc_state_type ? values.doc_state_type : "",
        new_type: values.paystub_type ? values.paystub_type.value : "",
        socr_error_fields: [],
        application: "docupload",
        request_from: "Admin",
        request_from_id: identity?.super_user_id,
        DocumentTypeID: 3,
        source_id: sourceEmpIndex + 1,
        trn_id: trn_id,
        method: "POST",
      });
    } else {
      setError(
        `employer.${sourceEmpIndex}.files.${index}.paystub_type`,
        { type: "required", message: "required" },
        { shouldFocus: true }
      );
    }
  };

  const handleFlagSocrField = async (index) => {
    const values = watch(`employer.${sourceEmpIndex}.files.${index}`);
    if (values.socr_error_fields && values.socr_error_fields?.length > 0) {
      //
      paystubProcessing({
        subUrl: "/ml/flagPaystub",
        IncomeRowID: values.income_row_id,
        ApplicationEmployerID: empSource.id,
        applicationDocumentID: values?.application_document_id,
        application_id: empSource?.application_id,
        filename: values.file_name,
        file_path: values.path,
        old_type: values.doc_state_type ? values.doc_state_type : "",
        new_type: "0",
        application: "docupload",
        request_from: "Admin",
        socr_error_fields: values.socr_error_fields.map(
          (socr_flag) => socr_flag.value
        ),
        request_from_id: identity?.super_user_id,
        DocumentTypeID: 3,
        source_id: sourceEmpIndex + 1,
        trn_id: trn_id,
        method: "POST",
      });
    } else {
      setError(
        `employer.${sourceEmpIndex}.files.${index}.socr_error_fields`,
        { type: "required", message: "required" },
        { shouldFocus: true }
      );
    }
  };

  const handleReprocessingPaystub = async (index) => {
    const values = getValues(`employer.${sourceEmpIndex}.files.${index}`);
    if (values.paystub_type) {
      const data = {
        subUrl: "/income/paystubReprocess",
        IncomeRowID: values.income_row_id,
        ApplicationEmployerID: empSource.id,
        applicationDocumentID: values?.application_document_id,
        application_id: empSource?.application_id,
        filename: values.file_name,
        file_path: values.path,
        old_type: values.doc_state_type ? values.doc_state_type : "",
        new_type: values.paystub_type ? values.paystub_type.value : "",
        socr_error_fields: [],
        application: "docupload",
        request_from: "Admin",
        request_from_id: identity?.super_user_id,
        DocumentTypeID: 3,
        source_id: sourceEmpIndex + 1,
        trn_id: trn_id,
        method: "POST",
      };
      paystubProcessing(data);
    } else {
      setError(
        `employer.${sourceEmpIndex}.files.${index}.paystub_type`,
        { type: "required", message: "required" },
        { shouldFocus: true }
      );
    }
  };
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChangeCollapse =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const grossPayWatch = watch(
    `employer.${sourceEmpIndex}.files.${index}.Gross_Pay`
  );
  const payFrequencyWatch = watch(
    `employer.${sourceEmpIndex}.files.${index}.Pay_frequency`
  );


  const startDateWatch = watch(
    `employer.${sourceEmpIndex}.files.${index}.Start_date`
  );

  const endDateWatch = watch(
    `employer.${sourceEmpIndex}.files.${index}.End_date`
  );


  const [previousPayFrequency, setPreviousPayFrequency] = React.useState<{ label: string; value: string; } | null>(null);

  // React.useEffect(() => {
  //   const newPayFrequency = calculatePayFrequency(
  //     getValues(`employer.${sourceEmpIndex}.files.${index}.Start_date`),
  //     getValues(`employer.${sourceEmpIndex}.files.${index}.End_date`)
  //   );

  //   if (newPayFrequency !== null && newPayFrequency !== previousPayFrequency) {
  //     setFormValue(
  //       `employer.${sourceEmpIndex}.files.${index}.Pay_frequency`,
  //       newPayFrequency
  //     );
  //     setPreviousPayFrequency(newPayFrequency);
  //   }
  // }, [startDateWatch, endDateWatch, payFrequencyWatch]);
  // React.useEffect(() => {
  //   setFormValue(
  //     `employer.${sourceEmpIndex}.files.${index}.Pay_frequency`,
  //     calculatePayFrequency(
  //       getValues(`employer.${sourceEmpIndex}.files.${index}.Start_date`),
  //       getValues(`employer.${sourceEmpIndex}.files.${index}.End_date`)
  //     )
  //   );
  // }, [startDateWatch, endDateWatch, payFrequencyWatch]);

  // React.useEffect(() => {
  //   const grossPay = calCulateMonthlyGross(
  //     watch(`employer.${sourceEmpIndex}.files.${index}.Gross_Pay`),
  //     watch(`employer.${sourceEmpIndex}.files.${index}.Pay_frequency`)
  //   );
  //   setFormValue(
  //     `employer.${sourceEmpIndex}.files.${index}.monthly_income`,
  //     parseFloat(String(grossPay)).toFixed(2),
  //     { shouldDirty: false }
  //   );
  //   setFormValue(
  //     `employer.${sourceEmpIndex}.files.${index}.annual_income`,
  //     parseFloat(String(grossPay * 12)).toFixed(2),
  //     { shouldDirty: false }
  //   );
  // }, [grossPayWatch, payFrequencyWatch]);
  React.useEffect(() => {

    if (!!payFrequencyWatch?.value && !!endDateWatch && !!startDateWatch) {
      const newPayFrequency = calculatePayFrequency(
        getValues(`employer.${sourceEmpIndex}.files.${index}.Start_date`),
        getValues(`employer.${sourceEmpIndex}.files.${index}.End_date`)
      );

      if (newPayFrequency !== null && newPayFrequency !== previousPayFrequency) {
        setFormValue(
          `employer.${sourceEmpIndex}.files.${index}.Pay_frequency`,
          newPayFrequency
        );
        setPreviousPayFrequency(newPayFrequency);
      }
    }
  }, [startDateWatch, endDateWatch, payFrequencyWatch]);

  React.useEffect(() => {
    if (!!grossPayWatch && !!payFrequencyWatch?.value) {
      const grossPay = calCulateMonthlyGross(
        watch(`employer.${sourceEmpIndex}.files.${index}.Gross_Pay`),
        watch(`employer.${sourceEmpIndex}.files.${index}.Pay_frequency`)
      );
      const monthlyIncome = parseFloat(String(grossPay)).toFixed(2);
      const annualIncome = parseFloat(String(grossPay * 12)).toFixed(2);

      setFormValue(`employer.${sourceEmpIndex}.files.${index}.monthly_income`, monthlyIncome, { shouldDirty: false });
      setFormValue(`employer.${sourceEmpIndex}.files.${index}.annual_income`, annualIncome, { shouldDirty: false });
    }
  }, [grossPayWatch, payFrequencyWatch]);

  const formatDate = (date) => {
    if (!date)
      return date;
    const d = new Date(date);
    const month = d.getMonth() + 1; // Months are zero based, so we add 1
    const day = d.getDate();
    const year = d.getFullYear();

    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const formattedDay = day < 10 ? `0${day}` : `${day}`;
    return `${formattedMonth}/${formattedDay}/${year}`;
  };
  React.useEffect(() => {

    if (!!payFrequencyWatch?.value && endDateWatch && !startDateWatch) {
      const newstartDate = calculateStartDate(payFrequencyWatch, endDateWatch);
      const fdate = formatDate(newstartDate);


      setFormValue(
        `employer.${sourceEmpIndex}.files.${index}.Start_date`, fdate);
    }
  }, [payFrequencyWatch?.value, endDateWatch, startDateWatch]);

  React.useEffect(() => {
    if (!!payFrequencyWatch?.value && startDateWatch && !endDateWatch) {
      const newstartDate = calculateEndDate(payFrequencyWatch, startDateWatch);
      const fdate = formatDate(newstartDate);
      setFormValue(`employer.${sourceEmpIndex}.files.${index}.End_date`,
        fdate
      );
    }
  }, [payFrequencyWatch?.value, startDateWatch, endDateWatch]);

  React.useEffect(() => {
    if (!payFrequencyWatch?.value && startDateWatch && endDateWatch) {
      const newPayFrequency = calculatePayFrequency(
        getValues(`employer.${sourceEmpIndex}.files.${index}.Start_date`),
        getValues(`employer.${sourceEmpIndex}.files.${index}.End_date`)
      );
      setFormValue(
        `employer.${sourceEmpIndex}.files.${index}.Pay_frequency`,
        newPayFrequency
      );
    }
  }, [payFrequencyWatch?.value, startDateWatch, endDateWatch]);
  const [originalPayFrequency, setOriginalPayFrequency] = useState(null);



  React.useEffect(() => {
    if (startDateWatch && endDateWatch) {
      setOriginalPayFrequency(payFrequencyWatch);
    }
  }, [startDateWatch, endDateWatch]);

  const handleOnChange = (selectedOption) => {
    if (startDateWatch && endDateWatch) {
      setFormValue(`employer.${sourceEmpIndex}.files.${index}.Pay_frequency`, originalPayFrequency);
    } else {
      setFormValue(`employer.${sourceEmpIndex}.files.${index}.Pay_frequency`, selectedOption);
    }
  };

  return (
    <>
    <TabPanel key={field.fieldId} value={value} index={index}>
        {/* <Card sx={{ background: "none", boxShadow: 'none', borderRadius: 0, marginBottom: 2 }}>
          <CardHeader action={} sx={{ padding: 1 }} subheader={`${index + 1}. ${field.file_name || 'New paystub'}`}
          />
        </Card> */}
        <div className="d-flex justify-content-between">
          <h6>{`${index + 1}. ${field.file_name || "New paystub"}`}</h6>
          {!field.not_save && (
            <DeleteFileConfirmBtn
              isArrayRemove={!field.path}
              doc_id={
                field.application_document_id != 0
                  ? field.application_document_id
                  : field.income_row_id
              }
              tag={"delete_document_income"}
              invalidateQueries={"income"}
            />
          )}
          {field.not_save && (
            <DeleteUnsaveRecords
              removeFun={() => {
                removeManualRecord();
              }}
            />
          )}
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6">
            <div className="row">
              <div className="col-6 col-md-6 col-lg-6">
                <InputDateField
                  // onChangeCallback={() => {
                  //   handleOnChange();
                  // }}
                  label="Period Start Date"
                  source={`employer.${sourceEmpIndex}.files.${index}.Start_date`}
                  placeholder=""
                  stopAutoFocus={true}
                />
              </div>
              <div className="col-6 col-md-6 col-lg-6">
                <InputDateField
                  // onChangeCallback={() => {
                  //   handleOnChange();
                  // }}
                  label="Pay End Date"
                  source={`employer.${sourceEmpIndex}.files.${index}.End_date`}
                  placeholder=""
                  stopAutoFocus={true}
                />
              </div>
              <div className="col-6 col-md-6 col-lg-6">
                <InputDateField
                  // onChangeCallback={() => {
                  //   handleOnChange();
                  // }}
                  label="Pay Date"
                  source={`employer.${sourceEmpIndex}.files.${index}.Pay_date`}
                  placeholder=""
                  stopAutoFocus={true}
                />
              </div>
              <div className="col-6 col-md-6 col-lg-6">
                <InputSelectField
                  // disable={startDateWatch && endDateWatch}
                  onChangeCallback={handleOnChange}
                  isClearable={false}
                  label="Pay Frequency"
                  source={`employer.${sourceEmpIndex}.files.${index}.Pay_frequency`}
                  options={pay_frequency}
                  stopAutoFocus={true}
                />
              </div>
              <div className="col-6 col-md-6 col-lg-6">
                <InputCurrencyField
                  // onChangeCallback={() => {
                  //   handleOnChange();
                  // }}
                  label="Gross Pay"
                  source={`employer.${sourceEmpIndex}.files.${index}.Gross_Pay`}
                  placeholder=""
                  disabled={false}
                />
              </div>
              <div className="col-6 col-md-6 col-lg-6">
                <InputCurrencyField
                  // onChangeCallback={() => {
                  //   handleOnChange();
                  // }}
                  type="number"
                  label="Net Pay"
                  source={`employer.${sourceEmpIndex}.files.${index}.Net_Pay`}
                  placeholder=""
                  disabled={false}
                />
              </div>

              <div className="col-6 col-md-6 col-lg-6">
                <InputCurrencyField
                  type="number"
                  label="Monthly Income"
                  source={`employer.${sourceEmpIndex}.files.${index}.monthly_income`}
                  placeholder=""
                  disabled={true}
                />
              </div>
              <div className="col-6 col-md-6 col-lg-6">
                <InputCurrencyField
                  type="number"
                  label="Yearly Income"
                  source={`employer.${sourceEmpIndex}.files.${index}.annual_income`}
                  placeholder=""
                  disabled={true}
                />
                <br />
              </div>

              {field.path && (
                <>
                  <div className="col-6 col-md-6 col-lg-6 d-flex">
                    <InputField2
                      type="text"
                      label="Paystub Type"
                      source={`employer.${sourceEmpIndex}.files.${index}.doc_state_type`}
                      placeholder=""
                      disable={true}
                    />{" "}
                    {/* <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setMlFlag((v) => !v);
                    }}
                    className="btn btn-link"
                    title="Report Predication"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-box-arrow-in-down-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M6.364 2.5a.5.5 0 0 1 .5-.5H13.5A1.5 1.5 0 0 1 15 3.5v10a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 2 13.5V6.864a.5.5 0 1 1 1 0V13.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5H6.864a.5.5 0 0 1-.5-.5z"
                      ></path>
                      <path
                        fillRule="evenodd"
                        d="M11 10.5a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1 0-1h3.793L1.146 1.854a.5.5 0 1 1 .708-.708L10 9.293V5.5a.5.5 0 0 1 1 0v5z"
                      ></path>
                    </svg>
                  </button> */}
                  </div>

                </>
              )}
            </div>
            {role === "Docuverus Super Admin" &&
              field.application_document_id !== "0" &&
              mlFlag && (
                <div className="card p-2" style={{ background: "#FDFFF5" }}>
                  <div className="row">
                    <div className="col-4 col-md-4 col-lg-4">
                      <InputSelectField
                        label="Select Paystub"
                        source={`employer.${sourceEmpIndex}.files.${index}.paystub_type`}
                        options={mlClassDetails}
                      />
                    </div>

                    <div className="col-6 col-md-6 col-lg-6">
                      <InputSelectField
                        label="Flag SOCR"
                        source={`employer.${sourceEmpIndex}.files.${index}.socr_error_fields`}
                        options={socr_flags}
                        multiple={true}
                      />
                    </div>

                    <div className="col-3 col-md-3 col-lg-3">
                      <div className="card">
                        <ButtonGroup
                          style={{ color: "#fff" }}
                          size="large"
                          orientation="vertical"
                          variant="contained"
                          aria-label="outlined primary button group"
                        >
                          <Button
                            label={"Flag Paystub"}
                            disabled={field?.isFlagPaystub}
                            onClick={() => handleFlagPaystub(index)}
                            className={"btn btn-primary"}
                          ></Button>
                          <Button
                            label={"Flag SOCR Field"}
                            disabled={field?.isFlagSocrFields}
                            onClick={() => handleFlagSocrField(index)}
                          ></Button>
                          <Button
                            label={"Reprocess"}
                            disabled={field?.isReprocess}
                            onClick={() => handleReprocessingPaystub(index)}
                          ></Button>
                        </ButtonGroup>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {/* <div className="row">
          <div className="col-6 col-md-6 col-lg-6 py-2">
            <button
              type="submit"
              className="blue-btn px-4"
              disabled={!formState.isDirty}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-floppy-fill" viewBox="0 0 16 16">
                <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0H3v5.5A1.5 1.5 0 0 0 4.5 7h7A1.5 1.5 0 0 0 13 5.5V0h.086a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5H14v-5.5A1.5 1.5 0 0 0 12.5 9h-9A1.5 1.5 0 0 0 2 10.5V16h-.5A1.5 1.5 0 0 1 0 14.5z" />
                <path d="M3 16h10v-5.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5zm9-16H4v5.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5zM9 1h2v4H9z" />
              </svg> 
              &nbsp;Save Changes
            </button>
          </div>
        </div> */}
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            <CustomPreviewUpload startpage={field.startpage} endpage={field.endpage} filePath={field?.path} />
          </div>
          <div className="col-12">
            <br />
            {field?.path && role !== "applicant" && (
              <div className="metadata-wrap">
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChangeCollapse("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      Metadata Checks
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <NewMetaDataRules
                      metaDataCheck={
                        meta_d_response?.records?.rules.find(
                          (item) =>
                            item.ApplicationDocumentID ==
                            field?.application_document_id
                        )?.metaDataCheck || []
                      }
                      alignmentError={
                        meta_d_response?.records?.rules.find(
                          (item) =>
                            item.ApplicationDocumentID ==
                            field?.application_document_id
                        )?.alignmentError || []
                      }
                      unknownTemplate={
                        meta_d_response?.records?.rules.find(
                          (item) =>
                            item.ApplicationDocumentID ==
                            field?.application_document_id
                        )?.unknownTemplate || []
                      }
                      fraudTemplate={
                        meta_d_response?.records?.rules.find(
                          (item) =>
                            item.ApplicationDocumentID ==
                            field?.application_document_id
                        )?.fraudTemplate || []
                      }
                    />
                  </AccordionDetails>
                </Accordion>
              </div>
            )}
          </div>
        </div>
        {field?.path && role === "Docuverus Super Admin" && (
          <SummaryTable sourceEmpIndex={sourceEmpIndex} fileIndex={index} />
        )}
        {/* {field.path && <SummaryTableArrayField
            employerIndex={sourceEmpIndex}
            fileIndex={index}
            activeTabIndex={value}
        />} */}
      </TabPanel>
    </>
  );
}
