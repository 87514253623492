import * as React from 'react';
import { useGetIdentity, useStore } from 'react-admin';
import parse from 'html-react-parser';
import ClearIcon from '@mui/icons-material/Clear';
import { Button } from '@mui/material';
import QuillTextEditor from '../common/InputComponents/TextEditorWithDataField/QuillTextEditor';
import RenderQuillText from '../Settings/EmailSettings/RenderQuillText';
const SubscriberNotification = () => {
  const { data } = useGetIdentity();
  const [systemNotification, setSystemNotification] = useStore<any>("Notification");
  

  // Set system notifications from identity data
  // React.useEffect(() => {
  //   if (data?.systemNotification && Array.isArray(data.systemNotification)) {
  //     setSystemNotification(data.systemNotification);
  //   }
  // }, []);

  // Handle close notification
  const handleCloseNotification = (index: number) => {
   const updatedNotifications = systemNotification.filter((_, i) => i !== index);
    setSystemNotification(updatedNotifications);
  };

  return (
    <>
      {/* Ensure systemNotification is an array and has items */}
      {
        systemNotification?.map((item, index) => (
         <div
              key={item?.ID || index}
              style={{
                color: 'red',
                fontFamily: 'Open Sans',
                fontWeight: 700,
                backgroundColor: '#ECFBFF',
                border: 'none',
                //maxHeight: '250px',
                width: '100%',
                maxWidth:"1100px",
                //  maxWidth:"1400px",
                fontSize: '12px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px',
                marginTop: '18px',
              //overflowX:'scroll'
              }}
              className="alert alert-warning alert-dismissible fade show"
              role="alert"
            >
            {/* {parse(item?.Template)} */}
            <RenderQuillText text={item?.Template}/>
              {/* Close button */}
              <Button
                style={{ color: 'grey' }}
                startIcon={<span style={{ fontSize: "12px" ,textTransform:"capitalize"}}>Dismiss</span>}
                onClick={() => handleCloseNotification(index)}
              />
            </div>
          ))}
    </>
  );
};

export default SubscriberNotification;
