import React, { Fragment, useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, useLocation } from "react-router";
import { ArrowBack } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import InputField2 from "../common/InputComponents/InputField2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import ToggleButtons from "../common/InputComponents/ToggleButtons";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button } from "@mui/material";
import GoogleAutoComplete from "../common/InputComponents/GoogleAutocomplete";
import {
  useCreate,
  useGetIdentity,
  useGetOne,
  useNotify,
  useRefresh,
  useStore,
  Loading,
  usePermissions
} from "react-admin";
import InputSelectField from "../common/InputComponents/InputSelectField";
import TextInputPhone from "../DocumentAnalysis/components/inputComponents/TextInputPhone";
import FilePreview2 from "../common/InputComponents/FilePreview2";
import InputFileUpload from "../common/InputComponents/InputFileUpload";
import { useParams } from "react-router-dom";
import InputDateField from "../common/InputComponents/InputDateFieldOld";
import InputCurrencyField from "../DocumentAnalysis/components/inputComponents/InputCurrencyFiled";
import InputCheckBoxField from "../common/InputComponents/InputCheckBoxField";
import InputMaskField from "../common/InputComponents/InputMaskFieid";
import moment from "moment";
import save_icon from "../../assets/images/save_icon.svg";
import DeactivateSubscriberModal from "./DeactivateSubscriberModal";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import formValidation from "../../yupFormValidationRules/formValidator";
import { JSONTree } from "react-json-tree";
import { checkModuleAccess } from "../../Functions/permissions";

const file_type = ["image/jpg", "image/jpeg", "image/png"];
const file_size = 4000 * 1024;


interface AddNewSubscriberFormValues {
  addressSearch: string;
  file: null | string;
  companyName: string;
  companyType: null;
  active: string | number;
  telephone: string;
  streetAddress: string;
  zipCode: string;
  city: string;
  state: null;
  username: string; // this value in add only
  subscriberCompanyWebsite: string; // this value in update only
  subscriberEmail: string;
  guestCard: string | number;
  docUpload: string | number;
  ats: string | number;
  screening: string | number;
  reviewDone: string | number;
  // leaseGeneration: string | number;
  licenseActivationDate: string;
  licenseExpirationDate: string;
  screeningCompany: null;
  screeningFee: string | number;
  subscriberFee: string | number;
  applicationFee: string | number;
  processingFee: string | number;
  enableOCRAPI: null;
  applicationFeeRecipient: null;
  primaryContactFirstName: string;
  primaryContactLastName: string;
  primaryContactEmail: string;
  primaryContactUserName: string;
  primaryContactMobileNo: string;
  bankName: string;
  accountHolderName: string;
  stripeAccountStatus: string | number;
  accountNumber: string;
  confirmAccountNumber: string;
  routingNumber: string;
  confirmRoutingNumber: string;
  taxID: string;
  confirmTaxID: string;
  businessWebsite: string;
  payOutFrequency: null | any;
  paymentDate: null | any;
  // paymentDay: string;
  billStreetAddress: string;
  billZipCode: string;
  useDifferentBillingAddress: any;
  billingState: null;
  billingCity: string;
  thirdPartyIntegration: null | any[];
}

const calculateProcessingFee = (
  screeningFeeWatch,
  subscriberFeeWatch,
  applicationFeeWatch
) => {
  let processingFeeTotal =
    applicationFeeWatch - (+screeningFeeWatch + +subscriberFeeWatch);
  return processingFeeTotal;
};

// const calculateApplicationFee = (
//   screeningFeeWatch,
//   subscriberFeeWatch,
//   processingFeeWatch
// ) => {
//   let processingFeeTotal =
//     processingFeeWatch + +screeningFeeWatch + +subscriberFeeWatch;
//   return processingFeeTotal;
// };

const AddorUpdateSubscriber = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const notify = useNotify();
  const refresh = useRefresh();
  const toggleButton = [
    {
      value: 0,
      label: "Inactive",
      icon: <ClearIcon />,
      borderColor: "#909090",
      backgroundColor: "#EEE",
      boxShadowColor: "#90909033",
      color: "#606060",
    },
    {
      value: 1,
      label: "Active",
      icon: <CheckIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];
  const toggleEnabledButton = [
    {
      value: 0,
      label: "Disabled",
      icon: <ClearIcon />,
      borderColor: "#909090",
      backgroundColor: "#EEE",
      boxShadowColor: "#90909033",
      color: "#606060",
    },
    {
      value: 1,
      label: "Enabled",
      icon: <CheckIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];

  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [isAddedNewIntegration, setIsAddedNewIntegration] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean[]>([]);
  const [isLoadingData,setLoadingData]= useState(true);
  const enableOCRAPIData = [
    {
      value: true,
      label: "Yes",
    },
    {
      value: false,
      label: "No",
    },
  ];
  const applicationFeeRecipientData = [
    {
      value: true,
      label: "Docuverus",
    },
  ];

  const payOutFrequencyData = [
    {
      label: "Daily",
      value: "Daily",
    },
    {
      label: "Weekly",
      value: "Weekly",
    },
    {
      label: "Monthly",
      value: "Monthly",
    },
  ];
  const paymentDayData = [
    {
      label: "Sunday",
      value: "Sunday",
    },
    {
      label: "Monday",
      value: "Monday",
    },
    {
      label: "Tuesday",
      value: "Tuesday",
    },
    {
      label: "Wednesday",
      value: "Wednesday",
    },
    {
      label: "Thursday",
      value: "Thursday",
    },
    {
      label: "Friday",
      value: "Friday",
    },
    {
      label: "Saturday",
      value: "Saturday",
    },
  ];
  // State for checking event is hit then true value
  const [checkEventOccurs, setCheckEventOccurs] = useState(false);
  
  // get states Data
  const { data: getStates, isLoading: isLoadingStates } = useGetOne(
    "getStates",
    {
      id: 1,
    },
    { refetchOnWindowFocus: false }
  );

  // get company type Data
  const { data: getCompanyType, isLoading: isLoadingCompanyType } = useGetOne(
    "company-type",
    {
      id: 1,
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  // get screening company Data
  const { data: getScreeningCompany, isLoading: isLoadingScreeningCompany} = useGetOne(
    "screening-company",
    {
      id: 1,
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  // get getThirdPartyApps Data
  const { data: getThirdPartyApps, isLoading: isLoadingThirdPartyApps} = useGetOne(
    "list-third-party-apps",
    {
      id: 1,
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  // get "get subscriber details
  const { data: getSubscriberDetails , isLoading: isLoadingSubscriber} = useGetOne(
    "get-subscriber",
    {
      id: id,
    },
    {
      refetchOnWindowFocus: false,
      enabled: id ? true : false,
    }
  );

  // form validation schema
  const validationSchema = yup.lazy((value) =>
    yup.object().shape({
      companyName: formValidation.name,
      companyType: formValidation.validationForDropDown,
      active: formValidation.StringRequiredValidation,
      streetAddress: formValidation.streetAddress,
      telephone: formValidation.mobileNoNotRequired,
      zipCode: formValidation.zipCode,
      city: formValidation.city,
      state: formValidation.validationForDropDown,
      username:
        !id &&
        state?.isAdding &&
        formValidation.username,
      subscriberCompanyWebsite: yup
        .string()
        .url("Enter a valid URL")
        .nullable(),
      subscriberEmail: formValidation.email,
      guestCard: formValidation.StringRequiredValidation,
      docUpload: formValidation.StringRequiredValidation,
      ats: formValidation.StringRequiredValidation,
      screening: value?.ats === 1 && formValidation.StringRequiredValidation,
      reviewDone: formValidation.StringRequiredValidation,
      licenseActivationDate: formValidation.date,
      // .min(yup.ref("licenseActivationDate"), "Expiration date must be later than Activation date")
      licenseExpirationDate: yup.string().required().typeError("Required!").test(
        "is-later-than-activation-date",
        "Expiration date must be later than Activation date",
        function (licenseExpirationDate) {
          const { licenseActivationDate } = this.parent;
          const activationDate = new Date(licenseActivationDate);
          const expirationDate = new Date(licenseExpirationDate || "");
          return expirationDate > activationDate;
        }
      ),
      screeningCompany:yup.object().when("screening",{
        is:(status) => value?.ats === 1 && value?.screening === 1,
        then:formValidation.validationForDropDown,
      }).nullable(),
      screeningFee: value?.ats === 1 && formValidation.feeValidation,
      subscriberFee: value?.ats === 1 &&  formValidation.feeValidation,
      processingFee: value?.ats === 1 &&  formValidation.processingFee,
      applicationFee:  value?.ats === 1 && formValidation.feeValidation,
      enableOCRAPI: formValidation.validationForDropDown,
      applicationFeeRecipient: value?.ats === 1 && formValidation.validationForDropDown,
      primaryContactFirstName: formValidation.name,
      primaryContactLastName: formValidation.name,
      primaryContactEmail: formValidation.email,
      primaryContactUserName: formValidation.username,
      primaryContactMobileNo: formValidation.mobileNo,
      ...(value?.useDifferentBillingAddress && {
        billStreetAddress: formValidation.streetAddress,
        billZipCode: formValidation.zipCode,
        billingCity: formValidation.city,
        billingState: formValidation.validationForDropDown,}),
      bankName:
        value?.ats === 1 &&
        value?.subscriberFee > 0 &&
        yup.string().nullable().when("stripeAccountStatus",{
          is: (status) => value?.stripeAccountStatus === 1,
          then: formValidation.name,
        }),
      accountHolderName:
        value?.ats === 1 &&
        value?.subscriberFee > 0 &&
        yup.string().nullable().when("stripeAccountStatus",{
          is: (status) => value?.stripeAccountStatus === 1,
          then:formValidation.name,
        }),
      stripeAccountStatus:
        value?.ats === 1 &&
        value?.subscriberFee > 0 &&
        formValidation.StringRequiredValidation,
      accountNumber:
        value?.ats === 1 &&
        value?.subscriberFee > 0 &&
        yup.string().nullable().when("stripeAccountStatus",{
          is: (status) => value?.stripeAccountStatus === 1,
          then: yup
          .string()
          .nullable()
          .required("Required!")
          .test("only_number", "Not valid", (value: any) => {
            if (value === "" || value === null) {
              return true;
            }
            if (id && !checkEventOccurs) {
              // Added this condition to prevent validation when updating an existing record, as the input value in a string.
              return true;
            }
            const regex =
              /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;
            return regex.test(value);
          }),
        }),
      confirmAccountNumber:
        value?.ats === 1 &&
        value?.subscriberFee > 0 &&
        yup.string().nullable().when("stripeAccountStatus",{
          is: (status) => value?.stripeAccountStatus === 1,
          then: yup
          .string()
          .nullable()
          .oneOf([yup.ref("accountNumber"), ""], "Account Number must match!")
          .required("Required!")
          .test("only_number", "Not valid", (value: any) => {
            if (value === "" || value === null) {
              return true;
            }
            if (id && !checkEventOccurs) {
              // Added this condition to prevent validation when updating an existing record, as the input value in a string.
              return true;
            }
            const regex =
              /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;
            return regex.test(value);
          }),
        }),
      routingNumber:
        value?.ats === 1 &&
        value?.subscriberFee > 0 &&
        yup.string().when("stripeAccountStatus",{
          is: (status) => value?.stripeAccountStatus === 1,
          then:  formValidation.stringNumberValidation,
        }),
      confirmRoutingNumber:
        value?.ats === 1 &&
        value?.subscriberFee > 0 &&
        yup.string().nullable().when("stripeAccountStatus",{
          is: (status) => value?.stripeAccountStatus === 1,
          then:  formValidation.stringNumberValidation.oneOf([yup.ref("routingNumber"), ""], "Routing Number must match!"),
        }),
      taxID:
        value?.ats === 1 &&
        value?.subscriberFee > 0 &&
        yup.string().nullable().when("stripeAccountStatus",{
          is: (status) => value?.stripeAccountStatus === 1,
          then: formValidation.stringNumberValidation,
        }),
      confirmTaxID:
        value?.ats === 1 &&
        value?.subscriberFee > 0 &&
        yup.string().nullable().when("stripeAccountStatus",{
          is: (status) => value?.stripeAccountStatus === 1,
          then: formValidation.stringNumberValidation.oneOf([yup.ref("taxID"), ""], "Tax ID must match!"),
        }),
      businessWebsite:
        value?.ats === 1 &&
        value?.subscriberFee > 0 &&
        yup.string().when("stripeAccountStatus",{
          is: (status) => value?.stripeAccountStatus === 1,
          then: yup.string().required("Required!").url("Enter a valid URL").nullable(),
        }),   
      payOutFrequency:
        value?.ats === 1 &&
        value?.subscriberFee > 0 &&
        yup.object().nullable().when("stripeAccountStatus",{
          is: (status) => value?.stripeAccountStatus === 1,
          then:yup
          .object()
          .shape({
            value: yup.string().required("Required!").nullable(),
            label: yup.string().nullable(),
          })
          .nullable()
          .required("Required!"),
        }),
      paymentDate:
        value?.ats === 1 &&
        value?.subscriberFee > 0 &&
        ((value?.payOutFrequency?.value === "Monthly" &&
        yup.string().when("stripeAccountStatus",{
          is: (status) => value?.stripeAccountStatus === 1, 
          then:formValidation.PaymentDate
        }))
        || (value?.payOutFrequency?.value === "Weekly" &&  yup.object().nullable().when("stripeAccountStatus",{
          is: (status) => value?.stripeAccountStatus === 1,
          then:   formValidation.validationForDropDown,
        })
      )),
      thirdPartyIntegration:
        (value?.guestCard === 1 || value?.docUpload === 1) &&
        yup.array().of(
          yup.object().shape({
            // status: yup.string().when("appID", {
            //   is: (appID) => appID !== null,
            //   then: yup.string().required("Required!").nullable(),
            // }),
            
            appUserName:yup.string().when("status", {
              is: (status) => status !== 0,
              then: formValidation.username,
            }),
            appPassword: yup.string().when("status", {
              is: (status) => status !== 0,
              then: yup.string().required("Required!").nullable(),
            }),
            appURL: yup.string().when(["status","appID"], {
              is: (status,appID) => status !== 0 ,
              then: yup
                .string()
                .required("Required!")
                 .url("Enter a valid URL")
                .nullable()
                // .test("test","Test is not validate", (value: any)=>{
                //   const regex = new RegExp(`\\b${"test"}\\b`, "i"); // \b for word boundaries, i for case-insensitive
                //  return !regex.test(value);
                // }),
            }),
            serverName: yup.string().when(["status","appID"], {
              is: (status,appID) => status !== 0 && appID?.label != "Entrata",
              then: yup
                .string()
                .typeError("Required!")
                .required("Required!")
                .nullable(),
            }),
            databaseName: yup.string().when(["status","appID"], {
              is: (status,appID) => status !== 0 && appID?.label != "Entrata",
              then: yup
                .string()
                .typeError("Required!")
                .required("Required!")
                .nullable(),
            }),
            platform: yup.string().when(["status","appID"], {
              is: (status,appID) => status !== 0 && appID?.label != "Entrata",
              then: yup
                .string()
                .typeError("Required!")
                .required("Required!")
                .nullable(),
            }),
            interfaceEntity: yup.string().when(["status","appID"], {
              is: (status,appID) => status !== 0 && appID?.label != "Entrata",
              then: yup
                .string()
                .typeError("Required!")
                .required("Required!")
                .nullable(),
            }),
            interfaceLicense: yup.string().when(["status","appID"], {
              is: (status,appID) => status !== 0 && appID?.label != "Entrata",
              then: yup
                .string()
                .typeError("Required!")
                .required("Required!")
                .nullable(),
            }),
          })
        ),
      file:
        value?.file &&
        value?.file?.type != undefined &&
        yup
          .mixed()
          .nullable()
          // .required("Required!")
          .test(
            "file_size",
            "File size exceeds 4Mb",
            (value) => !value || (value && value.size <= file_size)
          )
          .test(
            "file_type",
            "Supported file format is image files only.",
            (value) => !value || (value && file_type.includes(value?.type))
          ),
    })
  );

  // Initial form values
  const defaultFormValues = {
    addressSearch: "",
    file: null,
    companyName: "",
    companyType: null,
    active: 1,
    telephone: "",
    streetAddress: "",
    zipCode: "",
    city: "",
    state: null,
    username: "", // this value in add only
    subscriberCompanyWebsite: "", // this value in update only
    subscriberEmail: "",
    guestCard: 1,
    docUpload: 1,
    ats: 1,
    screening: 1,
    reviewDone:1,
    // leaseGeneration: 1,
    licenseActivationDate: "",
    licenseExpirationDate: "",
    screeningCompany: null,
    screeningFee: "",
    subscriberFee: "",
    applicationFee: "",
    processingFee: "",
    enableOCRAPI: null,
    applicationFeeRecipient: null,
    primaryContactFirstName: "",
    primaryContactLastName: "",
    primaryContactEmail: "",
    primaryContactUserName: "",
    primaryContactMobileNo: "",
    bankName: "",
    accountHolderName: "",
    stripeAccountStatus: 1, //can you possible to do in true and false value,
    accountNumber: "",
    confirmAccountNumber: "",
    routingNumber: "",
    confirmRoutingNumber: "",
    taxID: "",
    confirmTaxID: "",
    businessWebsite: "",
    paymentDate: "",
    billStreetAddress: "", // billingAddress and thais are same?,
    billZipCode: "",
    useDifferentBillingAddress: "",
    billingState: null,
    billingCity: "",
    thirdPartyIntegration: [
      {
        appID: null,
        status: 1,
        appPassword: "",
        appUserName: "",
      },
    ],
    payOutFrequency : {value: "", label: ""},
    // paymentDay:""

  };

  const methods = useForm<AddNewSubscriberFormValues>({
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  // destructuring a form values
  const { control } = methods;

  // state for dynamic fields
  const { fields, append, prepend, remove, swap, move, insert, replace } =
    useFieldArray({
      control,
      name: "thirdPartyIntegration",
    });

  useEffect(() => {
    if (!state?.isAdding) {
      if (getSubscriberDetails?.data?.length > 0) {
        getSubscriberDetails?.data?.map((subscriberDetails, index) => {
          subscriberDetails.ZipCode = (subscriberDetails?.ZipCode?.length>5 && subscriberDetails?.ZipCode?.slice(5, 6)!=="-")?(subscriberDetails?.ZipCode?.slice(0, 5)+"-"+subscriberDetails?.ZipCode?.slice(5)):subscriberDetails?.ZipCode;
          subscriberDetails.BillZipCode = (subscriberDetails?.BillZipCode?.length>5 && subscriberDetails?.BillZipCode?.slice(5, 6)!=="-")?(subscriberDetails?.BillZipCode?.slice(0, 5)+"-"+subscriberDetails?.BillZipCode?.slice(5)):subscriberDetails?.BillZipCode;
          subscriberDetails.Screening = 1;
          subscriberDetails.Telephone = (subscriberDetails?.Telephone && !subscriberDetails?.Telephone?.match(/^\+/))?("+1 "+subscriberDetails?.Telephone):subscriberDetails?.Telephone;
          getStates?.data?.filter((item) => {
            if (item?.StateShortCode == subscriberDetails?.State) {
              let body = {
                value: item?.id,
                label: item?.StateShortCode + " - " + item?.StateName,
              };

              subscriberDetails.State = body;
            }
          });
          getCompanyType?.records?.filter((item) => {
            if (item?.ID == subscriberDetails?.CompanyTypeID) {
              let body = {
                value: item?.ID,
                label: item?.CompanyType,
              };

              subscriberDetails.CompanyType = body;
            }
          });
          getScreeningCompany?.data?.filter((item) => {
            if (item?.ID == subscriberDetails?.ScreeningCompanyValue) {
              let body = {
                value: item?.ID,
                label: item?.Name,
              };

              subscriberDetails.ScreeningCompany = body;
            }
          });
          enableOCRAPIData?.filter((item) => {
            if (item?.label == subscriberDetails?.EnableOCRAPI) {
              let body = {
                value: item?.value,
                label: item?.label,
              };

              subscriberDetails.EnableOCRAPI = body;
            }
          });
          applicationFeeRecipientData?.filter((item) => {
            if (item?.label == subscriberDetails?.ApplicationFeeRecipient) {
              let body = {
                value: item?.value,
                label: item?.label,
              };

              subscriberDetails.ApplicationFeeRecipient = body;
            }
          });
          getStates?.data?.filter((item) => {
            if (item?.id == subscriberDetails?.BillStateID) {
              let body = {
                value: item?.id,
                label: item?.StateShortCode + " - " + item?.StateName,
              };

              subscriberDetails.BillingState = body;
            }
          });
          let ThirdPartyIntegration;
          if (typeof subscriberDetails?.ThirdPartyIntegration !== "object") {
            ThirdPartyIntegration = JSON?.parse(
              subscriberDetails?.ThirdPartyIntegration
            );
          } else {
            ThirdPartyIntegration = subscriberDetails?.ThirdPartyIntegration;
          }

          if (ThirdPartyIntegration?.length > 0) {
            const updatedThirdPartyIntegration = ThirdPartyIntegration?.map(
              (thirdPartyIntegration) => {
                const app = getThirdPartyApps?.data?.find(
                  (item) => item?.ID == thirdPartyIntegration?.appID
                );

                if (app) {
                  return {
                    ...thirdPartyIntegration,
                    appID: { value: app?.ID, label: app?.name },
                  };
                }

                return thirdPartyIntegration;
              }
            );
            subscriberDetails.ThirdPartyIntegration =
              updatedThirdPartyIntegration;
          } else if (!ThirdPartyIntegration) {
            subscriberDetails.ThirdPartyIntegration =
              subscriberDetails?.ThirdPartyIntegration
                ? subscriberDetails?.ThirdPartyIntegration
                : [
                    {
                      appID: null,
                      status: 0,
                      appPassword: "",
                      appUserName: "",
                    },
                  ];
          }
          subscriberDetails.file = subscriberDetails?.Logo
            ? subscriberDetails?.Logo
            : null;
          subscriberDetails.subscriberID = subscriberDetails?.ID;
          subscriberDetails.accountNumber = subscriberDetails?.AccountNumber
            ? "**********" + subscriberDetails?.AccountNumber
            : "";
          subscriberDetails.confirmAccountNumber =
            subscriberDetails?.AccountNumber
              ? "**********" + subscriberDetails?.AccountNumber
              : "";
          subscriberDetails.confirmRoutingNumber =
            subscriberDetails?.RoutingNumber;
          subscriberDetails.confirmTaxID = subscriberDetails?.TaxID;
          if(subscriberDetails?.PayOutFrequency === "Weekly"){
            paymentDayData?.filter((item) => {
              if (item?.value?.toLowerCase() === subscriberDetails?.PaymentDate) {
                let body = {
                  value: item?.value,
                  label: item?.label,
                };
  
                subscriberDetails.PaymentDate = body;
              }
            });
          }
          payOutFrequencyData?.filter((item) => {
            if (item?.value == subscriberDetails?.PayOutFrequency) {
              let body = {
                value: item?.value,
                label: item?.label,
              };

              subscriberDetails.PayOutFrequency = body;
            }
          });
          subscriberDetails.subscriberCompanyWebsite =
            subscriberDetails?.SubscriberCompanyWebsite
              ? subscriberDetails?.SubscriberCompanyWebsite
              : "";
          subscriberDetails.stripeAccountStatus =
            subscriberDetails?.StripeAccountEnabled
              ? subscriberDetails?.StripeAccountEnabled
              : 0;
          subscriberDetails.subscriberEmail = subscriberDetails?.Email;
        });
      }
      let data = getSubscriberDetails?.data && getSubscriberDetails?.data[0];
      const newObj = {};
      if (data !== null && data !== undefined) {
        Object.keys(data).forEach((key) => {
          newObj[key.charAt(0).toLowerCase() + key.slice(1)] = data[key];
        });
      } else {
        // console.log("msg: object is null or undefined");
      }
      methods.reset(newObj);
      setLoadingData(false);
    }
  }, [getSubscriberDetails?.data && getSubscriberDetails?.data[0], methods]);


  useEffect(()=>{
    if(methods?.watch("zipCode")?.length>5 && methods?.watch("zipCode")?.slice(5, 6)!=="-")
      methods?.setValue("zipCode", methods?.watch("zipCode")?.slice(0, 5)+"-"+methods?.watch("zipCode")?.slice(5));
    if(methods?.watch("billZipCode")?.length>5 && methods?.watch("billZipCode")?.slice(5, 6)!=="-")
      methods?.setValue("billZipCode", methods?.watch("billZipCode")?.slice(0, 5)+"-"+methods?.watch("billZipCode")?.slice(5));
    
   },[methods?.watch("zipCode"), methods?.watch("billZipCode")])

  const [create, { isLoading, error }] = useCreate();

  // form submit handler
  const onSubmit = (value) => {
    const {
      useDifferentBillingAddress,
      addressSearch,
      billCityID, //getting form get api
      billStateID, //getting form get api
      cityID, //getting form get api
      companyTypeID, //getting form get api
      dBGroupID, //getting form get api
      fax, //getting form get api
      iD, //getting form get api
      stateID, //getting form get api
      subscriberCompanyTypeID, //getting form get api
      isAccountSetup, //getting form get api
      file,
      confirmRoutingNumber, // not need to pass
      confirmAccountNumber, // not need to pass
      confirmTaxID, // not need to pass
      leaseGeneration, // hide lease generation for now
      ...restValue
    } = value;

    const sendData = {
      ...restValue,
      email: value?.subscriberEmail,
      zipCode:value?.zipCode.replace("-",""),
      useDifferentBillingAddress: useDifferentBillingAddress ? 1 : 0,
      ...(useDifferentBillingAddress
        ? {
            billStreetAddress: value?.billStreetAddress,
            billZipCode: value?.billZipCode.replace("-",""),
            billingCity: value?.billingCity,
            billingState: value?.billingState,
          }
        : {
            billStreetAddress: value?.streetAddress,
            billZipCode: value?.zipCode,
            billingCity: value?.city,
            billingState: value?.state,
          }),

      licenseActivationDate: value?.licenseActivationDate
        ? moment(value?.licenseActivationDate).format("MM/DD/YYYY")
        : null,
      licenseExpirationDate: value?.licenseExpirationDate
        ? moment(value?.licenseExpirationDate).format("MM/DD/YYYY")
        : null,
      thirdPartyIntegration:
        (state?.isAdding &&
          (methods?.watch("guestCard") === 1 ||
            methods?.watch("docUpload") === 1)) ||
        (!state?.isAdding &&
          (methods?.watch("guestCard") === 1 ||
            methods?.watch("docUpload") === 1))
          ? value?.thirdPartyIntegration
              ?.map((item, index) => {
                if (item?.appID?.value) {
                  if(item?.appURL?.slice(-1)!=="/"){
                    return {
                    ...item,
                    appURL: item?.appURL+"/",
                    appID: item?.appID?.value
                    };
                  }
                  return {
                    ...item,
                    appID: item?.appID?.value,
                  };
                }
                return null;
              })
              .filter((item) => item !== null)
          : !state?.isAdding &&
            methods?.watch("guestCard") === 0 &&
            methods?.watch("docUpload") === 0 &&
            getSubscriberDetails?.data[0]?.ThirdPartyIntegration[0]?.appID
          ? value?.thirdPartyIntegration
              ?.map((item, index) => {
                if (item?.appID?.value) {
                  return {
                    ...item,
                    status: "0",
                    appID: item?.appID?.value,
                  };
                }

                return null;
              })
              .filter((item) => item !== null)
          : [],
          paymentDate:(value?.payOutFrequency?.value === "Monthly")?(
            value?.paymentDate
            ? value?.paymentDate
            : "2"):((value?.payOutFrequency?.value === "Weekly")?(value?.paymentDate?.value?.toLowerCase()):"2"),
            // paymentDay: (value?.paymentDay?.value),
        // paymentDay: (value?.paymentDay?.value),
      accountNumber: value?.accountNumber?.replace(/\*/g, ""),
      ...(((methods?.watch("ats") === 0 && !state?.isAdding) ||
      (methods as any).watch("subscriberFee") <= 0) &&
      !state?.isAdding && {
        stripeAccountStatus: 0}),
      ...(((methods?.watch("ats") === 0 && state?.isAdding) ||
        (methods as any).watch("subscriberFee") <= 0) &&
        state?.isAdding && {
          bankName: "",
          accountHolderName: "",
          stripeAccountStatus: "",
          accountNumber: "",
          confirmAccountNumber: "",
          routingNumber: "",
          confirmRoutingNumber: "",
          taxID: "",
          confirmTaxID: "",
          businessWebsite: "",
          payOutFrequency: {value: "", label: ""},
          paymentDate: "",
          // paymentDay:""
        }),
      // ...(getSubscriberDetails?.data[0]?.AccountHolderName !==
      //   value?.accountHolderName && id
      //   ? { updateStripeAccount: 1 }
      //   : { updateStripeAccount: 0 }), // This keys added due to call third party api when changes is happen
      file: file && file?.type != undefined ? file : "",
      Logo: file ? restValue.logo : "",
      LogoURL: file ? restValue.logoURL : ""
    };

    const formData = new FormData();
    for (const [key, value] of Object.entries(sendData)) {
      if (value instanceof File) {
        formData.append(key, value);
      } else if (typeof value === "string") {
        formData.append(key, value);
      } else {
        formData.append(key, JSON.stringify(value));
      }
    }
    // if (!state?.isAdding) {
    //   let userID: any = id;
    //   formData.append("userID", userID);
    //   formData.append("subscriberID", value?.iD);
    // }

    create(
      state?.isAdding ? "add-subscriber" : "update-subscriber",
      { data: formData },
      {
        onSuccess: (data) => {
          notify(
            state?.isAdding
              ? `Subscriber created successfully.`
              : `Subscriber updated successfully.`,
            {
              type: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }
          );
          refresh();
          methods?.reset();
          navigate(-1);
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };

  const screeningFeeWatch = methods?.watch(`screeningFee`);
  const subscriberFeeWatch = methods?.watch(`subscriberFee`);
  const applicationFeeWatch = methods?.watch(`applicationFee`);
  const processingFeeWatch = methods?.watch(`processingFee`);
  const payOutFrequencyWatch = methods?.watch("payOutFrequency");

  useEffect(()=>{
        if(payOutFrequencyWatch?.value === "Monthly" && typeof methods?.watch("paymentDate") === "object")
        {methods?.setValue("paymentDate","")};
    },[payOutFrequencyWatch])

  useEffect(() => {
    if (
      (screeningFeeWatch && subscriberFeeWatch && applicationFeeWatch) ||
      applicationFeeWatch
    ) {
      methods?.setValue(
        `processingFee`,
        calculateProcessingFee(
          screeningFeeWatch,
          subscriberFeeWatch,
          applicationFeeWatch
        )
      );
      methods?.trigger(`processingFee`);
    }
  }, [screeningFeeWatch, subscriberFeeWatch, applicationFeeWatch]);

  // useEffect(() => {
  //   if (
  //     (screeningFeeWatch && subscriberFeeWatch && processingFeeWatch) ||
  //     processingFeeWatch
  //   ) {
  //     methods?.setValue(
  //       `applicationFee`,
  //       calculateApplicationFee(
  //         screeningFeeWatch,
  //         subscriberFeeWatch,
  //         processingFeeWatch
  //       )
  //     );
  //   }
  // }, [screeningFeeWatch, subscriberFeeWatch, processingFeeWatch]);

  // useEffect(() => {
  //   document.title = state?.isAdding
  //     ? "Manage Subscribers - Add New Subscriber"
  //     : "Manage Subscribers - Subscriber Details";
  // }, []);
  const togglePasswordVisibility = (index:number) => {
    setShowPassword(prev => {
      const newArray = [...prev];
      newArray[index] = !newArray[index];
      return newArray;
    });
  };
const {permissions} =usePermissions();
  if (isLoadingSubscriber || isLoadingStates || isLoadingThirdPartyApps || isLoadingScreeningCompany || isLoadingCompanyType || (!state?.isAdding && isLoadingData)) return <Loading />
  return (
    <Fragment>
      <div className="home_content">
        <div className="row">
          <div className="col-12 d-flex">
            <Breadcrumbs aria-label="breadcrumb">
              <Typography sx={{ fontWeight: "bold" }}>
                <button
                  title="Go Back"
                  style={{ color: "#2cc0e4" }}
                  className="btn btn-link"
                  onClick={() => navigate("/ats/manageSubscribers")}
                >
                  <ArrowBack />
                </button>
                {state?.isAdding ? (
                  <span>Add New Subscriber</span>
                ) : (
                  <span>Subscriber Details</span>
                )}
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className="mr-t30">
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputField2
                  label="Company Name"
                  source={`companyName`}
                  placeholder="Enter Company Name"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputSelectField
                  label={`Company Type`}
                  source={`companyType`}
                  options={getCompanyType?.records?.map((item) => ({
                    value: item?.ID,
                    label: item.CompanyType,
                  }))}
                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  disable={!state?.isAdding}
                  stopAutoFocus = {true}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <ToggleButtons
                  toggleButton={toggleButton}
                  label="Status"
                  labelClass={"lable-title"}
                  source={`active`}
                  onClickHandler={(e) => {
                    if (!state?.isAdding) {
                      if (methods?.watch(`active`) != e?.target?.value) {
                        setOpen(true);
                      }
                    }
                  }}
                />
              </div>
              {!isLoadingStates && (
              <div className="col-sm-12 mr-b20">
                <GoogleAutoComplete
                  notRequired={true}
                  groupFieldName={{
                    street: `streetAddress`,
                    city: `city`,
                    state: `state`,
                    zip: `zipCode`,
                    state_data: getStates?.data?.map((item) => ({
                      value: item?.id,
                      label: item?.StateShortCode + " - " + item?.StateName,
                    })),
                  }}
                  register={methods?.register(`addressSearch`)}
                  label={"Search  Address"}
                  labelClass={"lable-title"}
                />
              </div>)}
              <div className="col-sm-12 col-md-8 col-lg-8 mr-b20">
                <InputField2
                  label="Street Address"
                  source={`streetAddress`}
                  placeholder="Enter Street Address"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputField2
                  label="City"
                  source={`city`}
                  placeholder="Enter City"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputSelectField
                  label={`State`}
                  source={`state`}
                  options={getStates?.data?.map((item) => ({
                    value: item?.id,
                    label: item.StateShortCode + " - " + item.StateName,
                  }))}
                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  stopAutoFocus = {true}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputField2
                  label="Zip Code"
                  source={`zipCode`}
                  placeholder="Enter Zip Code"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <TextInputPhone
                  source={`telephone`}
                  label="Telephone"
                  isCountryCode={`country_code`}
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                  widthClass={"w-100"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  required={false}
                />
              </div>
              {!id && state?.isAdding ? (
                <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                  <InputField2
                    label="Subscriber Username"
                    source={`username`}
                    placeholder="Subscriber Username"
                    labelClass={"lable-title"}
                    additionalInputClass={"cus-control"}
                    // notRequired={true}
                  />
                </div>
              ) : (
                <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                  <InputField2
                    label="Subscriber Website"
                    source={`subscriberCompanyWebsite`}
                    placeholder="Subscriber Subscriber Website"
                    labelClass={"lable-title"}
                    additionalInputClass={"cus-control"}
                    notRequired={true}
                  />
                </div>
              )}
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputField2
                  label="Subscriber Email ID"
                  source={`subscriberEmail`}
                  placeholder="Subscriber Email ID"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                  // notRequired={true}
                  // disable={id ? true : false}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20"></div>
              <div className="row">
              <div className="col-sm-12 col-md-5 col-lg-3 mr-b20">
                <ToggleButtons
                  toggleButton={toggleEnabledButton}
                  label="GuestCard"
                  labelClass={"lable-title"}
                  source={`guestCard`}
                />
              </div>
              <div className="col-sm-12 col-md-5 col-lg-3 mr-b20">
                <ToggleButtons
                  toggleButton={toggleEnabledButton}
                  label="DocUpload"
                  labelClass={"lable-title"}
                  source={`docUpload`}
                />
              </div>
              <div className="col-sm-12 col-md-5 col-lg-3 mr-b20">
                <ToggleButtons
                  toggleButton={toggleEnabledButton}
                  label="ATS"
                  labelClass={"lable-title"}
                  source={`ats`}
                />
              </div>
              {methods?.watch("ats") ===1 &&
              <div className="col-sm-12 col-md-5 col-lg-3 mr-b20">
                <ToggleButtons
                  toggleButton={toggleEnabledButton}
                  label="Screening"
                  labelClass={"lable-title"}
                  source={`screening`}
                  disabled={true}
                  onClickHandler={() => {methods?.trigger("screeningCompany")}}
                />
              </div>
              }
              <div className="col-sm-12 col-md-5 col-lg-3 mr-b20">
                <ToggleButtons
                  toggleButton={toggleEnabledButton}
                  label="DocUpload Review Done Email"
                  labelClass={"lable-title"}
                  source={`reviewDone`}
                />
              </div>
              </div>
              {/* <div className="col-sm-12 col-md-2 col-lg-2 mr-b20">
                <ToggleButtons
                  toggleButton={toggleEnabledButton}
                  label="Lease Generation"
                  labelClass={"lable-title"}
                  source={`leaseGeneration`}
                />
              </div> */}
              {/* <div className="col-sm-12 col-md-2 col-lg-2 mr-b20"></div> */}
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputDateField
                  source={"licenseActivationDate"}
                  label="License Activation Date"
                  labelClass={"lable-title"}
                  //   minDate={new Date()}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputDateField
                  source={"licenseExpirationDate"}
                  label="License Expiration Date"
                  labelClass={"lable-title"}
                />
              </div>
              {methods?.watch('ats')===1 && 
              <Fragment>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputSelectField
                  label={`Screening Company`}
                  source={`screeningCompany`}
                  options={getScreeningCompany?.data?.map((item) => ({
                    value: item?.ID,
                    label: item.Name,
                  }))}
                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  disable={methods?.watch("screening") === 0 ? true : false}
                  required={methods?.watch("screening") === 0 ? false : true}
                  stopAutoFocus = {true}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputCurrencyField
                  label="Screening Fee"
                  source={`screeningFee`}
                  required={true}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  handleOnChange={() => {
                    methods?.trigger(`screeningFee`);
                  }}
                  removeDefaultZeroValue={true}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputCurrencyField
                  label="Subscriber Fee"
                  source={`subscriberFee`}
                  required={true}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  handleOnChange={() => {
                    methods?.trigger(`subscriberFee`);
                  }}
                  removeDefaultZeroValue={true}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputCurrencyField
                  label="Application Fee"
                  source={`applicationFee`}
                  required={true}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  handleOnChange={() => {
                    methods?.trigger(`applicationFee`);
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputCurrencyField
                  label="Processing Fee"
                  source={`processingFee`}
                  required={false}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  handleOnChange={() => {
                    methods?.trigger(`processingFee`);
                  }}
                  removeDefaultZeroValue={true}
                  disabled={true}
                  allowNegative={true}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputSelectField
                  label={`Application Fee Recipient`}
                  source={`applicationFeeRecipient`}
                  options={applicationFeeRecipientData?.map((item) => ({
                    value: item?.value,
                    label: item?.label,
                  }))}
                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  stopAutoFocus = {true}
                />
              </div>
              </Fragment>}
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputSelectField
                  label={`Enable OCR API`}
                  source={`enableOCRAPI`}
                  options={enableOCRAPIData?.map((item) => ({
                    value: item?.value,
                    label: item?.label,
                  }))}
                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  stopAutoFocus = {true}
                  // required={false}
                />
              </div>
              {/*Primary User Details starts*/}
              <div className="col-sm-12 mr-b20">
                <div
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.16)",
                    borderRadius: "4px",
                    padding: "10px 16px 16px 16px",
                  }}
                >
                  <div className="mb-3">
                    <label className="lable-title">Primary User Details</label>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                      <InputField2
                        label="Primary Contact First Name"
                        source={`primaryContactFirstName`}
                        placeholder="Enter Primary Contact First Name"
                        labelClass={"lable-title"}
                        additionalInputClass={"cus-control"}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                      <InputField2
                        label="Primary Contact Last Name"
                        source={`primaryContactLastName`}
                        placeholder="Enter Primary Contact Last Name"
                        labelClass={"lable-title"}
                        additionalInputClass={"cus-control"}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 mr-b20"></div>
                    <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                      <InputField2
                        label="Primary Contact Email"
                        source={`primaryContactEmail`}
                        placeholder="Enter Primary Contact Email"
                        labelClass={"lable-title"}
                        additionalInputClass={"cus-control"}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                      <InputField2
                        label="Primary Contact User Name"
                        source={`primaryContactUserName`}
                        placeholder="Enter Primary Contact User Name"
                        labelClass={"lable-title"}
                        additionalInputClass={"cus-control"}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                      <TextInputPhone
                        source={`primaryContactMobileNo`}
                        label="Primary Contact Mobile No"
                        isCountryCode={`country_code`}
                        labelClass={"lable-title"}
                        additionalInputClass={"cus-control"}
                        widthClass={"w-100"}
                        requiredClass={{
                          fontSize: "70%",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*Primary User Details end*/}
              {/*Billing Address starts*/}
              <div className="col-sm-12 mr-b20">
                <div
                  className="info-toggle mb-3"
                  style={{
                    borderRadius: "4px",
                  }}
                >
                  <div
                    className="toggle-heads change_color"
                    // onClick={(event) => {
                    //   event.preventDefault();
                    //   setBillingAddressAccordion((v) => !v);
                    // }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        padding: "10px 16px 10px 16px",
                      }}
                    >
                      <div className="row">
                        <div className="col-sm-12 col-md-8 col-lg-8">
                          <label className="lable-title">Billing Address</label>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                          <div className="d-flex justify-content-md-end">
                            <InputCheckBoxField
                              checked={
                                methods?.watch()?.useDifferentBillingAddress
                              }
                              notRequired={true}
                              label={"Use Different Billing Address"}
                              name={`useDifferentBillingAddress`}
                              // handleOnChange={() => {
                              //   props?.handleAPIDebounced();
                              // }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      // className={` ${
                      //   !billingAddressAccordion ? "d-none" : "d-display"
                      // } card-body cust-card-appl`}
                      className={` ${
                        !methods?.watch(`useDifferentBillingAddress`)
                          ? "d-none"
                          : "d-display"
                      } card-body cust-card-appl`}
                    >
                      <div
                        className="card card-body w-100"
                        style={{ paddingTop: 0 }}
                      >
                        <div className="row">
                          <div className="col-sm-12 mr-b20 mr-t20">
                            <GoogleAutoComplete
                              notRequired={true}
                              groupFieldName={{
                                street: `billStreetAddress`,
                                city: `billingCity`,
                                state: `billingState`,
                                zip: `billZipCode`,
                                state_data: getStates?.data?.map((item) => ({
                                  value: item?.id,
                                  label:
                                    item?.StateShortCode +
                                    " - " +
                                    item?.StateName,
                                })),
                              }}
                              register={methods?.register(`addressSearch`)}
                              label={"Search  Address"}
                              labelClass={"lable-title"}
                            />
                          </div>
                          <div className="col-sm-12 col-md-8 col-lg-8 mr-b20">
                            <InputField2
                              label="Billing Street Address"
                              source={`billStreetAddress`}
                              placeholder="Enter Billing Street Address"
                              labelClass={"lable-title"}
                              additionalInputClass={"cus-control"}
                            />
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                            <InputField2
                              label="Billing Zip Code"
                              source={`billZipCode`}
                              placeholder="Enter Billing Zip Code"
                              labelClass={"lable-title"}
                              additionalInputClass={"cus-control"}
                            />
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                            <InputField2
                              label="Billing City"
                              source={`billingCity`}
                              placeholder="Enter Billing City"
                              labelClass={"lable-title"}
                              additionalInputClass={"cus-control"}
                            />
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                            <InputSelectField
                              label={`Billing State`}
                              source={`billingState`}
                              options={getStates?.data?.map((item) => ({
                                value: item?.id,
                                label:
                                  item.StateShortCode + " - " + item.StateName,
                              }))}
                              labelAsteriskClass={"color-red"}
                              labelClass={"lable-title"}
                              requiredClass={{
                                fontSize: "70%",
                              }}
                              stopAutoFocus = {true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*Billing Address end*/}
              {/* Stripe Account start */}
              {methods?.watch("ats") === 1 &&
                (methods as any).watch("subscriberFee") > 0 && (
                  <div className="col-sm-12 mr-b20">
                    <div
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.16)",
                        borderRadius: "4px",
                        padding: "10px 16px 16px 16px",
                      }}
                    >
                      <div className="mb-3">
                        <label className="lable-title">Stripe Account</label>
                      </div>
                      {/* <JSONTree data={!checkModuleAccess(permissions, "Other Permissions", "Edit Stripe Settings")}/> */}
                       <fieldset disabled={!checkModuleAccess(permissions, "Other Permissions", "Edit Stripe Settings")}> 
                      <div className="row">
                        <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                          <InputField2
                            label="Bank Name"
                            source={`bankName`}
                            placeholder="Enter Bank Name"
                            labelClass={"lable-title"}
                            additionalInputClass={"cus-control"}
                            notRequired={
                              methods?.watch("ats") &&
                              (methods as any).watch("subscriberFee") > 0 &&
                              methods?.watch("stripeAccountStatus") !== 0
                                ? false
                                : true
                            }
                            disable={ methods?.watch("stripeAccountStatus") !== 0
                            ? false
                            : true}
                          />
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                          <InputField2
                            label="Account Holder Name"
                            source={`accountHolderName`}
                            placeholder="Enter Account Holder Name"
                            labelClass={"lable-title"}
                            additionalInputClass={"cus-control"}
                            notRequired={
                              methods?.watch("ats") &&
                              (methods as any).watch("subscriberFee") > 0 &&
                              methods?.watch("stripeAccountStatus") !== 0
                                ? false
                                : true
                            }
                            disable={ methods?.watch("stripeAccountStatus") !== 0
                            ? false
                            : true}
                          />
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                          <ToggleButtons
                            toggleButton={toggleButton}
                            label="Stripe Account Status"
                            labelClass={"lable-title"}
                            source={`stripeAccountStatus`}
                            onClickHandler={()=>{
                              methods?.trigger("bankName");
                              methods?.trigger("accountHolderName");
                              methods?.trigger("accountNumber");
                              methods?.trigger("confirmAccountNumber");
                              methods?.trigger("routingNumber");
                              methods?.trigger("confirmRoutingNumber");
                              methods?.trigger("taxID");
                              methods?.trigger("confirmTaxID");
                              methods?.trigger("businessWebsite");
                              methods?.trigger("payOutFrequency");
                              methods?.trigger("paymentDate");
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                          <InputField2
                            label="Account Number"
                            source={`accountNumber`}
                            placeholder="Enter Account Number"
                            labelClass={"lable-title"}
                            additionalInputClass={"cus-control"}
                            handleOnChange={(event) => {
                              // setting state for handling a condition based on value change
                              setCheckEventOccurs(true);
                              methods?.trigger("confirmAccountNumber");
                            }}
                            notRequired={
                              methods?.watch("ats") &&
                              (methods as any).watch("subscriberFee") > 0 &&
                              methods?.watch("stripeAccountStatus") !== 0
                                ? false
                                : true
                            }
                            disable={ methods?.watch("stripeAccountStatus") !== 0
                            ? false
                            : true}
                          />
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                          <InputField2
                            label="Confirm Account Number"
                            source={`confirmAccountNumber`}
                            placeholder="Enter Confirm Account Number"
                            labelClass={"lable-title"}
                            additionalInputClass={"cus-control"}
                            handleOnChange={(event) => {
                              // setting state for handling a condition based on value change
                              setCheckEventOccurs(true);
                            }}
                            notRequired={
                              methods?.watch("ats") &&
                              (methods as any).watch("subscriberFee") > 0 &&
                              methods?.watch("stripeAccountStatus") !== 0
                                ? false
                                : true
                            }
                            disable={ methods?.watch("stripeAccountStatus") !== 0
                            ? false
                            : true}
                          />
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 mr-b20"></div>
                        <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                          <InputField2
                            label="Routing Number"
                            source={`routingNumber`}
                            placeholder="Enter Routing Number"
                            labelClass={"lable-title"}
                            additionalInputClass={"cus-control"}
                            notRequired={
                              methods?.watch("ats") &&
                              (methods as any).watch("subscriberFee") > 0 &&
                              methods?.watch("stripeAccountStatus") !== 0
                                ? false
                                : true
                            }
                            disable={ methods?.watch("stripeAccountStatus") !== 0
                            ? false
                            : true}
                            handleOnChange={()=> methods?.trigger("confirmRoutingNumber")}
                          />
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                          <InputField2
                            label="Confirm Routing Number"
                            source={`confirmRoutingNumber`}
                            placeholder="Enter Confirm Routing Number"
                            labelClass={"lable-title"}
                            additionalInputClass={"cus-control"}
                            notRequired={
                              methods?.watch("ats") &&
                              (methods as any).watch("subscriberFee") > 0 &&
                              methods?.watch("stripeAccountStatus") !== 0
                                ? false
                                : true
                            }
                            disable={ methods?.watch("stripeAccountStatus") !== 0
                            ? false
                            : true}
                          />
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 mr-b20"></div>
                        <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                          <div className="emp-inputs form-label">
                            {/* <InputMaskField
                              notRequired={false}
                              maxLength={9}
                              label="Tax ID"
                              source={`taxID`}
                              mask={"999-99-9999"}
                              handleOnChange={() => {}}
                              placeholder="Enter Tax ID"
                            /> */}
                            <InputField2
                              label="Tax ID"
                              source={`taxID`}
                              placeholder="Enter Tax ID"
                              labelClass={"lable-title"}
                              additionalInputClass={"cus-control"}
                              notRequired={
                                methods?.watch("ats") &&
                                (methods as any).watch("subscriberFee") > 0 &&
                                methods?.watch("stripeAccountStatus") !== 0
                                  ? false
                                  : true
                              }
                              disable={ methods?.watch("stripeAccountStatus") !== 0
                            ? false
                            : true}
                            handleOnChange={()=>methods?.trigger("confirmTaxID")}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                          <div className="emp-inputs form-label">
                            {/* <InputMaskField
                              notRequired={false}
                              maxLength={9}
                              label="Confirm Tax ID"
                              source={`confirmTaxID`}
                              mask={"999-99-9999"}
                              handleOnChange={() => {}}
                              placeholder="Enter Tax ID"
                            /> */}
                            <InputField2
                              label="Confirm Tax ID"
                              source={`confirmTaxID`}
                              placeholder="Enter Confirm Tax ID"
                              labelClass={"lable-title"}
                              additionalInputClass={"cus-control"}
                              notRequired={
                                methods?.watch("ats") &&
                                (methods as any).watch("subscriberFee") > 0 &&
                                methods?.watch("stripeAccountStatus") !== 0
                                  ? false
                                  : true
                              }
                              disable={ methods?.watch("stripeAccountStatus") !== 0
                            ? false
                            : true}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 mr-b20"></div>
                        <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                          <InputField2
                            label="Business Website"
                            source={`businessWebsite`}
                            placeholder="Enter Business Website"
                            labelClass={"lable-title"}
                            additionalInputClass={"cus-control"}
                            notRequired={
                              methods?.watch("ats") &&
                              (methods as any).watch("subscriberFee") > 0 &&
                              methods?.watch("stripeAccountStatus") !== 0
                                ? false
                                : true
                            }
                            disable={ methods?.watch("stripeAccountStatus") !== 0
                            ? false
                            : true}
                          />
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                          <InputSelectField
                            label="Pay Out Frequency"
                            source={`payOutFrequency`}
                            options={payOutFrequencyData?.map((item) => ({
                              value: item?.value,
                              label: item?.label,
                            }))}
                            labelAsteriskClass={"color-red"}
                            labelClass={"lable-title"}
                            requiredClass={{
                              fontSize: "70%",
                            }}
                            required={
                              methods?.watch("ats") &&
                              (methods as any).watch("subscriberFee") > 0 &&
                              methods?.watch("stripeAccountStatus") !== 0
                                ? true
                                : false
                            }
                            disable={ methods?.watch("stripeAccountStatus") !== 0
                            ? false
                            : true}
                            stopAutoFocus = {true}
                          />
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                        {(methods?.watch("payOutFrequency")?.value) === "Monthly" && <InputField2
                            source={`paymentDate`}
                            placeholder="Enter Payment Date"
                            label="Day of the Month"
                            labelClass={"lable-title"}
                            notRequired={
                              methods?.watch("ats") &&
                              (methods as any).watch("subscriberFee") > 0 &&
                              methods?.watch("stripeAccountStatus") !== 0
                              && (methods?.watch("payOutFrequency")?.value) === "Monthly"
                                ? false
                                : true
                            }
                            disable={ methods?.watch("stripeAccountStatus") !== 0
                            ? false
                            : true}
                          />}
                          {(methods?.watch("payOutFrequency")?.value) === "Weekly" && <InputSelectField
                          label="Payment Day"
                          source={`paymentDate`}
                          options={paymentDayData?.map((item) => ({
                            value: item?.value,
                            label: item?.label,
                          }))}
                          labelAsteriskClass={"color-red"}
                          labelClass={"lable-title"}
                          requiredClass={{
                            fontSize: "70%",
                          }}
                          required={
                            methods?.watch("ats") &&
                            (methods as any).watch("subscriberFee") > 0 &&
                            methods?.watch("stripeAccountStatus") !== 0 &&
                            (methods?.watch("payOutFrequency")?.value) === "Weekly"
                              ? true
                              : false
                          }
                          disable={ methods?.watch("stripeAccountStatus") !== 0
                            ? false
                            : true}
                            stopAutoFocus = {true}
                        /> }
                        </div>
                      </div>
                    </fieldset> 
                    </div>
                  </div>
                )}
              {/* Stripe Account end */}
            </div>
            <div className="row mr-b20">
              <div className="col-12 mr-b20">
                <div
                  className="p-3"
                  style={{ background: "#f5fdff", borderRadius: "8px" }}
                >
                  <div className="row">
                    <div className="col-sm-12 col-md-2 col-lg-2 d-flex align-items-center">
                      <label className="lable-title">Company Logo</label>
                    </div>
                    <div className="col-sm-12 col-md-8 col-lg-4">
                      {/* {methods?.watch(`file`) !== null &&
                      !methods?.formState?.errors?.file ? (
                      <FilePreview2
                        img={methods?.watch(`file`)}
                        isPdf={false}
                        isfileName={false}
                        isDeletabel={false}
                      />
                      ) : (
                      <InputFileUpload
                        source={`file`}
                        acceptedFiles={"image/*"}
                      />
                      )} */}
                      <div className="d-flex align-items-center">
                        {methods?.watch(`file`) !== null &&
                          !methods?.formState?.errors?.file && (
                            <FilePreview2
                              img={methods?.watch(`file`)}
                              isPdf={false}
                              isDeletabel={false}
                              isfileName={false}
                            />
                          )}
                        {/* <div className="mx-3">
                          <InputFileUpload
                            source={`file`}
                            acceptedFiles={"image/*"}
                            buttonText={
                              methods?.watch(`file`) !== null &&
                              !methods?.formState?.errors?.file
                                ? `Change`
                                : `Upload`
                            }
                          />
                        </div> */}
                        {(methods?.watch(`file`) !== null &&
                              !methods?.formState?.errors?.file)?
                        <div className="mx-3">
                          <Button 
                           color='error'
                           startIcon={<DeleteIcon />}
                           onClick={()=>{
                            methods?.setValue(`file`,null);
                           }}
                          >Delete</Button>
                        </div>:
                        <div className="mx-3">
                          <InputFileUpload
                            source={`file`}
                            acceptedFiles={"image/*"}
                            buttonText={`Upload`}
                          />
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {(methods?.watch("guestCard") === 1 ||
              methods?.watch("docUpload") === 1) && (
              <div className="row">
                <div className="col-sm-12 mr-b50">
                  <div
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.16)",
                      borderRadius: "4px",
                      padding: "10px 16px 16px 16px",
                    }}
                  >
                    <div className="mb-3">
                      <label className="lable-title">
                        PMS Integration Details
                      </label>
                    </div>
                    <div className="row">
                      {fields.map((item, index) => {
                        return (
                          <div
                            className="col-12 my-2 py-2"
                            style={{
                              background: "#f5fdff",
                              borderRadius: "8px",
                            }}
                            key={index}
                          >
                            <div className="row">
                              {index > 0 && isAddedNewIntegration && (
                                <div className="col-sm-12 d-flex justify-content-end">
                                  <div className="card-title">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        remove(index);
                                        setIsAddedNewIntegration(false);
                                      }}
                                    >
                                      <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10.3333 3.00004H13.6667V4.33337H12.3333V13C12.3333 13.1769 12.2631 13.3464 12.1381 13.4714C12.013 13.5965 11.8435 13.6667 11.6667 13.6667H2.33333C2.15652 13.6667 1.98695 13.5965 1.86192 13.4714C1.7369 13.3464 1.66666 13.1769 1.66666 13V4.33337H0.333328V3.00004H3.66666V1.00004C3.66666 0.82323 3.7369 0.65366 3.86192 0.528636C3.98695 0.403612 4.15652 0.333374 4.33333 0.333374H9.66666C9.84347 0.333374 10.013 0.403612 10.1381 0.528636C10.2631 0.65366 10.3333 0.82323 10.3333 1.00004V3.00004ZM11 4.33337H2.99999V12.3334H11V4.33337ZM7.94266 8.33337L9.12133 9.51204L8.17866 10.4547L7 9.27604L5.82133 10.4547L4.87866 9.51204L6.05733 8.33337L4.87866 7.15471L5.82133 6.21204L7 7.39071L8.17866 6.21204L9.12133 7.15471L7.94266 8.33337ZM5 1.66671V3.00004H9V1.66671H5Z"
                                          fill="#2DC3E8"
                                        />
                                      </svg>
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              )}
                               <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <ToggleButtons
                                  toggleButton={toggleEnabledButton}
                                  label={
                                    (methods?.watch(
                                      `thirdPartyIntegration.${index}.appID`
                                    )?.label
                                      ? methods?.watch(
                                          `thirdPartyIntegration.${index}.appID`
                                        )?.label + " "
                                      : "") + "Status"
                                  }
                                  labelClass={"lable-title"}
                                  source={`thirdPartyIntegration.${index}.status`}
                                  onClickHandler={() =>
                                    methods?.trigger(`thirdPartyIntegration`)
                                  }
                                />
                              </div>
                          
                              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputSelectField
                                  label={`Select PMS`}
                                  source={`thirdPartyIntegration.${index}.appID`}
                                  options={getThirdPartyApps?.data
                                    // ?.filter((item) =>
                                    //   methods
                                    //     ?.watch(`thirdPartyIntegration`)
                                    //     ?.every(
                                    //       (includedApp) =>
                                    //         includedApp?.appID?.value !==
                                    //         item?.ID
                                    //     )
                                    // )
                                    ?.map((item) => ({
                                      value: item?.ID,
                                      label: item?.name,
                                    }))}
                                  labelAsteriskClass={"color-red"}
                                  labelClass={"lable-title"}
                                  requiredClass={{
                                    fontSize: "70%",
                                  }}
                                  stopAutoFocus = {true}
                                  disable={
                                    (methods?.watch(`thirdPartyIntegration.${index}.status`) === 0 ||
                                    methods?.getValues(
                                      `thirdPartyIntegration.${index}.integrationID`
                                    ))
                                      ? true
                                      : false
                                  }
                                  required={false}
                                />
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputField2
                                  label={
                                    (methods?.watch(
                                      `thirdPartyIntegration.${index}.appID`
                                    )?.label
                                      ? methods?.watch(
                                          `thirdPartyIntegration.${index}.appID`
                                        )?.label + " "
                                      : "") + "Username"
                                  }
                                  source={`thirdPartyIntegration.${index}.appUserName`}
                                  placeholder={
                                    "Enter" +
                                    (methods?.watch(
                                      `thirdPartyIntegration.${index}.appID`
                                    )?.label
                                      ? " " +
                                        methods?.watch(
                                          `thirdPartyIntegration.${index}.appID`
                                        )?.label +
                                        " "
                                      : " ") +
                                    "Username"
                                  }
                                  labelClass={"lable-title"}
                                  additionalInputClass={"cus-control"}
                                  notRequired={
                                    methods?.watch(`thirdPartyIntegration.${index}.status`) === 0
                                      ? true
                                      : false
                                  }
                                  disable={methods?.watch(`thirdPartyIntegration.${index}.status`) === 0}
                                />
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <div className="password_wrapper">
                                  <InputField2
                                    label={
                                      (methods?.watch(
                                        `thirdPartyIntegration.${index}.appID`
                                      )?.label
                                        ? methods?.watch(
                                            `thirdPartyIntegration.${index}.appID`
                                          )?.label + " "
                                        : "") + "Password"
                                    }
                                    source={`thirdPartyIntegration.${index}.appPassword`}
                                    type={showPassword[index] ? "text" : "password"}
                                    placeholder={
                                      "Enter" +
                                      (methods?.watch(
                                        `thirdPartyIntegration.${index}.appID`
                                      )?.label
                                        ? " " +
                                          methods?.watch(
                                            `thirdPartyIntegration.${index}.appID`
                                          )?.label +
                                          " "
                                        : " ") +
                                      "Password"
                                    }
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                    notRequired={
                                      methods?.watch(`thirdPartyIntegration.${index}.status`) === 0
                                        ? true
                                        : false
                                    }
                                    disable={methods?.watch(`thirdPartyIntegration.${index}.status`) === 0}
                                  />
                                  {methods?.watch(
                                    `thirdPartyIntegration.${index}.appPassword`
                                  ) !== "" && (
                                    <button
                                      type="button"
                                      className="password_toggle_button"
                                      // onClick={() =>
                                      //   setShowPassword((prev) => !prev)
                                        
                                      // }
                                      onClick={() => togglePasswordVisibility(index)}
                                      title={showPassword[index] ? "Hide" : "Show"}
                                    >
                                      {showPassword[index] ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </button>
                                  )}
                                </div>
                              </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                  <InputField2
                                    label={
                                      (methods?.watch(
                                        `thirdPartyIntegration.${index}.appID`
                                      )?.label
                                        ? methods?.watch(
                                            `thirdPartyIntegration.${index}.appID`
                                          )?.label + " "
                                        : "") + "URL"
                                    }
                                    source={`thirdPartyIntegration.${index}.appURL`}
                                    placeholder={
                                      "Enter" +
                                      (methods?.watch(
                                        `thirdPartyIntegration.${index}.appID`
                                      )?.label
                                        ? " " +
                                          methods?.watch(
                                            `thirdPartyIntegration.${index}.appID`
                                          )?.label +
                                          " "
                                        : " ") +
                                      "URL"
                                    }
                                    notRequired={
                                      methods?.watch(`thirdPartyIntegration.${index}.status`) === 0
                                        ? true
                                        : false
                                    }
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                    disable={methods?.watch(`thirdPartyIntegration.${index}.status`) === 0}
                                  />
                                </div>

                              {methods?.watch(
                                `thirdPartyIntegration.${index}.appID`
                              )?.value === 2 && (
                                <>
                                  <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                    <InputField2
                                      label={
                                        (methods?.watch(
                                          `thirdPartyIntegration.${index}.appID`
                                        )?.label
                                          ? methods?.watch(
                                              `thirdPartyIntegration.${index}.appID`
                                            )?.label + " "
                                          : "") + "Server Name"
                                      }
                                      source={`thirdPartyIntegration.${index}.serverName`}
                                      placeholder={
                                        "Enter" +
                                        (methods?.watch(
                                          `thirdPartyIntegration.${index}.appID`
                                        )?.label
                                          ? " " +
                                            methods?.watch(
                                              `thirdPartyIntegration.${index}.appID`
                                            )?.label +
                                            " "
                                          : " ") +
                                        "Server Name"
                                      }
                                      notRequired={
                                        methods?.watch(`thirdPartyIntegration.${index}.status`) === 0
                                          ? true
                                          : false
                                      }
                                      labelClass={"lable-title"}
                                      additionalInputClass={"cus-control"}
                                      disable={methods?.watch(`thirdPartyIntegration.${index}.status`) === 0}
                                    />
                                  </div>
                                  <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                    <InputField2
                                      label={
                                        (methods?.watch(
                                          `thirdPartyIntegration.${index}.appID`
                                        )?.label
                                          ? methods?.watch(
                                              `thirdPartyIntegration.${index}.appID`
                                            )?.label + " "
                                          : "") + "Database Name"
                                      }
                                      source={`thirdPartyIntegration.${index}.databaseName`}
                                      placeholder={
                                        "Enter" +
                                        (methods?.watch(
                                          `thirdPartyIntegration.${index}.appID`
                                        )?.label
                                          ? " " +
                                            methods?.watch(
                                              `thirdPartyIntegration.${index}.appID`
                                            )?.label +
                                            " "
                                          : " ") +
                                        "Database Name"
                                      }
                                      labelClass={"lable-title"}
                                      additionalInputClass={"cus-control"}
                                      disable={methods?.watch(`thirdPartyIntegration.${index}.status`) === 0}
                                      notRequired={
                                        methods?.watch(`thirdPartyIntegration.${index}.status`) === 0
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                  <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                    <InputField2
                                      label={
                                        (methods?.watch(
                                          `thirdPartyIntegration.${index}.appID`
                                        )?.label
                                          ? methods?.watch(
                                              `thirdPartyIntegration.${index}.appID`
                                            )?.label + " "
                                          : "") + "Platform"
                                      }
                                      source={`thirdPartyIntegration.${index}.platform`}
                                      placeholder={
                                        "Enter" +
                                        (methods?.watch(
                                          `thirdPartyIntegration.${index}.appID`
                                        )?.label
                                          ? " " +
                                            methods?.watch(
                                              `thirdPartyIntegration.${index}.appID`
                                            )?.label +
                                            " "
                                          : " ") +
                                        "Platform"
                                      }
                                      labelClass={"lable-title"}
                                      additionalInputClass={"cus-control"}
                                      disable={methods?.watch(`thirdPartyIntegration.${index}.status`) === 0}
                                      notRequired={
                                        methods?.watch(`thirdPartyIntegration.${index}.status`) === 0
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                  <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                    <InputField2
                                      label={
                                        (methods?.watch(
                                          `thirdPartyIntegration.${index}.appID`
                                        )?.label
                                          ? methods?.watch(
                                              `thirdPartyIntegration.${index}.appID`
                                            )?.label + " "
                                          : "") + "Interface Entity"
                                      }
                                      source={`thirdPartyIntegration.${index}.interfaceEntity`}
                                      placeholder={
                                        "Enter" +
                                        (methods?.watch(
                                          `thirdPartyIntegration.${index}.appID`
                                        )?.label
                                          ? " " +
                                            methods?.watch(
                                              `thirdPartyIntegration.${index}.appID`
                                            )?.label +
                                            " "
                                          : " ") +
                                        "Interface Entity"
                                      }
                                      labelClass={"lable-title"}
                                      additionalInputClass={"cus-control"}
                                      disable={methods?.watch(`thirdPartyIntegration.${index}.status`) === 0}
                                      notRequired={
                                        methods?.watch(`thirdPartyIntegration.${index}.status`) === 0
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                  <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                    <InputField2
                                      label={
                                        (methods?.watch(
                                          `thirdPartyIntegration.${index}.appID`
                                        )?.label
                                          ? methods?.watch(
                                              `thirdPartyIntegration.${index}.appID`
                                            )?.label + " "
                                          : "") + "Interface License"
                                      }
                                      source={`thirdPartyIntegration.${index}.interfaceLicense`}
                                      placeholder={
                                        "Enter" +
                                        (methods?.watch(
                                          `thirdPartyIntegration.${index}.appID`
                                        )?.label
                                          ? " " +
                                            methods?.watch(
                                              `thirdPartyIntegration.${index}.appID`
                                            )?.label +
                                            " "
                                          : " ") +
                                        "Interface License"
                                      }
                                      labelClass={"lable-title"}
                                      additionalInputClass={"cus-control"}
                                      disable={methods?.watch(`thirdPartyIntegration.${index}.status`) === 0}
                                      notRequired={
                                        methods?.watch(`thirdPartyIntegration.${index}.status`) === 0
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </>
                              )}
                             
                              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20"></div>
                            </div>
                          </div>
                        );
                      })}
                      {getThirdPartyApps?.data &&
                        getThirdPartyApps?.data?.length > 0 &&
                        fields?.length !== getThirdPartyApps?.data?.length && (
                          <div className="col-sm-12">
                            <div
                              className="g-btm-btn d-flex align-items-center justify-content-center"
                              style={{
                                borderTop: "0.5px solid #e0e0e0",
                                marginTop: "20px",
                              }}
                            >
                              <button
                                type="button"
                                className="blue-line-btn mr-r20 border-0"
                                onClick={() => {
                                  append({
                                    appID: null,
                                    status: 0,
                                    appPassword: "",
                                    appUserName: "",
                                  });
                                  setIsAddedNewIntegration(true);
                                }}
                                style={{
                                  fontSize: "1rem",
                                  fontFamily: "OpenSans-Bold",
                                  marginTop: "16px",
                                }}
                              >
                                <svg
                                  className="mr-r10"
                                  width="10"
                                  height="11"
                                  viewBox="0 0 10 11"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M4.33331 4.83337V0.833374H5.66665V4.83337H9.66665V6.16671H5.66665V10.1667H4.33331V6.16671H0.333313V4.83337H4.33331Z"
                                    fill="#2DC3E8"
                                  />
                                </svg>
                                Add
                              </button>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <Box
              // sx={{
              //   height: "60px",
              //   background: "#F5FDFF",
              //   boxShadow: "0px -1px 12px 0px rgba(0, 0, 0, 0.12)",
              // }}
              // display={"flex"}
              // justifyContent={"end"}
              // alignItems={"center"}
              // className="sticky-bottom mt-4"
              display={"flex"}
              justifyContent={"end"}
              alignItems={"center"}
              className="mt-4"
              minHeight="10vh"
            >
              <Box>
                <Button
                  sx={{ mx: 1 }}
                  onClick={() => {
                    refresh();
                    methods?.reset();
                    navigate(-1);
                  }}
                  variant="outlined"
                  color="secondary"
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  startIcon={
                    state?.isAdding ? (
                      <CheckIcon />
                    ) : (
                      <img src={save_icon} alt="save icon" onError={(event) => {
                        const imgElement = event.target as HTMLImageElement; // Type cast to HTMLImageElement
                        imgElement.style.display = 'none';
                        imgElement.style.visibility = 'hidden';
                      }}/>
                    )
                  }
                  sx={{ mx: 1 }}
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                >
                  {state?.isAdding ? `Create Subscriber` : `Update`}
                </Button>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </div>
      <DeactivateSubscriberModal
        open={open}
        setOpen={setOpen}
        setValue={methods?.setValue}
        status={methods?.getValues("active")}
      />
    </Fragment>
  );
};

export default AddorUpdateSubscriber;
