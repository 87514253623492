import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import WarningIcon from "@mui/icons-material/Warning";
import {
  useCreate,
  useNotify,
  useRefresh,
  useRecordContext,
} from "react-admin";
import { useForm } from "react-hook-form";
import SendIcon from "@mui/icons-material/Send";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";

import { Button as MuiButton } from "@mui/material";
import { boolean } from "yup";
import { useParams } from "react-router-dom";
import { JSONTree } from "react-json-tree";

type OnSubmitValues = {
  ApplicationNumber: any;
  Notice: number | string;
  IsEmail: number | string;
};

type WarningPrelimNoticeProps = {
  resendPreliminaryNotice?: boolean;
  vewDetailsMounting?: boolean;
  isPrelimNoticeEnabled?: boolean;
  showWithScoreAsIcon?: boolean;
};

const WarningPrelimNotice = ({
  resendPreliminaryNotice,
  vewDetailsMounting,
  isPrelimNoticeEnabled,
  showWithScoreAsIcon,
}: WarningPrelimNoticeProps) => {
  // state for popup show and hide
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //  record is a call for get application number
  const record = useRecordContext();
  const { applicationId } = useParams();

  const refresh = useRefresh();
  const notify = useNotify();

  const [create, { isLoading, error }] = useCreate();

  //   Initial call for useForm or initial state
  const { register, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      ApplicationNumber: record?.id || applicationId,
      Notice: "0",
      IsEmail: "0",
    },
  });

  //   form onsubmit Handler
  const onSubmit = (value: OnSubmitValues) => {
    create(
      "sendsecondaryapp",
      { data: value },
      {
        onSettled: (data, error) => {
          notify(`Message has been sent`, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          refresh();
          handleClose();
          reset();
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };

  return (
    <>
      {showWithScoreAsIcon && (
        <WarningIcon
          fontSize="small"
          onClick={handleOpen}
          sx={{ color: "#F9BB04" }}
        />
      )}

      {!showWithScoreAsIcon && vewDetailsMounting && (
        <button
          type="button"
          className=""
          data-bs-toggle="modal"
          data-bs-target="#transfer"
          onClick={handleOpen}
        >
          {resendPreliminaryNotice ? (
            <>
              <LoopOutlinedIcon

                sx={{ color: "#2dc3e8", marginRight: "4px", fontSize: "12px"  }}
              />
              Resend Preliminary Notice
            </>
          ) : (
            <>
              <SendIcon

                sx={{ color: "#2dc3e8", marginRight: "4px", fontSize: "12px" }}
              />
              Preliminary Notice
            </>
          )}
        </button>
      )}

      {!showWithScoreAsIcon && !vewDetailsMounting && (
        <MuiButton
          onClick={handleOpen}
          variant="text"
          sx={{ textTransform: "capitalize" }}
        >
          {resendPreliminaryNotice ? (
            <>
              <LoopOutlinedIcon

                sx={{ color: "#2dc3e8", marginRight: "4px", fontSize: "12px" }}
              />
              Resend Preliminary Notice
            </>
          ) : (
            <>
              <SendIcon

                sx={{ color: "#2dc3e8", marginRight: "4px", fontSize: "12px" }}
              />
              Preliminary Notice
            </>
          )}
        </MuiButton>
      )}

      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Prelim Notice</DialogTitle>
        <DialogContent>
          <div className="cus-modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-auto">
                  <div className="cus-input app-option-btn mr-b30">
                    <label htmlFor="" className="lable-title">
                      Pursuant to NJ law, the criminal report has been either
                      redacted from view or not processed and the applicant was
                      not asked about criminal history as part of their rental
                      application. notice?
                    </label>
                    <label htmlFor="" className="lable-title mr-t20">
                      In order to view/process the criminal report, you must
                      preliminarily notify the applicant that he/she/they are
                      approved/conditionally approved pending the results of
                      their criminal background report results and/or completion
                      of a secondary application. Have you provided or do you
                      wish to send this preliminary approval notice?
                    </label>
                    <div className="it-option" id="Notice">
                      <label htmlFor="notice1">
                        <input
                          type="radio"
                          id="notice1"
                          value="1"
                          {...register("Notice", { required: true })}
                        />
                        <span>Yes</span>
                      </label>
                      <label htmlFor="notice2">
                        <input
                          type="radio"
                          id="notice2"
                          value="0"
                          {...register("Notice", { required: true })}
                        />
                        <span>No</span>
                      </label>
                    </div>
                  </div>
                </div>
                {watch("Notice") == "1" ? (
                  <div className="col-auto">
                    <div className="cus-input app-option-btn mr-b30">
                      <label htmlFor="" className="lable-title">
                        Would you like to send preliminary notice to the
                        applicant now via E-mail?
                      </label>
                      <div className="it-option" id="IsEmail">
                        <label htmlFor="notice3">
                          <input
                            type="radio"
                            id="notice3"
                            value="1"
                            {...register("IsEmail", { required: true })}
                          />
                          <span>Yes</span>
                        </label>
                        <label htmlFor="notice4">
                          <input
                            type="radio"
                            id="notice4"
                            value="0"
                            {...register("IsEmail", { required: true })}
                          />
                          <span>No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="col-sm-12">
                  <div className="g-btm-btn d-flex align-items-center justify-content-end mr-t20">
                    <button
                      disabled={isLoading}
                      type="button"
                      className="blue-line-btn mr-r20"
                      onClick={() => {
                        handleClose();
                        reset();
                      }}
                    >
                      <svg
                        className="mr-r10"
                        width="10"
                        height="9"
                        viewBox="0 0 10 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 3.55732L8.3 0.257324L9.24267 1.19999L5.94267 4.49999L9.24267 7.79999L8.3 8.74266L5 5.44266L1.7 8.74266L0.757334 7.79999L4.05733 4.49999L0.757334 1.19999L1.7 0.257324L5 3.55732Z"
                          fill="#2DC3E8"
                        />
                      </svg>
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="blue-btn"
                      disabled={isLoading}
                    >
                      <svg
                        className="mr-r10"
                        width="12"
                        height="9"
                        viewBox="0 0 12 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.66666 6.61463L10.7947 0.485962L11.738 1.42863L4.66666 8.49996L0.423996 4.2573L1.36666 3.31463L4.66666 6.61463Z"
                          fill="white"
                        />
                      </svg>
                      {isLoading ? "Saving..." : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default WarningPrelimNotice;
