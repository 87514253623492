import React, { Fragment, useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, useParams } from "react-router";
import { ArrowBack } from "@mui/icons-material";

import YesNoRadioButton from "../../common/InputComponents/YesNoRadioButton";
import {
  Grid,
  Typography,
  TextField as MuiTextField,
  Box,
  Link,
  ToggleButton,
  ToggleButtonGroup,
  Checkbox,
  Stack,
  Button,
} from "@mui/material";
import save_icon from "../../../assets/images/save_icon.svg";
import ApplicationsettingLogo from "../../../assets/images/Frame (10).png";
import {
  Loading,
  useCreate,
  useGetList,
  useNotify,
  useRefresh,
  useStore,
  useUpdate,
  useListContext,
  useGetOne,
  useGetIdentity,
} from "react-admin";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import ToggleButtons from "../../common/InputComponents/ToggleButtons";
import InputSelectField from "../../common/InputComponents/InputSelectField";
import { FormProvider, useForm } from "react-hook-form";
import InputField2 from "../../common/InputComponents/InputField2";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { JSONTree } from "react-json-tree";
import { CircularProgress } from "@mui/material";
import InputCheckBoxField from "../../common/InputComponents/InputCheckBoxField";
import InputCurrencyField from "../../DocumentAnalysis/components/inputComponents/InputCurrencyFiled";
import formValidation from "../../../yupFormValidationRules/formValidator";
const calculateProcessingFee = (
  screeningFeeWatch,
  subscriberFeeWatch,
  applicationFeeWatch
) => {
  let processingFeeTotal =
    applicationFeeWatch - (+screeningFeeWatch + +subscriberFeeWatch);
  return processingFeeTotal;
}
const ApplicationProfileUpdate = () => {

  const toggleButton = [
    {
      value: "Inactive",
      label: "Inactive",
      icon: <ClearIcon />,
      borderColor: "#909090",
      backgroundColor: "#EEE",
      boxShadowColor: "#90909033",
      color: "#606060",
    },
    {
      value: "Active",
      label: "Active",
      icon: <CheckIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];


  const schema = yup.object().shape({
    ProfileName: yup.string().required("required"),
    ManagerID: yup.object().required("required").typeError("required"),
    NumberOfCoApplicant: yup
      .string()
      .required("required")
      .test("coApplicantRange", "Must be between 1 and 8", (value: any) => {
        const numValue = parseInt(value, 10);
        return numValue >= 1 && numValue <= 8;
      }),
    LinkExpiry: yup
      .string()
      .required("required")
      .test("linkexpiryRange", "Must be between 1 and 30 days", (value: any) => {
        const numValue = parseInt(value, 10);
        return numValue >= 1 && numValue <= 30;
      }),
    LookBackPeriod: yup.string().required("required"),
    BankLookBackPeriod: yup.string().required("required"),
    PreAuthTab: yup.string().required("required"),
    Active: yup.string().required("required"),

    DocUploadScreen: yup.string().required("required"),
    IsServiceAnimal: yup.string().required("required"),
    ScreeningFee: formValidation.feeValidation,
    SubscriberFee: formValidation.feeValidation,
    ProcessingFee: formValidation.processingFee,
    ApplicationFee: formValidation.feeValidation,
    FloorandUnit: yup.string().required("required"),
    CustomApplicationSetting: yup.string().required("required"),
    ApplicationComplitionEmail: yup.object().when("CustomApplicationSetting", {
      is: "Active",
      then: yup.object().required("Required").typeError("required"),
      otherwise: yup.object().nullable(),
    }),
    ApplicationComplitionEmailSendTo: yup
      .object()
      .when("CustomApplicationSetting", {
        is: "Active",
        then: yup.object().required("Required").typeError("required"),
        otherwise: yup.object().nullable(),
      }),
    AdverseLetterType: yup.object().when("CustomApplicationSetting", {
      is: "Active",
      then: yup.object().required("Required"),
      otherwise: yup.object().nullable(),
    }),
    // AdverseLetterTypeEmailSendTo: yup
    //   .object()
    //   .when("CustomApplicationSetting", {
    //     is: "Active",
    //     then: yup.object().required("Required").typeError("required"),
    //     otherwise: yup.object().nullable(),
    //   }),
    LeadSourceIDs: yup
      .array()
      .of(
        yup.object().shape({
          // value: yup.string().required("Required!").nullable(), // `value` must be a string, is required, but can be `null`
          // label: yup.string().nullable(), // `label` can be a string or `null`
        })
      )
      .min(1, "At least one source is required") // Ensures the array has at least one object
      .required("Required") // Ensures the array itself is not null or undefined
      .nullable(),
  });



  const methods = useForm({
    defaultValues: {
      CustomApplicationSetting: "Active",
      Active: "Active",
      ProcessingFee: "",
      ScreeningFee: "",
      SubscriberFee:""
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const { id } = useParams();
  console.log("1ID", id)
  const {
    data: getapplicationprofiledetails,
    isLoading: getapplicationprofiledetailsIsLoading,
    error: getapplicationprofiledetailsError,
  } = useGetOne("getapplicationprofiledetails",
    {

      id: [id],
    },
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {

    let data = getapplicationprofiledetails && getapplicationprofiledetails?.profiledetails?.[0];
    if (data && getsubscribermanagerandleads) {
      const getFormData: any = {
        ID: data?.ID,
        //  SubscriberID:2,
        ProfileName: data?.ProfileName,
        ApplicationFee: data?.ApplicationFee,
        NumberOfCoApplicant: data?.NumberOfCoApplicant,
        PreAuthTab: data?.PreAuthTab == "Yes" ? "Yes" : "No",
        // PreAuthTab:"Yes",
        LookBackPeriod: data?.LookBackPeriod,
        UpdatePermission: data?.UpdatePermission == 1 ? "Yes" : "No",
        DocUploadScreen: data?.DocUploadScreen == 1 ? "Yes" : "No",
        IsServiceAnimal: data?.IsServiceAnimal == 1 ? "Yes" : "No",
        SendLeaseLandlord: 1,
        ManagerID: getsubscribermanagerandleads?.data?.managerlist?.map((item) => ({
          label: item.ManagerName,
          value: item.ID,
        }))
          .filter((item) => data?.ManagerID?.split(",").includes(item?.value))[0],
        // ManagerID:{
        //     label: data?.ManagerName,
        //      value: data?.ID,
        // },

        // ManagerID:data?.ManagerID?.split(",").map((item) => ({
        //   label: item,
        //   value: item,
        // }))[0],

        LinkExpiry: data?.LinkExpiry,
        SubscriberFee: data?.SubscriberFee,
        ScreeningFee: data?.ScreeningFee,
        ProcessingFee: data?.ProcessingFee,
        BankLookBackPeriod: data?.BankLookBackPeriod,
        FloorandUnit: data?.FloorandUnit == 1 ? "Yes" : "No",
        // FloorandUnit:1,
        CustomApplicationSetting: data?.CustomApplicationSetting == 1 ? "Active" : "Inactive",
        Active: data?.Active == 1 ? "Active" : "Inactive",
        ApplicationComplitionEmail: data?.ApplicationComplitionEmail?.split(",").map((item) => ({
          label: item,
          value: item,
        }))[0],
        ApplicationComplitionEmailSendTo: data?.ApplicationComplitionEmailSendTo?.split(",").map((item) => ({
          label: item,
          value: item,
        }))[0],
        AdverseLetterType: data?.AdverseLetterType?.split(",").map((item) => ({
          label: item,
          value: item,
        }))[0],
        // AdverseLetterTypeEmailSendTo:data?.AdverseLetterTypeEmailSendTo?.split(",").map((item) => ({
        //     label: item,
        //     value: item,
        //   }))[0],
        CopyProfileID: 2,
        LeadSourceIDs: getsubscribermanagerandleads?.data?.LeadSourcelist?.map((item) => ({
          label: item.LeadSource,
          value: item.ID,
        }))
          .filter((item) => data?.LeadSourceIDs?.split(",").includes(item?.value)),

      };
      methods.reset(getFormData);
    }


  }, [getapplicationprofiledetails && getapplicationprofiledetails?.profiledetails?.[0]]);
  const [create, { isLoading, error }] = useCreate();
  const notify = useNotify();

  const refresh = useRefresh();


  // form submit handler
  const onSubmit = (value, e) => {
    console.log("Dat123", value);
    e.preventDefault();
    let data = getapplicationprofiledetails && getapplicationprofiledetails[0];
    const addData = {

      ...value,
      ID: value?.ID,
      SubscriberID: value.SubscriberID,
      //    SubscriberID:2,
      ProfileName: value?.ProfileName,
      BankLookBackPeriod: value?.BankLookBackPeriod,
      ApplicationFee: value?.ApplicationFee,
      NumberOfCoApplicant: value?.NumberOfCoApplicant,
      PreAuthTab: value?.PreAuthTab == "Yes" ? "Yes" : "No",
      // PreAuthTab:"Yes",
      LookBackPeriod: value?.LookBackPeriod,
      UpdatePermission: value?.UpdatePermission == "Yes" ? 1 : 0,
      DocUploadScreen: value?.DocUploadScreen == "Yes" ? 1 : 0,
      IsServiceAnimal: value?.IsServiceAnimal == "Yes" ? 1 : 0,
      SendLeaseLandlord: 1,
      ManagerID: value?.ManagerID?.value,
      SubscriberFee: value?.SubscriberFee,
      ScreeningFee: value?.ScreeningFee,
      ProcessingFee: value?.ProcessingFee,
      LinkExpiry: value?.LinkExpiry,
      FloorandUnit: value?.FloorandUnit == "Yes" ? 1 : 0,
      // FloorandUnit:1,
      CustomApplicationSetting: value?.CustomApplicationSetting == "Active" ? 1 : 0,
      Active: value?.Active == "Active" ? 1 : 0,
      ApplicationComplitionEmail: value?.ApplicationComplitionEmail?.value,
      ApplicationComplitionEmailSendTo: value?.ApplicationComplitionEmailSendTo?.value,
      AdverseLetterType: value?.AdverseLetterType?.value,
      AdverseLetterTypeEmailSendTo: value?.AdverseLetterTypeEmailSendTo?.value,
      CopyProfileID: 2,
      // LeadSourceIDs: value?.LeadSourceIDs,
      LeadSourceIDs: value?.LeadSourceIDs?.map((item) => item.value),




    };

    console.log(addData, "dump");
    create(
      `addupdateapplicationprofile`,
      { data: addData },
      {
        onSuccess: (data) => {
          notify(
            `Application updated successfully.`,

            {
              type: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }
          );
          refresh();
          methods?.reset();
          navigate(-1);
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );


  };

  const navigate = useNavigate();
  const {
    data: getsubscribermanagerandleads,
    isLoading: subscribermanagerandleadsIsLoading,
    error: subscribermanagerandleadsError,
  } = useGetOne("getsubscribermanagerandleads",
    {
      id: 1,
    },
    { refetchOnWindowFocus: false }
  );
  const screeningFeeWatch = methods?.watch<any>(`ScreeningFee`);
  const subscriberFeeWatch = methods?.watch<any>(`SubscriberFee`);
  const applicationFeeWatch = methods?.watch<any>(`ApplicationFee`);
  // const processingFeeWatch = methods?.watch<any>(`ProcessingFee`);




  useEffect(() => {
    if (
      (screeningFeeWatch && subscriberFeeWatch && applicationFeeWatch) ||
      applicationFeeWatch
    ) {
      methods?.setValue<any>(
        `ProcessingFee`,
        calculateProcessingFee(
          screeningFeeWatch,
          subscriberFeeWatch,
          applicationFeeWatch
        )
      );
      methods?.trigger(`ProcessingFee`);
    }
  }, [screeningFeeWatch, subscriberFeeWatch, applicationFeeWatch]);

  if (getapplicationprofiledetailsIsLoading )
    return <Loading />;
  return (
    <>
      <div className="home_content">
        <Grid style={{ marginBottom: 8 }} container spacing={3}>
          {/* Left side with title */}
          <Grid item xs={12} md={6}>
            <div className="row">
              <div className="col-12 d-flex">
                <Breadcrumbs aria-label="breadcrumb" separator=">">
                  <Typography sx={{ fontWeight: "bold" }}>
                    <Typography
                      color="textPrimary"
                      style={{
                        fontWeight: "bold",
                        color: "rgba(0, 0, 0, 0.38)",
                      }}
                      onClick={() => navigate(-1)}
                    >
                      Application Profile
                    </Typography>
                  </Typography>
                  <Typography
                    color="textPrimary"
                    style={{ fontWeight: "bold" }}
                  >
                    Profile Details
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              variant="outlined"
              startIcon={<img src={ApplicationsettingLogo} alt="application icon" />}
              onClick={() => {
                console.log(methods.watch(), "ghfghf")
                navigate(`/ats/settings/applicationProfile/${id}/applicationSettings`, {
                  state: { isAdding: true, formData: methods.watch() },
                })

              }
              }
              className="blue-btn px-4"
            >
              Application Settings
            </Button>
          </Grid>
        </Grid>

        <div className="form">
          <div className="auth-form mr-t30">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={4}>
                    <InputField2
                      label="Profile Name"
                      // register={methods.register("email")}
                      source={`ProfileName`}
                      placeholder="Enter profile name here"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                      disable={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField2
                      label="No. of Co-applicant"
                      // register={methods.register("email")}
                      source={`NumberOfCoApplicant`}
                      placeholder="Enter no of co-applicant"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <ToggleButtons
                      toggleButton={toggleButton}
                      label="Status"
                      labelClass={"lable-title"}
                      source={`Active`}
                      onClickHandler={(event) => event.stopPropagation()}
                    />
                  </Grid>
                </Grid>{" "}
                <br />
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={4}>
                    {/* <ToggleButtons
                      toggleButton={toggleButtonyesno}
                      label="Pre Auth Questionaries"
                      labelClass={"lable-title"}
                      source={`PreAuthTab`}
                      onClickHandler={(event) => event.stopPropagation()}
                    /> */}
                    <YesNoRadioButton
                      required={true}
                      label="Pre-Auth Questionaries"
                      source="PreAuthTab"
                      disable={undefined}
                      handleOnClick={undefined}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField2
                      label="Proof of Income Look Back Period (weeks)"
                      // register={methods.register("email")}
                      source={`LookBackPeriod`}
                      placeholder="Enter lookback period here"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField2
                      label="Link Expiry"
                      // register={methods.register("email")}
                      source={`LinkExpiry`}
                      placeholder="Enter expiry link here"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={4}>
                    <InputCurrencyField
                      label="Application Fee"
                      source={`ApplicationFee`}
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                      placeholder={undefined}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputCurrencyField
                      label="Subscriber Fee"
                      source={`SubscriberFee`}
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                      placeholder={undefined}
                      required={true}
                      handleOnChange={() => {
                        methods?.trigger(`SubscriberFee`);
                      }}
                      removeDefaultZeroValue={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputCurrencyField
                      label="Screening Fee"
                      source={`ScreeningFee`}
                      required={true}
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                      placeholder={undefined}
                      handleOnChange={() => {
                        methods?.trigger(`ScreeningFee`);
                      }}
                      removeDefaultZeroValue={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={4}>
                    <InputCurrencyField
                      label="Processing Fee"
                      source={`ProcessingFee`}
                      required={false}
                      labelClass={"lable-title"}
                      requiredClass={{
                        fontSize: "70%",
                      }}
                      handleOnChange={() => {
                        methods?.trigger(`ProcessingFee`);
                      }}
                      removeDefaultZeroValue={true}
                      disabled={true}
                      allowNegative={true}
                    />
                  </Grid>
                  <Grid item xs={4}>

                    <InputSelectField
                      label="Lead Source"
                      // register={methods.register("email")}
                      source={`LeadSourceIDs`}
                      labelClass={"lable-title"}
                      options={getsubscribermanagerandleads?.data?.LeadSourcelist?.map((item) => ({
                        label: item.LeadSource,
                        value: item.ID,
                      }))}
                      multiple
                      stopAutoFocus={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <YesNoRadioButton
                      required={true}
                      label="Floor Plan/Unit Required?"
                      source="FloorandUnit"
                      disable={undefined}
                      handleOnClick={undefined}

                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={4}>
                    <InputField2
                      label="Bank Look Back Period (months)"
                      source={`BankLookBackPeriod`}
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                      placeholder={undefined}
                    />
                  </Grid>


                </Grid>
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={4}>
                    <YesNoRadioButton
                      required={true}
                      label="Allow applicant to edit submitted/completed application?"
                      source="UpdatePermission"
                      disable={undefined}
                      handleOnClick={undefined}

                    />

                  </Grid>
                  <Grid item xs={4}>
                    <YesNoRadioButton
                      required={true}
                      label="Allow Document Upload Screen"
                      source="DocUploadScreen"
                      disable={undefined}
                      handleOnClick={undefined}

                    />
                  </Grid>
                  {/* <Grid item xs={4}>
                  <YesNoRadioButton
                  required={true}
                  label="Allow Service Animal Checkbox"
                  source="IsServiceAnimal"
                  disable={undefined}
                  handleOnClick={undefined}
                  
                />
                                </Grid> */}


                </Grid>

                <div>
                  <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)} className="mr-t30">
                      {" "}
                      <div className="info-toggle">
                        <div
                          className="toggle-heads"
                          onClick={(event) => {
                            event.preventDefault();

                          }}
                        >
                          <div className="row align-items-center justify-content-between ">
                            <div className="col">
                              <div className="d-flex align-items-center">
                                <b
                                  className="ms-3 me-2"
                                  style={{ fontSize: "18px", fontWeight: "700", fontFamily: "Open Sans" }}
                                >
                                  Custom Application Settings
                                </b>

                              </div>
                            </div>

                            <div className="col text-end m-1 remove_mr">
                              <ToggleButtons
                                toggleButton={toggleButton}
                                label=""
                                labelClass={"lable-title"}
                                source={`CustomApplicationSetting`}
                              />
                            </div>
                          </div>
                          {/*  ${!docuverusAccordion ? "d-none" : "d-display"
                              } */}
                          {methods.watch("CustomApplicationSetting") === "Active" &&
                            <div
                              className={` card-body cust-card-appl`}
                            >
                              <div
                                className="card card-body w-100"
                                style={{ paddingTop: 0 }}
                              >
                                <br />
                                <div className="row">
                                  <div className="col-sm-12 mr-b20">
                                    <p style={{ font: "Open Sans", fontWeight: 700, fontSize: '14px' }}>Send application completion confirmation email</p>
                                    <Grid container spacing={3} columns={12}>
                                      <Grid item xs={4}>
                                        <InputSelectField
                                          label="Value"
                                          // register={methods.register("email")}
                                          source={`ApplicationComplitionEmail`}
                                          labelClass={"lable-title"}
                                          options={[
                                            { label: "On completion of application", value: "On completion of application" },
                                            { label: "Once background screening report is completed", value: "Once background screening report is completed" },


                                          ]}
                                          stopAutoFocus={true}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <InputSelectField
                                          label="Send to"
                                          // register={methods.register("email")}
                                          source={`ApplicationComplitionEmailSendTo`}
                                          labelClass={"lable-title"}
                                          options={[
                                            { label: "Subscriber", value: "Subscriber" },
                                            { label: "Agent", value: "Agent" },
                                            { label: "Both", value: "both" },

                                          ]}
                                          stopAutoFocus={true}
                                        />
                                      </Grid>
                                    </Grid>
                                    <p style={{ font: "Open Sans", fontWeight: 700, fontSize: '14px' }}>Adverse Letter Type</p>
                                    <Grid container spacing={3} columns={12}>
                                      <Grid item xs={4}>
                                        <InputSelectField
                                          label="Value"
                                          // register={methods.register("email")}
                                          source={`AdverseLetterType`}
                                          labelClass={"lable-title"}
                                          options={[
                                            { label: "Fico", value: "fico" },
                                            { label: "Regular", value: "regular" },


                                          ]}
                                          stopAutoFocus={true}
                                        />
                                      </Grid>
                                      {/* <Grid item xs={4}>
                    <InputSelectField
                      label="Send to"
                      // register={methods.register("email")}
                      source={`AdverseLetterTypeEmailSendTo`}
                      labelClass={"lable-title"}
                      options={[
                        { label: "Subscriber", value: "Subscriber" },
                        { label: "Agent", value: "Agent" },
                        { label: "Both", value: "both" },
                        
                      ]}
                      stopAutoFocus={true}
                    />
                  </Grid> */}

                                    </Grid>

                                  </div>
                                </div>
                              </div>
                            </div>}
                        </div>
                      </div>



                    </form>
                  </FormProvider>
                </div>
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={4}>
                    <InputSelectField
                      label="Manager List"
                      // register={methods.register("email")}
                      source={`ManagerID`}
                      labelClass={"lable-title"}
                      options={getsubscribermanagerandleads?.data?.managerlist?.map(
                        (item) => ({
                          label: item.ManagerName,
                          value: item.ID,
                        })
                      )}
                      stopAutoFocus={true}
                    />
                  </Grid>

                </Grid>
                <Box
                  display={"flex"}
                  justifyContent={"end"}
                  alignItems={"center"}
                  className="mt-4"
                  minHeight="10vh"
                >
                  <Box
                   
                  >
                    {/* <div className="d-flex justify-content-end">
                      <div className="mx-3"> */}
                        <Button
                          sx={{ mx: 1 }}
                          onClick={() => {
                            refresh();
                            methods?.reset();
                            navigate(-1);
                          }}
                          variant="outlined"
                          color="secondary"
                          type="button"
                        >
                          Cancel
                        </Button>

                        <Button

                          startIcon={<img src={save_icon} alt="save icon" />}

                          sx={{ mx: 1 }}

                          variant="contained"

                          type="submit"

                        >

                          Update

                        </Button>
                      {/* </div>
                    </div> */}
                  </Box>
                </Box>
              </form>
            </FormProvider>
          </div>

        </div>
      </div>


    </>
  );
};

export default ApplicationProfileUpdate;
