import React from "react";
import { JSONTree } from "react-json-tree";
import greenCircle from "../../../assets/images/greenCircle.svg";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { ListSubheader, Typography } from "@mui/material";

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const Dark = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const checkCreateandModifiedDate = (dateString: string) => {
  if(dateString == 'Create Date should be Present'){
    return dateString
  }
  if (dateString.includes('Create Date')) {
    const createdDate: any = dateString.split('Modified Date').shift();
    // return JSON.stringify(createdDate.split('Create Date').pop())
    return <>
      <div className="d-flex justify-content-start">
        <div className="">CD: </div>
        <div className="px-1"> {createdDate.split('Create Date').pop()?.substring(1).trimEnd()}</div>
      </div>
      <div className="d-flex justify-content-start">
        <div className="">MD: </div>
        <div className="px-1">{dateString.split('Modified Date').pop()?.substring(1).trimEnd()}</div>
      </div>
    </>
  }
  return dateString;
}

const NewMetaDataRules = ({
  metaDataCheck,
  alignmentError,
  unknownTemplate,
  fraudTemplate,
}) => {
  return (<><div className="row" >
    {metaDataCheck.length > 0 && <div className="col-12 col-lg-12 col-md-12">
      <div>
        <div className="border-bottom small mb-2" style={{ fontSize: '10px' }}><strong>MetaData Check</strong></div>

        {metaDataCheck.map(item => {
          return (<div className="row justify-content-between  py-1">
            <div className="col-12 col-lg-4 col-md-6" style={{ fontSize: '10px' }}>{item.rule.split(' - ').shift()}</div>
            {item.rule.split(' - ').shift() == 'Font Check' && <div className="col-12 col-lg-6 col-md-6" style={{ fontSize: '10px' }}>{item.rule.split(' - ').pop().split(',').map(item => <Chip size="small" variant="filled"  label={item} />)}</div>}
            {item.rule.split(' - ').shift() != 'Font Check' && <div className="col-12 col-lg-6 col-md-6" style={{ fontSize: '10px' }}>{checkCreateandModifiedDate(item.rule.split(' - ').pop())}</div>}
            <div className="col-12 col-lg-2 col-md-2" style={{ fontSize: '8px', float: 'right' }}><strong>{item.status}</strong></div>
          </div>)
        })}
      </div>
    </div>}
    {alignmentError.length > 0 && <div className="col-12 col-lg-12 col-md-12 pt-4">
      <div>
        <div className="border-bottom small mb-2" style={{ fontSize: '10px' }}><strong>Allignment Error Check</strong></div>

        {alignmentError.map(item => {
          return (<div className="row justify-content-start py-1">
            <div className="col-12 col-lg col-md" style={{ fontSize: '10px' }}>{item.rule}</div>
            <div className="col-3 col-lg-2 col-md-2" style={{ fontSize: '8px' }}><strong>{item.status}</strong></div>
          </div>)
        })}
      </div>
    </div>}
  </div>
    <br />
    <div className="row ">
      {unknownTemplate.length > 0 && <div className="col-12 col-lg-12 col-md-12 mb-4">
        <div>
          <div className="border-bottom small mb-2" style={{ fontSize: '10px' }}><strong>Unknown Template check</strong></div>

          {unknownTemplate.map(item => {
            return (<div className="row justify-content-start py-1">
              <div className="col-12 col-lg col-md" style={{ fontSize: '10px' }}>{item.rule}</div>
              <div className="col-3 col-lg-2 col-md-2" style={{ fontSize: '8px' }}><strong>{item.status}</strong></div>
            </div>)
          })}
        </div>
      </div>}
      {fraudTemplate.length > 0 && <div className="col-12 col-lg-12 col-md-12">
        <div>
          <div className="border-bottom small mb-2" style={{ fontSize: '10px' }}><strong>Fraud Template Check</strong></div>

          {fraudTemplate.map(item => {
            return (<div className="row justify-content-start py-1">
              <div className="col-12 col-lg col-md" style={{ fontSize: '10px' }}>{item.rule}</div>
              <div className="col-3 col-lg-2 col-md-2" style={{ fontSize: '8px' }}><strong>{item.status}</strong></div>
            </div>)
          })}
        </div>
      </div>}
    </div></>)
};

export default NewMetaDataRules;
