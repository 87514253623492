import { debounce } from "lodash";
import React, { useEffect } from "react";
import { Button, Datagrid, DateField, FunctionField, List, ListBase, ListToolbar, NumberField, Pagination, Resource, SelectInput, TextField, useGetIdentity, useGetList, useGetOne, useListContext, useStore } from 'react-admin';
import {
    Box, TextField as MuiTextField,
    Button as MuiButton, Grid
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useState } from 'react';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import FilterModal from '../manageSubscribers/FilterModal';
import { useForm, FormProvider } from "react-hook-form";
import InputDateField from '../common/InputComponents/InputDateFieldOld';
import { ATS_API_URL } from '../../config';
import InputSelectField from '../common/InputComponents/InputSelectField';
import StatsAndGraph from './DocuploadReportCount';
import { JSONTree } from 'react-json-tree';
import {
    CreateButton,
    DatagridConfigurable,
    ExportButton,
    FilterButton,
    SelectColumnsButton,
    TopToolbar,
    SearchInput,
} from 'react-admin';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
import SelectField from "../common/InputComponents/SelectField";
import moment from "moment";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const VerificationReportFilter = (isTabActive) => {

    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const [switchSubscriberObj] = useStore("switchSubscriberName");
    const { data: identity } = useGetIdentity();
    const { displayedFilters, filterValues, setFilters, hideFilter, sort } =
        useListContext();



    const validationSchema = Yup.object().shape({
        rangeto: Yup.string()
            .required("Required!")
            .nullable()
            .test("is-valid-date", "Invalid date format", (value) => {
                return value ? moment(value, "MM/DD/YYYY", true).isValid() : true;
            }),
        rangefrom: Yup.string()
            .required("Required!")
            .nullable()
            .test("is-valid-date", "Invalid date format", (value) => {
                return value ? moment(value, "MM/DD/YYYY", true).isValid() : true;
            }),
    });
    const form = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: filterValues,
    });

    const applyFilter = (values) => {
        if (Object.keys(values).length > 0) {
            const filter = {
                ...values,
                end_date: values.end_date
                    ? moment(values.end_date).format("MM/DD/yyyy")
                    : "",
                start_date: values.start_date
                    ? moment(values.start_date).format("MM/DD/yyyy")
                    : "",
            };
            setFilters(filter, displayedFilters);
            handleClose();
        }
    };

    const rangeto = new Date();
    const currentDate = new Date();
    rangeto.setDate(currentDate.getDate() - 30);

    const formatDate = (date: Date) => {
        if (isNaN(date.getTime())) {
            return " ";
        }
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, so add 1
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${month}/${day}/${year}`;
    };
    useEffect(() => {
        if (isTabActive) {
            // Reset the form with default values when the tab becomes active
            form.reset({
                rangefrom: filterValues?.rangefrom ? "" : formatDate(rangeto),
                rangeto: filterValues?.rangeto ? "": formatDate(currentDate),
            });
        }
    }, [isTabActive]);

    const onSubmit = (values: any) => {
        // Apply the filters with the submitted values and additional filter values
        applyFilter({
            ...values,
            ...filterValues,
            app_invitation_type: filterValues?.app_invitation_type || 1,
        });

        // Retain the submitted values in the form
        form.reset({
            ...values,
        });
    };

 



    const start_date = form.watch("rangefrom");

    return (
        <>
            <div>
                <div className='row' style={{ top: 10, zIndex: '99999' }}>

                    <div className='col-12 col-md-12 col-sm-12' style={{ zIndex: 1000, position: "relative" }}>
                        <FormProvider {...form}>
                            <form onSubmit={form.handleSubmit(onSubmit)}>

                                <Box
                                    className="date-label"
                                    sx={{
                                        position: "relative",
                                        paddingTop: 1,
                                        display: "flex", // Add this to make the fields display inline
                                        alignItems: "center",
                                        justifyContent: "space-between", // Add this to make the fields take equal space

                                    }}
                                >
                                    <span style={{ paddingRight: 2, paddingBottom: 10 }}>
                                        <strong>From:</strong>
                                    </span>
                                    <InputDateField
                                        source={"rangefrom"}
                                        maxDate={new Date()}
                                        required={false}


                                    />
                                    <strong
                                        style={{
                                            paddingRight: 2,
                                            paddingLeft: 2,
                                            paddingBottom: 10,
                                            fontSize: "14px",
                                            color: "#303030",
                                            fontWeight: "inherit",
                                        }}
                                    >
                                        &nbsp;To:&nbsp;
                                    </strong>
                                    <InputDateField
                                        source={"rangeto"}
                                        maxDate={new Date()}
                                        minDate={start_date ? moment(start_date).toDate() : undefined}
                                        required={false}

                                    />


                                    <MuiButton sx={{ mx: 1, marginBottom: 2 }} variant="contained" type="submit">
                                        Apply
                                    </MuiButton>

                                </Box>

                            </form>
                        </FormProvider></div>
                </div>
            </div>

        </>
    )
}

export default VerificationReportFilter;


