import { Create, FunctionField, ListBase, ListGuesser, ListToolbar, Pagination, Resource, useDelete, useRecordContext, useRefresh } from "react-admin";
import { NumericFormat } from "react-number-format";
import FilterChips from "../../componets/Ats/SuperAdminInbox/FilterChips";
import moment from "moment";
import { BooleanField, Datagrid, DateField, List, NumberField, ReferenceField, TextField } from 'react-admin';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import {useNavigate} from "react-router-dom";
import CreatePredefinedMessageAdmin from "./CreatePredefinedMessage";

const CreateButton = () => {
    const naviage = useNavigate();
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<AddIcon />}
      onClick={()=>naviage("/ats/predefinedMessage/create")}
    >
      Create
    </Button>
  );
};

const DeleteButton = () => {
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  // delete api define 
  const record = useRecordContext();
  const [deleteOne, { isLoading: isDeleting, error,isSuccess }] = useDelete(
    'predefinedmessageAdmin',
    { id: record.id, previousData: record },
);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = () => {
    deleteOne();
    setOpen(false);
  };
  useEffect(() => {
    if (isSuccess) {
      refresh();
    }
  }, [isSuccess]);

  return (
    <>
      <Button
        variant="text"
        color="error"
        startIcon={<DeleteIcon />}
        onClick={handleClickOpen}
      >
        Delete
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this item? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const PredefinedMessageAdmin = () => {
    return (<>
        <Resource name="predefinedMessageAdmin" list={PredefinedMessageAdminList} create={<CreatePredefinedMessageAdmin/>} />
    </>)
}
const MyList = ({ children, filters, actions, ...props }) => {
    return (
        <ListBase
            {...props}
            perPage={100} // Number of items per page
        >
            {<ListToolbar
                filters={filters}
                actions={actions}
            />
}
           { <FilterChips excludeKeys={['app_invitation_type']} />}
            {children}
            <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
            <br />
            <br />
            <br />
            <br />
        </ListBase>
    );
};

export const PredefinedMessageAdminList = () => {
    return (
        <>
            <MyList
                filters={""}  //<FilterReports />
                actions={<CreateButton/>} //<ExportRevenueReport />
                sort={{ field: "ApplicationNumber", order: "DESC" }}
                children={<PredefinedmessageAdminListField />}
            />
        </>
    )
}

const EditButton = ({onClick}) => {
    return (
      <Button
        variant="text"
        color="primary"
        startIcon={<EditIcon />}
        onClick={onClick}
      >
        Edit
      </Button>
    );
  };
  
  const EditButtonWithRecord = () => {
    const record = useRecordContext();
    const navigate = useNavigate();
    
    if (!record) return null;

    return (
        <EditButton 
            onClick={() => {
                navigate(`/ats/predefinedMessage/${record.id}`);
            }} 
        />
    );
};

export const PredefinedmessageAdminListField = () => {
    const navigate = useNavigate();
    return(
        <CustomDataGrid>
            <TextField source="Message" />
            <DateField source="CreatedOn" label={'Created'} />
            <DateField source="ModifiedOn" label={'Modified'} />
            <EditButtonWithRecord />
            <DeleteButton/>
        </CustomDataGrid>
)};
const CustomDataGrid = (props) => {
    return (<Datagrid bulkActionButtons={false} sx={{
        "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
        "& .RaDatagrid-expandIcon": { color: "#fff" },
        "& .RaDatagrid-expandIconCell": { backgroundColor: "primary.main" },
        "& .RaDatagrid-headerCell": {
            fontWeight: "bold",
            fontSize: 12,

        },
        "& .RaDatagrid-table": {
            fontSize: 12,
        },
        "& .MuiToggleButton-root.Mui-selected": {
            fontWeight: 700,
        },
        // marginTop: '40px'
    }}>
        {props.children}
    </Datagrid>


    )
}
export default PredefinedMessageAdmin;