import { format } from "date-fns"
import { BooleanField, Datagrid, DateField, DeleteButton, FunctionField, List, ListBase, ListGuesser, ListToolbar, NumberField, Pagination, Resource, TextField, useListContext, useNotify, useStore } from "react-admin"

import {
    Grid,
    Typography,
    TextField as MuiTextField,
    Box,
    Link,
    ToggleButton, 
    ToggleButtonGroup,
    Checkbox,
    Stack,
    Button as MuiButton
  } from "@mui/material";
  
  import PDF from "./../../../../assets/images/Frame (5).png";
  import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
  import InputAdornment from "@mui/material/InputAdornment";
  import SearchIcon from "@mui/icons-material/Search";

import { debounce } from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import React from "react";
import FilterChips from "../Ats/SuperAdminInbox/FilterChips";
import TimeAgo from "javascript-time-ago";
import ReactTimeAgo from "react-time-ago";


export const OcrQueue = () => {
    return <Resource name="getocrqueuelist"  list={GetocrqueuelistList} />
 }
 const GetocrqueuelistList = () => (
  <List>
      <Datagrid>
      <TextField source="id" />
          <TextField source="ApplicantID" />
          <TextField source="ApplicationNumber" />
          <TextField source="DocumentID" />
          <TextField source="Retries" />
          <BooleanField source="OCRCompleted" />
          <FunctionField label={'Queue Time'} render={record=>{
            return <ReactTimeAgo
            date={record?.StartDate}
            locale="en-US"
            timeStyle="round"
          />
          }}/>
          <BooleanField source="OCRStarted" />
          {/* <DateField source="UpateSOCRFlagDate" /> */}
          <FunctionField label={'Update SOCR Flag'} render={record=>{
            return <ReactTimeAgo
            date={record?.UpateSOCRFlagDate}
            locale="en-US"
            timeStyle="round"
          />
          }}/>
          <TextField source="DocumentPath" />
          <TextField source="BlobPathfile" />
          <NumberField source="DocumentTypeID" />
          <TextField source="EmployerNumber" />
          <TextField source="AppInvitationType" />
          <DeleteButton/>
      </Datagrid>
  </List>
);
export default OcrQueue;