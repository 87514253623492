
import React, { Fragment, useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, useLocation } from "react-router";
import { ArrowBack } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";

import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button } from "@mui/material";
import ReactSelect from "react-select";

import {
  Loading,
  useCreate,
  useGetList,
  useNotify,
  useRefresh,
  useStore,
  useUpdate,
} from "react-admin";
import { useParams } from "react-router-dom";
import save_icon from "../../../../assets/images/save_icon.svg";
import formValidation from "../../../../yupFormValidationRules/formValidator";
import InputField2 from "../../../common/InputComponents/InputField2";
import QuillTextEditor from "../../../common/InputComponents/TextEditorWithDataField/QuillTextEditor";
import ToggleButtons from "../../../common/InputComponents/ToggleButtons";
import { JSONTree } from "react-json-tree";
import { method } from "lodash";


const UpdateAdverseLetter = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const notify = useNotify();
  const [create] = useCreate();
  const refresh = useRefresh();
  const { data: dataField } = useGetList("GETDataFiled");
  const [dataFieldEnabled, setDataFieldEnabled] = useState(true);
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const {
    data: getadverseletter,
    isLoading: loadingAdverseLetterDetails,
  } = useGetList(
    "getadverseletter", {
    filter: {
      subscriberID: switchSubscriberObj?.value,
      id: [id],
    }
  },
    {
      enabled: switchSubscriberObj?.value && id ? true : false,
      refetchOnWindowFocus: false,
    }

  );
  console.log("Adverse", getadverseletter)

  const toggleButton = [
    {
      value: "Inactive",
      label: "Inactive",
      icon: <ClearIcon />,
      borderColor: "#909090",
      backgroundColor: "#EEE",
      boxShadowColor: "#90909033",
      color: "#606060",
    },
    {
      value: "Active",
      label: "Active",
      icon: <CheckIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];

  // Initial form values
  const validationSchema = yup.lazy((value) =>
    yup.object().shape({
      Active: formValidation.StringRequiredValidation,
    })
  );

  //   const defaultFormValues = {
  //     // Active: 0,
  //     // LetterName:"",
  //     // Subject: "",
  //     // isDvDefault: null,
  //     // Template: "",
  //     Body: " ",
  //     LetterKey: " ",
  //     LetterName: " ",
  //     Status: " ",
  //     Subject:""
  //  };

  const methods = useForm({
    // defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });


  useEffect(() => {
    let data = getadverseletter && getadverseletter[0];
    console.log("data", data);
    const getFormData: any = {
      Body: data?.Body,
      LetterKey: data?.LetterKey,
      LetterName: data?.LetterName,
      Active: data?.Status,
      Subject: data?.Subject,


    };
    console.log("Get form", data?.Status)
    methods.reset(getFormData);
  }, [getadverseletter && getadverseletter[0]]);
  const [update, { isLoading, error }] = useUpdate();

  const onSubmit = (value, e) => {

    console.log("Value", value)
    e.preventDefault();
    console.log(value);
    let data = getadverseletter && getadverseletter[0];
    update(
      "update-adverse-letter-details",
      {
        data: {

          // ...value,
          id: value?.id,
          subscriberid: switchSubscriberObj?.value,
          // Body: data?.Body,
          letterkey: value?.LetterKey,
          // LetterName: data?.LetterName,
          template: value?.Body,
          // status: value?.Status,
          subject: value?.Subject,
          active: value?.Active == "Active" ? 1 : 0,
          // active:1,

          // TemplateApplicableFor: data?.TemplateApplicableFor,
        },
      },

      {
        onSuccess: (data) => {
          notify(`Adverse Letter details updated successfully.`, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          methods?.reset();
          navigate(-1);
          refresh();
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };

  if (loadingAdverseLetterDetails) return <Loading />;
  return (
    <>

      <div className="home_content">
        <div className="row">
          <div className="col-12 d-flex">
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Typography sx={{ fontWeight: "bold" }}>
                <Typography
                  color="textPrimary"
                  style={{ fontWeight: "bold", color: "rgba(0, 0, 0, 0.38)" }}
                  onClick={() => navigate(-1)}
                >
                  Adverse Letters
                </Typography>
              </Typography>
              <Typography color="textPrimary" style={{ fontWeight: "bold" }}>
                Letter Details
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className="mr-t30">
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputField2
                  label="Letter Name"
                  source={`LetterName`}
                  placeholder="Enter Letter Name"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                  disable={true}
                />
              </div>

              {/* <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
              <ToggleButtons
                  toggleButton={toggleButton}
                  label="Status"
                  labelClass={"lable-title"}
                  source={`Active`}
                />
              </div> */}
            </div>

            <div
              className="toggle-heads"
            // onClick={(event) => {
            //     event.preventDefault();
            //     setDocuverusAccordion((v) => !v);
            // }}
            >

              <br />
              <div className="row d-flex">
                <div className="col-sm-9 mr-b10" style={{ width: "300px" }}>
                  <InputField2
                    label="Subject"
                    source={`Subject`}
                    labelClass={"lable-title"}
                    additionalInputClass={"cus-control"}
                    placeholder="Enter Subject"

                  />
                </div>

              </div>
              {/* {
                <div
                  className={`d-flex justify-content-between ${dataFieldEnabled ? "d-none" : ""
                    }`}
                >
                  <div style={{ width: "300px" }}>
                    <ReactSelect
                      onChange={(selected) => {
                        const inputElement =
                          document.querySelector<HTMLInputElement>(
                            'input[placeholder="Enter Subject"]'
                          );

                        if (inputElement) {
                          console.log(inputElement, selected.value);
                          methods.setValue(
                            "Subject",
                            (inputElement.value += selected.value)
                          );
                        } else {
                          console.log("Input element not found");
                        }
                      }}
                      options={dataField}
                    />
                  </div>
                  <p>&nbsp;</p>
                </div>
              } */}

              <br />
              <div className="row d-flex">
                <div className="col-sm-11 mr-b20">
                  <QuillTextEditor
                    dataFieldOptions={dataField}
                    source={"Body"}
                    control={methods.control}
                  />
                </div>
              </div>

            </div>
            <Box
              display={"flex"}
              justifyContent={"end"}
              alignItems={"center"}
              className="mt-4"
              minHeight="10vh"
            >
              <Box>
                <Button
                  sx={{ mx: 1 }}
                  onClick={() => {
                    methods?.reset();
                    navigate(-1);
                  }}
                  variant="outlined"
                  color="secondary"
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  startIcon={!isLoading && <img src={save_icon} alt="save icon" />}
                  sx={{ mx: 1 }}
                  variant="contained"
                  type="submit"
                >
                  {isLoading ? (
                    <CircularProgress size="20px" sx={{ color: "white" }} /> // Show loader when isLoading is true
                  ) : (
                    <span>Update</span>
                  )}
                </Button>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default UpdateAdverseLetter;
