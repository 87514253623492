import React, { useEffect } from "react";
import EmailIcon from "@mui/icons-material/Email";
import { Box, Button, InputAdornment } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  TextInput,
  required,
  useUpdate,
  useNotify,
  useRefresh,
  useGetList,
  SelectField,
  SelectInput,
  useCreate,
} from "react-admin";
import { useForm, FormProvider, get } from "react-hook-form";
import { Button as MuiButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { JSONTree } from "react-json-tree";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import mail from "../../assets/images/mail.svg";
import InputSelectField from "../common/InputComponents/InputSelectField";
import InputTextArea from "../common/InputComponents/InputTextArea";
import LoadingSpinner from "../common/loadingSpinner";

type SendEmailFormProps = {
  handleClose: () => void;
  email: string;
  record: any[];
  mobile_number: number;
};

// validation schema here
const validationSchema = Yup.lazy((value) =>
  Yup.object().shape({
    pre_message: Yup.object()
      .shape({
        value: Yup.string().nullable(),
        label: Yup.string().nullable(),
      })

      .nullable(),

    mail_html_body: Yup.string()
      .nullable()
      .trim("Message is required!")
      .max(5000, "maximum 5000 characters is required")
      .required("Message is required!"),
    email: Yup.string()
      .email("Invalid email format")
      .max(75, "maximum 75 characters is required")
      .matches(/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/, "Invalid domain"),
  })
);

const SendEmailForm = (props: SendEmailFormProps) => {
  // call get pre define message data
  const { data: preDefinedMessageList, isLoading: isLoadingMessageLoading } =
    useGetList("getpredefinemessage", {filter:{appID:props.record[0]?.AppID}});

  // form initial states
  const form = useForm({
    defaultValues: {
      to: props?.email,
      mobile_number: props?.mobile_number,
      mail_html_body: "",
      message_type: "0",
      pre_message: null,
    },
    resolver: yupResolver(validationSchema),
  });

  // Destructuring form
  const { register, watch } = form;

  const refresh = useRefresh();
  const notify = useNotify();

  // call usecreate
  const [create, { isLoading, error }] = useCreate();

  // form on submit handler
  const onSubmit = (value) => {
    const { pre_message, message_type, ...rest } = value;

    if (value?.message_type == "0") {
      const sendData = { to: value.to, mail_html_body: value.mail_html_body };
      create(
        "sendemailtoapplicant",
        { data: sendData },
        {
          onSettled: (data, error) => {
            notify(`Message has been sent`, {
              type: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
            refresh();
            props?.handleClose();
          },
          onError: (e: any) =>
            notify(`Operation fail:${e.message}`, {
              type: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }),
        }
      );
    } else {
      const sendData = { to: value.mobile_number, body: value.mail_html_body };
      create(
        "sendsmstoapplicant",
        { data: sendData },
        {
          onSettled: (data, error) => {
            notify(`Message has been sent`, {
              type: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
            refresh();
            props?.handleClose();
          },
          onError: (e: any) =>
            notify(`Operation fail:${e.message}`, {
              type: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }),
        }
      );
    }
  };
  if (isLoadingMessageLoading) return <>Loading...</>;
  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-auto">
              <div className="cus-input app-option-btn mr-b20">
                {watch("message_type") == "0" ? (
                  <div className="it-option" id="a-oprtions">
                    {props.record
                      ?.filter((item) => item.ApplicantStatus !== "Removed")
                      .map((item) => (
                        <label
                          htmlFor={item?.id}
                          key={item?.id}
                          className="mb-2"
                        >
                          <input
                            type="radio"
                            id={item?.id}
                            value={item?.Email}
                            {...register("to")}
                          />
                          <span>
                            {item?.FirstName} {item?.LastName}
                          </span>
                        </label>
                      ))}
                  </div>
                ) : (
                  <div className="it-option" id="a-oprtions">
                    {props.record?.map((item) => (
                      <label htmlFor={item?.id} key={item?.id} className="mb-2">
                        <input
                          type="radio"
                          id={item?.id}
                          value={item?.MobileNumber}
                          {...register("to")}
                        />
                        <span>
                          {item?.FirstName} {item?.LastName}
                        </span>
                      </label>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mr-b20">
              <InputSelectField
                label="Predefined Message"
                source={`pre_message`}
                options={preDefinedMessageList?.map((item) => ({
                  value: item?.Message,
                  label: item?.Message,
                }))}
                required={false}
                labelAsteriskClass={"color-red"}
                labelClass={"lable-title"}
                requiredClass={{
                  fontSize: "70%",
                }}
                onChangeCallback={() => {
                  const preMessage: any = form.getValues("pre_message");
                  const currentMailBody: any = form.getValues("mail_html_body");

                  const updatedMailBody = `${currentMailBody}  ${preMessage.value}`;
                  console.log(currentMailBody, "hi");
                  form.setValue("mail_html_body", updatedMailBody, {
                    shouldValidate: true,
                    shouldTouch: true,
                  });
                }}
                fontsize="14px"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mr-b30">
              <InputTextArea
                register={"mail_html_body"}
                label="Message Text"
                placeholder="Message Text"
                labelClass={"lable-title"}
                labelAsteriskClass={"color-red"}
                additionalInputClass={"cus-control"}
                styleProperty={{ height: "auto" }}
                messageText={false}
                requiredClass={{
                  fontSize: "70%",
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-auto">
              <div className="cus-input app-option-btn mr-b20">
                <div className="it-option" id="a-oprtions">
                  <label htmlFor="message_type">
                    <input
                      type="radio"
                      id="message_type"
                      value="0"
                      {...register("message_type")}
                    />
                    <span>E-Mail</span>
                  </label>
                  <label htmlFor="message_type2">
                    <input
                      type="radio"
                      id="message_type2"
                      value="1"
                      {...register("message_type")}
                    />
                    <span>Text Message</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <Box display={"flex"} justifyContent={"end"}>
            <Button
              sx={{ mx: 1 }}
              onClick={props.handleClose}
              variant="outlined"
              color="secondary"
              type="button"
            >
              Cancel
            </Button>
            <Button
              startIcon={<EmailIcon />}
              sx={{ mx: 1 }}
              variant="contained"
              type="submit"
            >
              Send Message
            </Button>
          </Box>
        </form>
      </FormProvider>
      {/* <JSONTree data={preDefinedMessageList} /> */}
      {isLoading ? <LoadingSpinner /> : ""}
    </>
  );
};

export default function SendEmailToApplicant(props) {
  const notify = useNotify();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [update, { data, isLoading, error }] = useUpdate();

  return (
    <>
      {props?.buttonSendMail ? (
        <button
          type="button"
          className=""
          data-bs-toggle="modal"
          data-bs-target="#sendmodal"
          onClick={handleOpen}
        >
          <img src={mail} alt="" />
          <span>Send Mail</span>
        </button>
      ) : (
        <MuiButton
          sx={{
            ".MuiButton-startIcon": { paddingLeft: "10px" },
            minWidth: "80px",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            textTransform: "capitalize",
          }}
          onClick={handleOpen}
          startIcon={<EmailIcon />}
          variant="text"
        >
          Send Message
        </MuiButton>
      )}
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Send Message</DialogTitle>
        <DialogContent>
          <SendEmailForm
            handleClose={handleClose}
            email={props?.record?.length > 0 ? props?.record[0]?.Email : ""}
            record={props?.record}
            mobile_number={
              props?.record?.length > 0 ? props?.record[0]?.MobileNumber : ""
            }
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
