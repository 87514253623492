 
import commentSquare from "../../../assets/images/commentSquare.svg";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { JSONTree } from "react-json-tree";
 
const ShowComments = ({ section_comment, role,comment_heading='Docuverus Comment'}) => {
  const MAX_CHAR_PER_LINE = 190;
  

  // Explicitly defining the type of lines as a string array
  const breakLines = (text: string, maxLength: number): string[] => {
    const words = text.split(' ');
    let currentLine = '';
    const lines: string[] = []; // Define lines as an array of strings

    words.forEach((word) => {
      // Check if adding the word to the current line would exceed the limit
      if ((currentLine + word).length <= maxLength) {
        currentLine += word + ' ';
      } else {
        // If it exceeds the limit, push the current line to lines array
        lines.push(currentLine.trim());
        // Start a new line with the current word
        currentLine = word + ' ';
      }
    });

    // Push the last line if there's any remaining text
    if (currentLine.trim()) {
      lines.push(currentLine.trim());
    }

    return lines;
  };

  const renderComment = (text: string) => {
    const lines = breakLines(text, MAX_CHAR_PER_LINE);
    return lines?.map((line, index) => <div key={index}>{line}</div>);
  };
  return section_comment ? (
    <>
      {(role !== "applicant" &&
        section_comment["admin"] &&
        section_comment["admin"] !== "" && comment_heading !== "Applicant Comment") && (
          <div
  className={`notify-wrap ${
    !section_comment["applicant"] || section_comment["applicant"] === ""
      ? "mr-b20"
      : ""
  }`}
  style={{
    width: "auto",
    maxWidth: "100%",          
    whiteSpace: "normal",
    wordWrap: "break-word",    
    overflowWrap: "break-word",
    wordBreak: "break-all",    
  }}
>
  <div className="notify-head mt-2">
    <h4>{comment_heading}</h4>
  </div>
  <Alert severity="info" sx={{ fontSize: 13 }}>
    {renderComment(section_comment["admin"])}
  </Alert>
</div>
 )}
      
      {comment_heading == "Applicant Comment" && section_comment["applicant"] && section_comment["applicant"] !== "" && (
        <div className="notify-wrap mr-b20 mt-2"  style={{
          width: "auto",
          maxWidth: "100%",          
          whiteSpace: "normal",
          wordWrap: "break-word",    
          overflowWrap: "break-word",
          wordBreak: "break-all",    
        }}>
          <div className="notify-head mt-2">
            <h4 style={{ fontSize: 11 }}>{comment_heading}</h4>
          </div>
          <Alert severity="info" sx={{ fontSize: 13 }}>
            {renderComment(section_comment["applicant"])}
          </Alert>
        </div>
      )}
    </>
  ) : null;
};
 
export default ShowComments;
 
