import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/DocuVerusLogoorg.png";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { Outlet, Link, useNavigate, Navigate } from "react-router-dom";
import InputField2 from "../../componets/common/InputComponents/InputField2";
import { Button, useCreate, useNotify, useRefresh, useGetList } from "react-admin";
import { ATS_DOCS_URL, SOFTWARE_VERSION, typesofUsers } from "../../config";
import { useLogin, Notification } from "react-admin";
import "../../assets/styles/style.css";
import MfaLogin from "./MfaLogin";

const Login = (props) => {
  const login = useLogin();
  const notify = useNotify();
  const [loginResponse, setLoginResponse] = useState<any>(null);



  const schema = yup.object().shape({
    password: yup.string().required("required"),
    email: yup.string().typeError("required").required("required"),
  });

  const methods = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  useEffect(() => {
    document.title = "Docuverus";
  }, []);
  const [checkLoginState, setCheckLoginState] = useState(false);
  // add logic if alreadu login redirect to dashboard
  useEffect(() => {
    setCheckLoginState(true);
    const timeoutId = setTimeout(() => {
        const auth = localStorage.getItem('auth');
        setCheckLoginState(false);
        if (auth) {
            const parsedAuth = JSON.parse(auth);
            if (parsedAuth.data) {
                window.location.replace('/Ats'); // Replace '/dashboard' with the actual path to your dashboard
            }
        }
    }, 100); // Adjust the timeout duration as needed

    return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount
}, []);

  const onSubmit = (data) => {
    const sendData = {
      username: data?.email,
      password: data?.password,
    };
    login({ ...sendData }).then(data => {
      setLoginResponse({ ...data, sendData });
    }).catch((e) => {
      setLoginResponse(null)
      notify(e.message, {
        anchorOrigin: { vertical: "top", horizontal: "right" },
      })
    }
    );
  };
  if (loginResponse?.status == 301) {
    return <MfaLogin username={loginResponse?.sendData?.username} />
  }
  if(checkLoginState)
    return <div>Loading...</div>;
  return (
    <>
      <section className="login-wrap">
        <div className="login-cell">
          <div className="login-block">
            <div className="l-logo-block text-center">
              <img src={Logo} alt="Docuverus" title="Docuverus" />
            </div>

            <div className="auth-form mr-t30">
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <div className="form-group cus-input-group mr-b20">
                    <InputField2
                      label="Email or Username"
                      source={`email`}
                      placeholder="Enter Email or Username here"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                    />
                  </div>

                  <div className="form-group cus-input-group mr-b20">
                    <InputField2
                      label="Password"
                      source={`password`}
                      type="password"
                      placeholder="Enter Password here"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                    />
                  </div>
                  <div
                    className="ForgetPassword"
                    style={{
                      marginLeft: "220px",
                      color: "5555FF",
                      fontSize: "14px",
                    }}
                  >
                    <Link to="/atss/ForgotPassword">Forgot Password?</Link>

                  </div>
                  <div
                    className="ForgetPassword"
                    style={{
                      marginLeft: "220px",
                      color: "5555FF",
                      fontSize: "14px",
                    }}
                  >
                    <Link to="/atss/ForgotUsername">Forgot Username?</Link>

                  </div>
                  {(
                    <div className="d-flex justify-content-center align-items-center">
                      <button type="submit" className="l-btn">
                        Login
                      </button>
                    </div>
                  )}
                </form>
              </FormProvider>
            </div>

            <div className="l-foot-links mr-t20">
              <ul className="mx-auto">
                <li>
                  <a href="/atss/DataPolicy" target="_blank">
                    Data Policy
                  </a>
                </li>

                <li>
                  <a href="/atss/TermsOfUse" target="_blank">
                    Terms of Use
                  </a>
                </li>
                <li>
                  Version {SOFTWARE_VERSION}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default Login;
