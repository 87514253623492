import React, { Fragment, useState } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useGetList, Button, useRefresh, useNotify, useCreate, useStore } from 'react-admin';
import { JSONTree } from 'react-json-tree';
// import ToggleButtons from '../../componets/common/InputComponents/ToggleButtons';
import { FormProvider, useForm } from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
// import InputField2 from '../../componets/common/InputComponents/InputField2';
// import YesNoRadioButton from '../../componets/common/InputComponents/YesNoRadioButton';
import { Button as MUIButton } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import Yup from 'yup';
import { ArrowBack } from "@mui/icons-material";
// import InputField2 from '../../common/InputComponents/InputField2';
// import YesNoRadioButton from '../../common/InputComponents/YesNoRadioButton';
import save_icon from "../../../assets/images/save_icon.svg"
// import formValidation from '../../../yupFormValidationRules/formValidator';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import formValidation from '../../yupFormValidationRules/formValidator';
import InputField2 from '../common/InputComponents/InputField2';
import YesNoRadioButton from '../common/InputComponents/YesNoRadioButton';
import ToggleButtons from '../common/InputComponents/ToggleButtons';
// import ToggleButtons from '../../common/InputComponents/ToggleButtons';
const AdminAddRole = () => {
    // const formRules=Yup.object({

    // });
    // const methods = useForm();
    const validationSchema = yup.lazy((value) =>
        yup.object().shape({
            rolename: formValidation.name,
            // applicantrole: Yup.string()
            //     .required("required")
            //     .typeError("required"),
            // applicantrole: formValidation.StringRequiredValidation,    
            // active: formValidation.StringRequiredValidation,
        })
       
    );
    const toggleButton = [
        {
            value: 0,
            label: "Inactive",
            icon: <ClearIcon />,
            borderColor: "#909090",
            backgroundColor: "#EEE",
            boxShadowColor: "#90909033",
            color: "#606060",
        },
        {
            value: 1,
            label: "Active",
            icon: <CheckIcon />,
            borderColor: "#46A96F",
            backgroundColor: "#EBFFF3",
            boxShadowColor: "#46a96f1a",
            color: "#46A96F",
        },
    ];

    const defaultFormValues = {
        applicantrole: "",
        rolename: "",
        active: 1,
    };
    const methods = useForm({
        defaultValues: defaultFormValues,
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    });
    const [switchSubscriberObj] = useStore("switchSubscriberName");
    const navigate = useNavigate();
    const refresh = useRefresh();
    const notify = useNotify();
    const [create, { isLoading, error }] = useCreate();
    const onSubmit = (value) => {
        // alert("hi");
        const addData = {
            // subscriberid: switchSubscriberObj?.value,
            ...value,
            // applicantrole: value?.applicantrole === "Yes" ? 1: 0,
            // defaultrole:0,
        };

        create(
            `add-adminRole-permission`,
            { data: addData },
            {
                onSuccess: (data) => {
                    notify(

                        `New Role created successfully.`,

                        {
                            type: "success",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                        }
                    );
                    refresh();
                    methods?.reset();
                    navigate(-1);
                },
                onError: (e: any) =>
                    notify(`Operation fail:${e.message}`, {
                        type: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    }),
            }
        );
    };
    return (
     

        <Fragment>
            <div className="home_content">
                <div className="row">
                    <div className="col-12 d-flex">
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography sx={{ fontWeight: "bold" }}>
                                <button
                                    title="Go Back"
                                    style={{ color: "#2cc0e4" }}
                                    className="btn btn-link"
                                    onClick={() => navigate(-1)}
                                >
                                    <ArrowBack />
                                </button>

                                <span>Add New Role</span>

                            </Typography>
                        </Breadcrumbs>
                    </div>
                </div>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)} className="mr-t30">
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <InputField2 source={'rolename'} label={'Role Name'} placeholder={'Role Name'} />
                            </div>
                            {/* <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                                <YesNoRadioButton
                                    required={true}
                                    label="Applicants can apply with this role?"
                                    source={`applicantrole`}
                                />
                            </div> */}
                            {/* <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                            <ToggleButtons
                                    toggleButton={toggleButton}
                                    label="Status"
                                    labelClass={"lable-title"}
                                    source={`active`}
                                />
                            </div> */}

                        </div>

                        <Box
                          display={"flex"}
                          justifyContent={"end"}
                          alignItems={"center"}
                          className="mt-4"
                          minHeight="10vh"
                          position={"fixed"}
                          bottom={70}
                          right={30}
                        >
                            <Box>
                                <MUIButton
                                    sx={{ mx: 1 }}
                                    onClick={() => {
                                        methods?.reset();
                                        navigate(-1);
                                    }}
                                    variant="outlined"
                                    color="secondary"
                                    type="button"
                                >
                                    Cancel
                                </MUIButton>
                                <MUIButton
                                    startIcon={<CheckIcon />}
                                    sx={{ mx: 1 }}
                                    variant="contained"
                                    type="submit"
                                >
                                    Add Role
                                </MUIButton>
                            </Box>
                        </Box>
                    </form>
                </FormProvider>
            </div>
        </Fragment>
    )
};


export default AdminAddRole;