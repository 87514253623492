import React, { useState } from "react";

import SideBar from "../../pages/Sidebar/sidebar";
import { FormProvider, useForm } from "react-hook-form";
import InputField2 from "../../componets/common/InputComponents/InputField2";
import Grid from "@mui/material/Grid";
import "../../assets/styles/style.css";
import InputSelectField from "../../componets/common/InputComponents/InputSelectField";
import { Card, CardActions, CardContent, CardHeader } from "@mui/material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import crossIcon from "../../assets/images/crossIcon.svg";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  FormLabel,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import Header from "../../componets/common/Header/Header";
import YesNoRadioButton from "../../componets/common/InputComponents/YesNoRadioButton";
import InputField from "../../componets/common/InputComponents/InputField";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ClearIcon from "@mui/icons-material/Clear";
import QuillTextEditor from "../common/InputComponents/TextEditorWithDataField/QuillTextEditor";
import CheckIcon from "@mui/icons-material/Check";
import ToggleButtons from "../../componets/common/InputComponents/ToggleButtons";
import TextEditor from "../../componets/common/InputComponents/TextEditor";
import InputDateField from "../../componets/common/InputComponents/InputDateFieldOld";
import {
  Loading,
  useCreate,
  useGetList,
  useNotify,
  useRefresh,
  useStore,
  useUpdate,
  useListContext,
  useGetOne,
  useGetIdentity,
} from "react-admin";
import { useNavigate, useParams } from "react-router";
import { JSONTree } from "react-json-tree";
import moment from "moment";
const AddNotification = (props) => {
  // const login = useLogin();
  const notify = useNotify();
  const navigate = useNavigate();
  const refresh = useRefresh();
  const { states } = props;
  const {
    displayedFilters,
    filterValues,
    setFilters,
    hideFilter,
    sort,
    total,
  } = useListContext();
  const { data: dataField } = useGetList("GETDataFiled");
  // const methods = useForm();
  const schema = yup.object().shape({
    TemplateName: yup.string().required("required"),
    Active: yup.string().required("required"),

    StartDate: yup.date().required('Start date is required').typeError("Required"),
    EndDate: yup.date()
      .required('End date is required')
      .min(yup.ref('StartDate'), 'End date cannot be before start date').typeError("Required"),
    StartTime: yup.string().required('Start time is required'),
    EndTime: yup.string()
      .required('End time is required')
      .test('is-after-start-time', 'End time cannot be before start time on the same day', function (value) {
        const { StartDate, EndDate, StartTime } = this.parent; // Accessing other fields in the form

        if (moment(StartDate).isSame(EndDate, 'day')) {
          const startMoment = moment(StartTime, 'HH:mm');
          const endMoment = moment(value, 'HH:mm');
          return endMoment.isSameOrAfter(startMoment); // Check if end time is after start time
        }
        return true; // If the dates are different, validation passes
      }),
    Template: yup.string().required("required"),
    // SubscriberID: yup.object().required("required").typeError("required"),
    PropertyOrOffice: yup.array().when("SubscriberID", {
      is: (SubscriberID) => SubscriberID?.label != undefined,
      then: yup.array().min(1, "Required")
        .of(yup.object().shape({
          value: yup.string().required("Value is required!"),
          label: yup.string().required("Label is required!"),
        })
        ),
      otherwise: yup.array().nullable(),
    }),
  })

 
  const toggleButton = [
    {
      value: "Inactive",
      label: "Inactive",
      icon: <ClearIcon />,
      borderColor: "#909090",
      backgroundColor: "#EEE",
      boxShadowColor: "#90909033",
      color: "#606060",
    },
    {
      value: "Active",
      label: "Active",
      icon: <CheckIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];
  const { id } = useParams();
  
  const { data: getStates, isLoading: isLoadingStates } = useGetOne(
    "getStates",
    {
      id: 1,
    },
    { refetchOnWindowFocus: false }
  );

  const methods = useForm({
    defaultValues: {
      TemplateName: "",
      StartDate: "",
      StartTime: "",
      EndDate: "",
      EndTime: "",
      Active: "Active",
      SubscriberID: [],
      PropertyOrOffice: [],
      States: [],
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const [create, { isLoading, error }] = useCreate();
  const form = useForm({});
  const [officeData, setOfficeData] = useState([]);

  // form submit handler
  const onSubmit = (value,e) => {
    e.preventDefault();

    const addData = {

      // ...value,

      TemplateName: value?.TemplateName,
      Template: value?.Template,
      StartDate: value?.StartDate ? moment(value.EndDate).format("MM/DD/YYYY") : null,
      EndDate: value?.EndDate ? moment(value.EndDate).format("MM/DD/YYYY") : null,
      StartTime: value?.StartTime,
    
      EndTime: value?.EndTime,
      // Active:parseInt(value?.Active),
      SubscriberID: value?.SubscriberID?.map((item) => item.value),
      VisibleTo: value?.VisibleTo?.map((item) => item.value),
      PropertyOrOffice: value?.PropertyOrOffice?.map((item) => item.value),
      States: value?.States?.map((item) => item.label),
      Active: value?.Active == "Active" ? 1 : 0,
      isDeleted: 0
    };


    create(
      `addSystemNotification`,
      { data: addData },
      {
        onSuccess: (data) => {
          notify(
            `Notification created successfully.`,

            {
              type: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }
          );
          refresh();
          methods?.reset();
          navigate(-1);
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
 
  };

  const {
    data: subscriberlist,
    isLoading: subscriberlistIsLoading,
    error: subscriberlistError,
  } = useGetList("getsubscribers", {});
  const { data: identity } = useGetIdentity();
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const [propertyFilter, setPropertyFilter] = useState([]);
  const {
    data,
    isLoading: officelistIsLoading,
    error: officelistError,
  } = useGetList("getCollectiveListOfPropertyAndOffice", {
    filter: propertyFilter,
  }, { enabled: propertyFilter.length > 0 });
  const start_date = methods.watch("StartDate");
  const SubscriberIDValue = methods.watch<any>("SubscriberID");
  return (
    <>
      <div className="home_content">
        <div className="row">
          <div className="col-12 d-flex">
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Typography sx={{ fontWeight: "bold" }}>
                <Typography
                  color="textPrimary"
                  style={{ fontWeight: "bold", color: "rgba(0, 0, 0, 0.38)" }}
                  onClick={() => navigate(-1)}
                >
                  System Notifications
                </Typography>
              </Typography>
              <Typography color="textPrimary" style={{ fontWeight: "bold" }}>
                Create New System Notifications
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div className="form">
          <div className="auth-form mr-t30">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={4}>
                    <InputField2
                      label="Notification Name"
                      // register={methods.register("email")}
                      source={`TemplateName`}
                      placeholder="Enter template name here"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <ToggleButtons
                      toggleButton={toggleButton}
                      label="Status"
                      labelClass={"lable-title"}
                      source={`Active`}
                      onClickHandler={(event) => event.stopPropagation()}
                    />
                  </Grid>
                </Grid>{" "}
                <br />
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={4}>
                    <InputDateField
                      source={`StartDate`}
                      label="Start Date"
                      labelClass={"lable-title"}
                  
                      minDate={new Date()}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField2
                      label="Start Time"
                      type="time"
                      source={`StartTime`}
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                      placeholder={undefined}

                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={4}>
                   
                    <InputDateField
                      source={`EndDate`}
                      label="End Date"
                      labelClass={"lable-title"}
                   
                      minDate={start_date ? moment(start_date).toDate() : new Date()}
                
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField2
                      label="End Time"
                      type="time"
                      source={`EndTime`}
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                      placeholder={undefined}

                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={4}>
                  

                    {subscriberlist && (
                      <InputSelectField
                        onChangeCallback={(value) => {
                      
                          setPropertyFilter(value.map(item => item.value))
                          if (value.length === 0) {
                            methods.setValue("PropertyOrOffice", []);  // Clear "Properties/Offices" field
                          }
                        }}
                        label="Subscribers"
                        options={subscriberlist?.map((item) => ({
                          label: item.value,
                          value: item.id,
                        }))}
                        source={"SubscriberID"}
                        required={false}
                        stopAutoFocus={true}
                        multiple
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    {data && SubscriberIDValue && (
                      <InputSelectField
                        onChangeCallback={(value) => {
                          
                        }}
                        label="Properties/Offices"
                        options={data?.map((item) => {
                          return {
                            value: item?.ID,
                            label: item.SubscriberPropertyName || item.SubscriberOfficeName,
                          };
                        })}
                        source={"PropertyOrOffice"}
                        required={false}
                        stopAutoFocus={true}
                        multiple
                      />
                    )}
                   
                  </Grid>
                </Grid>
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={4}>
                    <InputSelectField
                      label="States"
                      source={`States`}
                      required={false}
                      options={getStates?.data?.map((item) => ({
                        value: item?.id,
                        label: item.StateShortCode + " - " + item.StateName,
                      }))}
                      multiple
                      stopAutoFocus={true}
                    />
                  </Grid>
                
                </Grid>
                <br />
                <div className="row d-flex">
                  <div className="col-sm-11 mr-b20">
                    <label htmlFor="quillTextEditor" className="form-label">
                      Text
                    </label>
                    <QuillTextEditor
                      // id="quillTextEditor"

                      dataFieldOptions={dataField}
                      source={"Template"}
                      control={methods.control}
                    />
                  </div>
                </div>
                <Box
                  display={"flex"}
                  justifyContent={"end"}
                  alignItems={"center"}
                  className="mt-4"
                  minHeight="10vh"

                >
                  <Box>
                   
                    <Button
                      sx={{ mx: 1 }}
                      onClick={() => {
                        refresh();
                        methods?.reset();
                        navigate(-1);
                      }}
                      variant="outlined"
                      color="secondary"
                      type="button"
                    >
                      Cancel
                    </Button>

                    <Button
                      startIcon={!isLoading && <CheckIcon />} // Conditionally render the startIcon
                      sx={{ mx: 1 }}
                      variant="contained"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <CircularProgress
                          size="20px"
                          sx={{ color: "white" }}
                        /> 
                      ) : (
                        <span>Create Notification</span> // Show text when isLoading is false
                      )}
                    </Button>
                  
                  </Box>
                </Box>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNotification;
