import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";

export default function FilePreview2({
  img,
  file_name = "",
  isPdf = true,
  isDeletabel = true,
  isfileName = true,
  source=""
}) {
  const isImage = img?.type?.startsWith("image/");
  const { resetField,setValue } = useFormContext();

const imgSrc = useMemo(() => {
  if (img instanceof File) {
    return URL.createObjectURL(img);
  }
  return img;
}, [img]);

  return (
    <>
      <div className="d-inline-flex flex-column flex-wrap">
        {!isPdf ? (
          <img
            src={imgSrc}
            alt=""
            style={{ maxWidth: "120px", overflow: "hidden" }}
            className={"img-thumbnail ratio ratio-1x1"}
            width="120"
            height="120"
          />
        ) : (
          <embed
            src={imgSrc}
            style={{ maxWidth: "120px", overflow: "hidden" }}
            className={"img-thumbnail ratio ratio-1x1"}
            type="application/pdf"
            width="120"
            height="120"
          ></embed>
        )}
        {isfileName && (
          <div className="" style={{ textAlign: "center" }}>
            <p
              className="small pt-1"
              title={file_name}
              style={{ textAlign: "left", fontSize: 10 }}
            >
              {img?.name ? img?.name?.substring(0, 15) + "..." : ""}
            </p>
          </div>
        )}
        {isDeletabel && (
          <Button
            startIcon={<DeleteIcon style={{ fontSize: 12 }} />}
            className="btn btn-link"
            style={{
              color: "red",
              textAlign: "center",
              fontSize: 10,
              textTransform: "capitalize",
            }}
            onClick={() =>source?setValue(source,null):resetField(`file`)}
          >
            Delete
          </Button>
        )}
      </div>
    </>
  );
}
