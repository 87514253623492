import React, { useEffect } from 'react'
import {
  useParams,
  useNavigate
} from "react-router-dom";
import { Resource, useGetIdentity, usePermissions, useStore } from 'react-admin';
// import { GetsubscriberinboxList } from '../../componets/Ats/GetsubscriberinboxList';
import ApplicantDetailEdit from '../../componets/Ats/ApplicantDetailEdit';
import { GetsubscriberinboxList } from '../../componets/Ats/SubscriberInbox/GetsubscriberinboxList';
import { isSuperAdmin,checkModuleAccess } from '../../Functions/permissions';
import ErrorPage from '../../componets/Settings/userPermission/ErrorPage';

export default function Inbox() {
  const { data: identity } = useGetIdentity();
  const {permissions} =usePermissions();
  return (
    <>
   { isSuperAdmin(checkModuleAccess(permissions, "Inbox", ""), identity?.userrole) || isSuperAdmin(checkModuleAccess(permissions, "Doc Upload App", ""), identity?.userrole)|| isSuperAdmin(checkModuleAccess(permissions, "Guest Card App", ""), identity?.userrole) ? <Resource name='getsubscriberinbox' list={<GetsubscriberinboxList />} /> : <ErrorPage/>}
   </>
  )
}
