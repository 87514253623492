import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { CircularProgress } from "@mui/material";
import {
    useGetList, Button, useStore, useUpdate, useNotify,
    useRefresh, useGetIdentity,
    usePermissions
} from 'react-admin';
import { JSONTree } from 'react-json-tree';
import { FormProvider, useForm } from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Button as MUIButton } from "@mui/material";
import { method } from 'lodash';
import { ATS_API_URL } from '../../config';
import YesNoRadioButton from '../common/InputComponents/YesNoRadioButton';
import ToggleButtons from '../common/InputComponents/ToggleButtons';


const SubscriberPermissionOfAdmin = () => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

 
    const [switchSubscriberObj] = useStore("switchSubscriberName");
    const navigate = useNavigate();
    const {
        data: getrolePermission,
        isLoading: loadingrole,
    } = useGetList(
        "get-admin-role-permission"
    );


    useEffect(() => {
        if (getrolePermission?.[0]?.Name) {
            setSelectedTab(getrolePermission[0].Name);
        }
    }, [getrolePermission]);
    const [selectedTab, setSelectedTab] = useState();
    console.log(getrolePermission?.[0]?.RoleName, "selectedTab");
    const [isLoadingExport, setIsLoading] = useState(false);
    const handleExportData = () => {

        setIsLoading(true); // Start loading
        const tokenString = localStorage.getItem('auth');
        let tokenSecret = '';
        if (tokenString) {
            try {
                const token = JSON.parse(tokenString);
                tokenSecret = token?.data?.tokendata || '';
            } catch (error) {
                console.error('Error parsing token:', error);
            }
        }

        const url = `${ATS_API_URL}/admin/exportpermissions?rolename=${selectedTab}`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `${tokenSecret}`
            }
        }).then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `Admin User-Permissions.xlsx`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(error => console.error('Error:', error))
            .finally(() => {
                setIsLoading(false); // Stop loading
            });




    }
    return (

        <section className="main-sec">

            <div className="home_content">
                <div className='d-flex justify-content-between'
                    style={{ fontWeight: "600", fontSize: "18px" }}
                >
                    <p>
                        Admin User Permissions

                    </p>
                    <div className='d-flex justify-content-between'>
                        <button
                            onClick={handleExportData}
                            type="button"
                            className="g-line-btn m-1"
                            title="Export all data"
                        >
                            <svg
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clip-path="url(#clip0_14375_6193)">
                                    <path
                                        d="M8.66667 8.4987H10.6667L8 11.1654L5.33333 8.4987H7.33333V5.83203H8.66667V8.4987ZM10 3.16536H3.33333V13.832H12.6667V5.83203H10V3.16536ZM2 2.49336C2 2.12803 2.298 1.83203 2.666 1.83203H10.6667L14 5.16536V14.494C14.0006 14.5816 13.984 14.6684 13.951 14.7495C13.9181 14.8306 13.8695 14.9045 13.808 14.9668C13.7466 15.0291 13.6734 15.0788 13.5928 15.1128C13.5121 15.1469 13.4255 15.1648 13.338 15.1654H2.662C2.48692 15.1641 2.31934 15.0941 2.19548 14.9704C2.07161 14.8466 2.0014 14.6791 2 14.504V2.49336Z"
                                        fill="#2DC3E8"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_14375_6193">
                                        <rect
                                            width="16"
                                            height="16"
                                            fill="white"
                                            transform="translate(0 0.5)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                        <Button label='Add Role' onClick={() =>
                            navigate(`/ats/AdminPermissions/adminAddRole`, {
                                state: { isAdding: true },
                            })
                        } className='m-1' variant='contained' startIcon={<AddIcon />} size='large' />
                    </div>

                </div>
                <div className="application-tabs">
                    <div className="nav-flexes" style={{ marginTop: "40px", fontSize: "12px", fontWeight: "700", fontFamily: "Open Sans", lineHeight: "18.5px" }}>
                        <nav>
                            <div
                                className="nav nav-tabs app-nav-tabs"
                                id="nav-tab"
                                role="tablist"
                            >
                              
                             
                                {getrolePermission && getrolePermission?.length > 0 && getrolePermission?.map((item, index) => {

                                    return (
                                        <>
                                       
                                         
                                        <button
                                            type="button"
                                            className={`nav-link ${index === 0 ? "active" : ""}`}
                                            data-bs-toggle="tab"
                                            data-bs-target={`#tab-${item.Id	}`}
                                            onClick={() => setSelectedTab(item.Name)} // Set role name when clicked
                                        >
                                            {item.Name}
                                        </button>
                                        </>
                                        )
                                })}
                            </div>
                        </nav>
                    </div>
                    <div className="tab-content app-nav-tabcontent " id="nav-tabContent" style={{ marginTop: "10px", backgroundColor: "#f8f8f8" }} >
                        {getrolePermission && getrolePermission.length > 0 && getrolePermission.map((item, index) => {

                            return (
                                <>

                                    <div className={`tab-pane fade show ${index === 0 ? "active" : ""}`} id={`tab-${item.Id	}`}>


                                        <TabsBody item={item} index={index} />

                                    </div>
                                </>


                            )
                        })}

                    </div>

                </div>
            </div>
        </section>

    );
};

const toggleButton = [
    {
        value: false,
        label: "Denied",
        icon: <ClearIcon />,
        borderColor: "#FF4848",
        backgroundColor: "#FFF1F1",
        boxShadowColor: "#FFF1F1",
        color: "#FF4848",
    },
    {
        value: true,
        label: "Access",
        icon: <CheckIcon />,
        borderColor: "#46A96F",
        backgroundColor: "#EBFFF3",
        boxShadowColor: "#46a96f1a",
        color: "#46A96F",
    },
];
const TabsBody = ({ item, index }) => {
    const { rolename } = useParams();
    const methods = useForm();
    const navigate = useNavigate();
    const [expandIdSection, setExpandIdSection] = React.useState(true);
    const [switchSubscriberObj] = useStore("switchSubscriberName");
    const { data: getpermissions } = useGetList('getAdminPermissions', { filter: {'role': item.Name } });
    const collasableData = getpermissions?.filter(item => item.ParentMenuName == null);
    const childCollasableData = getpermissions?.filter(item => item.ParentMenuName != null);
    // const applicantRole = getpermissions?.filter(item => item.applicantrole);

    const notify = useNotify();
    const refresh = useRefresh();
    const [update, { data, isLoading, error }] = useUpdate();
    const {
        data: getrolePermission,
        isLoading: loadingrole,
    } = useGetList(
        "get-admin-role-permission"
     );




    const onSubmit = (value) => {
        console.log(value, "beforefunction")

        const addData = {
            subscriberid: switchSubscriberObj?.value,
            rolename: item?.Name,
            // applicantrole: value?.applicantrole === "Yes" ? 1 : 0,
            active: item?.Active === true ? 1 : 0,
            menulist:
                // Object.keys(value).map(key => ({
                //     if (key === "applicantrole") {
                //         return null; // Skip "applicantrole"
                //       }
                //     [key]: value[key] === true // Or keep it as value[key] if you want the exact boolean
                // }))
                Object.keys(value).map(key => {
                    if (key === "applicantrole") {
                        return null; // Skip "applicantrole"
                    }
                    return { [key]: value[key] === true }; // Keep true/false mapping
                }).filter(item => item !== null) 

        };
        console.log(addData, "afterfunction")
        update(
            `update-admin-permission`,
            { data: addData },
            {
                onSuccess: (data) => {
                    notify(

                        `Permission Updated Successfully.`,

                        {
                            type: "success",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                        }
                    );
                    refresh();
                    // methods?.reset();
                    // navigate(-1);
                },
                onError: (e: any) =>
                    notify(`Operation fail:${e.message}`, {
                        type: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    }),
            }
        );
    };
    useEffect(() => {
        if (getpermissions) {
            const permissionData = getpermissions?.map(item => {
                return { [item.MenuID]: item.Access }; // Dynamic key
            });

            console.log(permissionData, "permissionData");

            // Assuming methods.reset expects an object, not an array
            methods.reset(Object.assign({}, ...permissionData));
        }
    }, [getpermissions]);


    const { permissions } = usePermissions();

    return (<>

        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} className="mr-t30">
                {/* <JSONTree data={methods.watch()} /> */}
                {/* <JSONTree data={item} /> */}
                {/* <div className="col-sm-12 col-md-4 col-lg-4 mr-b20" >

                    <YesNoRadioButton
                        required={true}
                        label="Applicants can apply with this role?"
                        source={`applicantrole`}
                    />
                </div> */}
                {collasableData && collasableData.length > 0 && collasableData.filter(item => item.MenuID != null).map((item, index) => {
                    return <>

                        {/* <JSONTree data={methods.watch()} />  */}
                        <div className="info-toggle">
                            <div className="toggle-heads ">
                                <div className="row align-items-center justify-content-between  ">
                                    <div className="col">
                                        <div className="d-flex align-items-center">
                                            <b
                                                className="ms-3 me-2"
                                                style={{ fontSize: "18px", fontWeight: "700", fontFamily: "Open Sans" }}
                                            >
                                                {item.MenuName}
                                            </b>
                                        </div>
                                    </div>

                                    <div className="col text-end m-1">
                                        <ToggleButtons
                                            toggleButton={toggleButton}
                                            label={""}
                                            labelClass={"lable-title"}
                                            notRequired={true}
                                            source={String(item.MenuID)}
                                        />
                                    </div>
                                </div>

                                {methods.watch(String(item.MenuID))==true &&<div
                                    className={` ${!expandIdSection ? "d-none" : "d-display"
                                        } card-body cust-card-appl`}
                                >

                                    <CollasableFormData formInputArr={childCollasableData?.filter(i => i.ParentMenuName == item.MenuName)} item={item} index={index} />

                                </div>}
                            </div>
                        </div>
                    </>
                })}
                <Box
                   display={"flex"}
                   justifyContent={"end"}
                   alignItems={"center"}
                   className="mt-4"
                   minHeight="10vh"
                >
                    <Box>
                        {/* <MUIButton
                            sx={{ mx: 1 }}
                            onClick={() => {
                                methods?.reset();
                                // navigate(-1);
                            }}
                            variant="outlined"
                            color="secondary"
                            type="button"
                        >
                            Cancel
                        </MUIButton> */}
                        <MUIButton
                            startIcon={!isLoading && <CheckIcon />} // Conditionally render the startIcon
                            sx={{ mx: 1 }}
                            variant="contained"
                            type="submit"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <CircularProgress size="20px" sx={{ color: "white" }} /> // Show loader when isLoading is true
                            ) : (
                                <span>Update</span> // Show text when isLoading is false
                            )}
                        </MUIButton>
                    </Box>
                </Box>
            </form></FormProvider>
    </>)
}


const CollasableFormData = ({ item, index, formInputArr }) => {
    return <>
        <div className='row'>
            {/* <JSONTree data={formInputArr} /> */}
            {formInputArr && formInputArr.length > 0 && formInputArr.map((item, index) => {
                return <>
                    <div className='col-3'>
                        <div
                            className="card card-body w-100"
                            style={{ paddingTop: 0 }}
                        >
                            {/* <JSONTree data={item} /> */}
                            <>
                                <ToggleButtons
                                    toggleButton={toggleButton}
                                    label={item?.MenuName}
                                    labelClass={"lable-title"}
                                    source={String(item?.MenuID)}
                                    notRequired={true}
                                />
                            </>
                        </div>
                    </div>
                </>
            })
            }
        </div>
    </>
}



export default SubscriberPermissionOfAdmin;