import React from "react";
import { Button as MuiButton, CircularProgress, Box } from "@mui/material";
// import EmailIcon from "@mui/icons-material/Email";
import { useCreate, useNotify, useRefresh } from "react-admin";
import { useParams } from "react-router-dom";
import ResendIcon from '../../assets/images/resend.svg';

const ResendShareAppReport = ({ record }) => {
  console.log("🚀 ~ file: ResendShareAppReport.tsx:9 ~ ResendShareAppReport ~ record:", record)
  const refresh = useRefresh();
  const notify = useNotify();
  const { id } = useParams();
  // call usecreate
  const [create, { isLoading, error }] = useCreate();
  // form on submit handler
  const ResendInviteHandler = (value) => {
    const formData = {
      landloardfirstname: record?.LandloardFirstName,
      landloardlastname: record?.LandloardLastName,
      email: record?.Email,
      username: record?.UserName,
      id: record?.ID,
      subscriberid: id || record.SubscriberId,
    };
    create(
      "resendshareappreport",
      { data: formData },
      {
        onSuccess: (data) => {
          notify(`Invitation has been resent`, { type: "success", anchorOrigin: { vertical: 'top', horizontal: 'right' }});
          refresh();
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, { type: "error", anchorOrigin: { vertical: 'top', horizontal: 'right' }}),
      }
    );
  };

  if (isLoading)
    return (
      <>
        <div className="d-flex justify-content-center align-items-center">
          <CircularProgress size="30px" />
        </div>
      </>
    );
  return (
    <Box display={"flex"} justifyContent={"start"}>
      <MuiButton
        sx={{
          ".MuiButton-startIcon": { paddingLeft: "10px" },
          minWidth: "80px",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          textTransform: "capitalize",
        }}
        onClick={ResendInviteHandler}
        startIcon={<img src={ResendIcon} alt="" />}
        variant="text"
      >
        Resend
      </MuiButton>
    </Box>
  );
};

export default ResendShareAppReport;
