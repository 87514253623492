import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Card } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Datagrid,
  FunctionField,
  NumberField,
  useCreate,
  useGetList,
  useNotify,
  useRefresh,
  useStore,
  useUpdate,
} from "react-admin";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, FormProvider } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import DocumentAnalysisShow from "../../componets/DocumentAnalysis";
import AlertDialogTable from "../../componets/DocuploadTableComponent/AlertDialogTable";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import "../../App.css";
import ToggleButtons from "../../componets/common/InputComponents/ToggleButtons";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  FormLabel,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Backdrop,
  Typography,
  Button as MuiButton,
} from "@mui/material";
import { RaDatagrid_headerCell } from "../../componets/common/inlineStyle";
import InputField2 from "../../componets/common/InputComponents/InputField2";
import InputSelectField from "../../componets/common/InputComponents/InputSelectField";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { JSONTree } from "react-json-tree";
import moment from "moment";
// import formValidation from "../../../yupFormValidationRules/formValidator";
import formValidation from "../../yupFormValidationRules/formValidator";
import ApplicationSettingsDocument from "../../componets/Settings/ApplicationProfileSettings/ApplicationSettingsDocument";
import ApplicationSettingsCosigner from "../../componets/Settings/ApplicationProfileSettings/ApplicationSettingsCosigner";
import ApplicationSettingsCoapplicant from "../../componets/Settings/ApplicationProfileSettings/ApplicationSettingsCoapplicant";
import ApplicationSettingsApplicant from "../../componets/Settings/ApplicationProfileSettings/ApplicationSettingsApplicant";
import { useFieldArray } from 'react-hook-form';
import { Delete, Save } from "@mui/icons-material";
import ApplicationSettingsPreAuth from "../../componets/Settings/ApplicationProfileSettings/ApplicationSettingsPreAuth";
import ApplicationSettingsChild from "./ApplicationSettingsChildTabs";

const ApplicationSettings = () => {
  const [value, setValue] = React.useState("1");
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();
 

  const [activeTab, setActiveTab] = useState(0);
  const [switchSubscriberObj] = useStore("switchSubscriberName");

  // const { data: getapplicationprofilelist, isLoading } = useGetList(
  //   "getapplicationprofilelist"
  // );

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const require = [
    {
      value: "require",
      label: "require",
      icon: <DoneAllIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];

  const threetoggleButton = [
    {
      value: "Hide",
      label: "hide",
      icon: <VisibilityOffOutlinedIcon />,
      borderColor: "#FF0000",
      backgroundColor: "#FFF5EE",
      boxShadowColor: "#90909033",
      color: "#FF0000",
    },
    {
      value: "Optional",
      label: "Optional",
      icon: <RemoveRedEyeOutlinedIcon />,
      borderColor: "#8A3324",
      backgroundColor: "#FFF5EE",
      boxShadowColor: "#46a96f1a",
      color: "#8A3324",
    },
    {
      value: "require",
      label: "require",
      icon: <DoneAllIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];

  const documenttoggleButton = [
    {
      value: "Hide",
      label: "Hide",
      icon: <VisibilityOffOutlinedIcon />,
      borderColor: "#FF0000",
      backgroundColor: "#FFF5EE",
      boxShadowColor: "#46a96f1a",
      color: "#FF0000",
    },
    {
      value: "Optional",
      label: "optional",
      icon: <RemoveRedEyeOutlinedIcon />,
      borderColor: "#8A3324",
      backgroundColor: "#FFF5EE",
      boxShadowColor: "#90909033",
      color: "#8A3324",
    },

    {
      value: "require",
      label: "require",
      icon: <DoneAllIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const [tabClick, setTabClick] = useState("Applicant");

  // Function to handle tab click and set the active tab name
  const handleTab = (tabName) => {
    setTabClick(tabName);
  };

  const [selectedTab, setSelectedTab] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue); // Update selected tab on change
  };


  return (
    <>
      <div className="home_content">
        {/* <div style={{ fontWeight: "bold" }}>
          <p>
            Application Profile &gt; Profile Details &gt;Application Settings
          </p>
        </div> */}
        <Grid item xs={12} md={6}>
          <div className="row">
            <div className="col-12 d-flex">
              <Breadcrumbs aria-label="breadcrumb" separator=">">
                <Typography sx={{ fontWeight: "bold" }}>
                  <Typography
                    color="textPrimary"
                    style={{
                      fontWeight: "bold",
                      color: "rgba(0, 0, 0, 0.38)",
                    }}
                    onClick={() => navigate(-1)}
                  >
                    Application Profile
                  </Typography>
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  <Typography
                    color="textPrimary"
                    style={{
                      fontWeight: "bold",
                      color: "rgba(0, 0, 0, 0.38)",
                    }}
                    onClick={() => navigate(-1)}
                  >
                    Profile Details
                  </Typography>
                </Typography>
                <Typography color="textPrimary" style={{ fontWeight: "bold" }}>
                  Application Settings
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
        </Grid>

        <div className="nav-flexes" style={{ marginTop: "20px" }}>
          <nav>
            <div
              className="nav nav-tabs app-nav-tabs"
              id="nav-tab"
              role="tablist"
            >
              <button
                type="button"
                className="nav-link active"
                data-bs-toggle="tab"
                data-bs-target="#tab-applicant"
                onClick={() => handleTab("applicant")}
              >
                Applicant

              </button>
              <button
                type="button"
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab-Co-applicant"
                onClick={() => handleTab("co-applicant")}
              >
                Co-applicant
              </button>
              <button
                type="button"
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab-Co-signer"
                onClick={() => handleTab("co-signer")}
              >
                Co-Signer
              </button>
              <button
                type="button"
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab-documents"
                onClick={() => handleTab("document")}
              >
                Documents
              </button>
            </div>
          </nav>
        </div>

        <div className="tab-content app-nav-tabcontent" id="nav-tabContent">
          <div className="tab-pane fade show active" id="tab-applicant">
            {/* <Card> */}
            <ApplicationSettingsChild tabClick={tabClick}  />
            {/* </Card> */}
          </div>

          <div className="tab-pane fade show" id="tab-documents">
            <ApplicationSettingsDocument />
          </div>
          {/* Co-signer */}
          <div className="tab-pane fade show" id="tab-Co-signer">
          <ApplicationSettingsChild tabClick={tabClick}  />
          </div>
          <div className="tab-pane fade show" id="tab-Co-applicant">
           <ApplicationSettingsChild tabClick={tabClick} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationSettings;
