import React, { Fragment, useState, useRef, useEffect } from "react";
// import MarkInactiveModal from "./MarkInactiveModal";
// import MarkActiveModal from "./MarkActiveModal";
// import FilterModal from "./FilterModal";
import Button from "@mui/material";
import { CircularProgress } from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { MdOutlineUploadFile } from "react-icons/md";
import {
    Datagrid,
    FunctionField,
    ListBase,
    Pagination,
    TextField,
    useListContext,
    useStore,
    useGetList,
    Resource,
    useNotify, useRefresh
    , useCreate,
    usePermissions
} from "react-admin";
import {
    Grid,
    Typography,
    TextField as MuiTextField,
    Box,
    Link,
    ToggleButton,
    ToggleButtonGroup,
    Checkbox,
    Stack
} from "@mui/material";
import { useParams } from "react-router-dom";
import { debounce } from "lodash";
import { ATS_API_URL } from "../../../config";
import InputFileImport from "../../common/InputFileImport";
import DownloadSampleButton from "../../common/DownloadSampleButton";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { format } from 'date-fns';
//  import FilterModal from "../EmailSettings/FilterModal";
// import FilterModal from "../../manageSubscribers/FilterModal";

import { JSONTree } from "react-json-tree";
import CopyLinkHoverDropdownUserSetup from "./CopyLinkHoverDropdownUserSetup";

import { styled } from "@mui/material/styles";
import FilterModal from "../PropertySetup/FilterModal";
import MarkAsActiveSubscriber from "./MarkAsActiveSubscriber";
import { checkModuleAccess } from "../../../Functions/permissions";
import FilterChips from "../../Ats/SuperAdminInbox/FilterChips";
const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});
const MyListToolBar = ({ title }) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, sort } =
        useListContext();

    const [applicantType, setApplicantType] = useState("All")
    const navigate = useNavigate();
    const [switchSubscriberObj] = useStore("switchSubscriberName");
    console.log(filterValues, "filterValues")
    // Debounce the search function
    // const debouncedSearch = debounce((value) => {

    //     // Perform your search logic here
    //     setFilters({ ...filterValues, SearchCode: value }, []);
    // }, 500); // 500ms debounce delay

    useEffect(() => {
        // Set default filter value if not already set
        if (!filterValues.UserType) {
            setFilters({ ...filterValues, UserType: "All" }, []);
        }
    }, [filterValues, setFilters]);
    const notify = useNotify();
    const refresh = useRefresh();
    const [create, { isLoading, error }] = useCreate();
    const [searchValue, setSearchValue] = useState('');
    const handleSearchChange = (event) => {

        const value = event.target.value;
        setSearchValue(value)
        setFilters({ ...filterValues, q_email: value }, []);
        // debouncedSearch(value);
    };
    useEffect(() => {
        console.log("filterValues.q_email1", filterValues.q_email)
        if (filterValues.q_email == undefined) {
            console.log("filterValues.q_email2", filterValues.q_email)
            setSearchValue('');
        } else {
            console.log("filterValues.q_email3", filterValues.q_email)
            setSearchValue(filterValues.q_email);
        }
    }, [filterValues.q_email]);
    const file_type = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "text/csv",
    ];
    const errorMessage = (error: any) => {
        notify(error, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
        });
    };
    const urlForSample = `${ATS_API_URL}/subscriber/download?sampleType=UserSetup`;
    const [isLoadingExport, setIsLoading] = useState(false);
    const [isLoadingImport, setIsLoadingImport] = useState(false);
    const handleExportData = () => {

        setIsLoading(true); // Start loading
        const tokenString = localStorage.getItem('auth');
        let tokenSecret = '';
        if (tokenString) {
            try {
                const token = JSON.parse(tokenString);
                tokenSecret = token?.data?.tokendata || '';
            } catch (error) {
                console.error('Error parsing token:', error);
            }
        }

        const url = `${ATS_API_URL}/subscriber/exportusers?SubscriberId=${switchSubscriberObj?.value
            }&UserType=${filterValues.UserType}`;

        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `${tokenSecret}`
            }
        }).then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `Users Setup.xlsx`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(error => console.error('Error:', error))
            .finally(() => {
                setIsLoading(false); // Stop loading
            });


        // const tokenString = localStorage.getItem('auth')
        // let tokenSecret = ''
        // if (tokenString) {
        //     try {
        //         const token = JSON.parse(tokenString);
        //         tokenSecret = token?.data?.tokendata || '';
        //     } catch (error) {
        //         console.error('Error parsing token:', error);
        //     }
        // }


        // const currentTimestamp = Date.now();

        // const url = `${ATS_API_URL}/subscriber/exportusers?SubscriberId=${switchSubscriberObj?.value
        //     }&UserType=${filterValues.UserType}`;

        // fetch(url, {
        //     method: 'GET',
        //     headers: {
        //         'Authorization': `${tokenSecret}`
        //     }
        // }).then(response => response.blob())
        //     .then(blob => {
        //         const url = window.URL.createObjectURL(blob);
        //         const a = document.createElement('a');
        //         a.style.display = 'none';
        //         a.href = url;
        //         a.download = `Users Setup.xlsx`;
        //         document.body.appendChild(a);
        //         a.click();
        //         window.URL.revokeObjectURL(url);
        //     })
        //     .catch(error => console.error('Error:', error));
    }
    const validateFile = (newFile) => {
        if (!newFile) return;
        // if (newFile.size > file_size) {
        //   errorMessage("File size exceeds 4Mb");
        //   return false;
        // }
        if (!file_type.includes(newFile.type)) {
            errorMessage("Supported file format is Excel and CSV files only.");
            return false;
        }
        return true;
    };
    const submitBtnRef = useRef<HTMLButtonElement>(null);
    const handleChange = (event: any) => {
        const newFile = event.target.files[0];
        submitBtnRef?.current?.click();
        if (validateFile(newFile)) {
            handleImportData(newFile);
        }
    };

    //import-xls-susbriber-userSetup
    const handleImportData = (value) => {
        const formData: any = new FormData();
        setIsLoadingImport(true);
        console.log(formData, "formData");
        formData.append("importType", "Register");
        formData.append("subscriberid", switchSubscriberObj?.value);
        formData.append("file", value);
        console.log(formData, "formData1");

        create(
            `import-xls-susbriber-userSetup`,
            { data: formData },
            {
                onSuccess: (data) => {
                    notify(`File import successfully.`, {
                        type: "success",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    });
                    refresh();
                    setIsLoadingImport(false);

                },
                onError: (e: any) => {
                    notify(`Operation fail:${e.message}`, {
                        type: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    });
                    setIsLoadingImport(false);
                },

            }
        );
    }
    //for doenload
    const handleDownloadData = () => {
        const tokenString = localStorage.getItem('auth')
        let tokenSecret = ''
        if (tokenString) {
            try {
                const token = JSON.parse(tokenString);
                tokenSecret = token?.data?.tokendata || '';
            } catch (error) {
                console.error('Error parsing token:', error);
            }
        }


        const currentTimestamp = Date.now();

        const url = `${ATS_API_URL}/subscriber/download?sampleType=UserSetup`;


        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `${tokenSecret}`
            }
        }).then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `RegisterTemplate.csv`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(error => console.error('Error:', error));
    }
    const buttonStyle: any = {
        color: "#2DC3E8",
        border: "1px solid #2DC3E8",
        borderRadius: "4px",
        height: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "14px",
        fontFamily: "OpenSans-Bold",
        textTransform: "capitalize",
        margin: "0px 0px 0px 10px",
        textAlign: "center",
        minWidth: "7rem",
    };

    const { permissions } = usePermissions();
    return (
        <>
            <Grid style={{ marginTop: 10, marginBottom: 8 }} container spacing={3}>
                {/* Left side with title */}

                {/* Right side with search input, filter button, and add button */}
                <Grid
                    item
                    xs={12}
                    md={12}
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <div className="group-btn">
                        {/* Search Input */}
                         <MuiTextField
                            placeholder="search"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}

                            value={searchValue}
                            onChange={handleSearchChange}
                            variant="outlined"
                            size="small"
                        />
                        <FilterModal />
                        {/* import button+ */}
                        {/* <button
                        type="button"
                        className="blue-btn px-3 bg-transparent shadow-none"
                        style={{
                            color: "#2DC3E8", minWidth: "10rem"
                        }}

                        onClick={() => {
                            const fileInput = document.getElementById('fileInput') as HTMLInputElement | null;
                            if (fileInput) {
                                fileInput.click();
                            }
                        }}
                    >
                        Import CSV
                        <input
                            type="file"
                            id="fileInput"
                            accept={file_type.join(", ")}
                            hidden
                            // onChange={handleChange}
                            onChange={(event) => {
                                handleChange(event);
                                const fileInput = event.target as HTMLInputElement;
                                // Reset the input value to allow the same file to be uploaded again
                                fileInput.value = '';
                            }}

                        />
                    </button> */}

                        {checkModuleAccess(permissions, "Other Permissions", "Edit Users") && <button
                            type="button"
                            className="g-line-btn"
                            title="Import data"
                            style={{ minWidth: "2rem" }}
                            disabled={isLoadingImport}

                            onClick={() => {
                                const fileInput = document.getElementById('fileInput') as HTMLInputElement | null;
                                if (fileInput) {
                                    fileInput.click();
                                }
                            }}
                        >
                            {isLoadingImport ? (

                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 50 50"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="spinner"
                                >
                                    <circle
                                        cx="25"
                                        cy="25"
                                        r="20"
                                        stroke="#2DC3E8"
                                        strokeWidth="4"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeDasharray="31.4 31.4"
                                        strokeDashoffset="0"
                                        transform="rotate(-90 25 25)"
                                        style={{ animation: "spin 1s linear infinite" }}
                                    />
                                </svg>

                            ) : (
                                <UploadFileOutlinedIcon fontSize="small" color="primary" />
                            )}
                            <input
                                type="file"
                                id="fileInput"
                                accept={file_type.join(", ")}
                                hidden
                                // onChange={handleChange}
                                onChange={(event) => {
                                    handleChange(event);
                                    const fileInput = event.target as HTMLInputElement;
                                    // Reset the input value to allow the same file to be uploaded again
                                    fileInput.value = '';
                                }}

                            />
                        </button>}

                        <button
                            type="button"
                            className="blue-btn px-3 bg-transparent shadow-none"
                            style={{ color: "#2DC3E8", minWidth: "10rem" }}
                            onClick={handleDownloadData

                            }
                        >
                            Download Sample
                        </button>


                        <button
                            onClick={handleExportData}
                            type="button"
                            className="g-line-btn"
                            title="Export all data"
                            style={{ minWidth: "2rem" }}
                            disabled={isLoadingExport} // Disable button while loading
                        >
                            {isLoadingExport ? (

                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 50 50"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="spinner"
                                >
                                    <circle
                                        cx="25"
                                        cy="25"
                                        r="20"
                                        stroke="#2DC3E8"
                                        strokeWidth="4"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeDasharray="31.4 31.4"
                                        strokeDashoffset="0"
                                        transform="rotate(-90 25 25)"
                                        style={{ animation: "spin 1s linear infinite" }}
                                    />
                                </svg>

                            ) : (
                                <svg
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clipPath="url(#clip0_14375_6193)">
                                        <path
                                            d="M8.66667 8.4987H10.6667L8 11.1654L5.33333 8.4987H7.33333V5.83203H8.66667V8.4987ZM10 3.16536H3.33333V13.832H12.6667V5.83203H10V3.16536ZM2 2.49336C2 2.12803 2.298 1.83203 2.666 1.83203H10.6667L14 5.16536V14.494C14.0006 14.5816 13.984 14.6684 13.951 14.7495C13.9181 14.8306 13.8695 14.9045 13.808 14.9668C13.7466 15.0291 13.6734 15.0788 13.5928 15.1128C13.5121 15.1469 13.4255 15.1648 13.338 15.1654H2.662C2.48692 15.1641 2.31934 15.0941 2.19548 14.9704C2.07161 14.8466 2.0014 14.6791 2 14.504V2.49336Z"
                                            fill="#2DC3E8"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_14375_6193">
                                            <rect
                                                width="16"
                                                height="16"
                                                fill="white"
                                                transform="translate(0 0.5)"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            )}
                        </button>






                        {checkModuleAccess(permissions, "Other Permissions", "Edit Users") && <button
                            type="button"
                            onClick={() =>
                                navigate(`/ats/settings/usersetup/adduser`, {
                                    state: { isAdding: true },
                                })
                            }
                            className="blue-btn px-4"
                        >
                            <svg
                                className="mr-r10"
                                width="10"
                                height="11"
                                viewBox="0 0 10 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M4.33337 4.83333V0.833334H5.66671V4.83333H9.66671V6.16667H5.66671V10.1667H4.33337V6.16667H0.333374V4.83333H4.33337Z"
                                    fill="white"
                                />
                            </svg>
                            User
                        </button>}

                    </div>
                </Grid>
            </Grid></>

    );
};


const MyList = ({ children, toolBar, ...props }) => {
    return (
        <ListBase
            {...props}
            perPage={100} // Number of items per page
        >
            {toolBar}
            <FilterChips excludeKeys={['UserType']} />
            {children}
            <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
            <br />
            <br />
            <br />
            <br />
        </ListBase>
    );
};




const ActiveBtn = () => {
    const myStyles = {
        display: "flex",
        width: "79px",
        padding: "8px 11px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "4px",
        background: "#E6FDF0",
    };
    const myTextStyles = {
        color: "var(--Success, #46A96F)",
        // textAlign: 'center',
        fontFamily: "Open Sans",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
    };
    return (
        <div style={myStyles}>
            <div style={myTextStyles}>Active</div>
        </div>
    );
};
const DeActiveBtn = () => {
    const myStyles = {
        display: "flex",
        width: "79px",
        padding: "8px 11px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "4px",
        background: "#FFF1F1",
    };
    const myTextStyles = {
        color: "var(--Success, #FF4848)",
        // textAlign: 'center',
        fontFamily: "Open Sans",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
    };
    return (
        <div style={myStyles}>
            <div style={myTextStyles}>Inactive</div>
        </div>
    );
};



const UserSetup = (props) => {

    const navigate = useNavigate();
    const { id } = useParams();

    const location = useLocation();

    const params = useParams();
    const getlocalStorageItem: any = localStorage.getItem(
        "stateRecord" + params?.applicationId
    );
    let locationState = JSON.parse(getlocalStorageItem);
    const data = location.state || locationState;


    const [switchSubscriberObj] = useStore("switchSubscriberName");


    // const {
    //     data: getusersetup,
    //     isLoading: loadingEmailSettingsDetails,
    // } = useGetList(
    //     "user-setup", {
    //     filter: {
    //         SubscriberId: switchSubscriberObj?.value,

    //     },
    // }s


    //  );

    console.log(params.filter, "getusersetup");



    return (

        <MyList

            toolBar={<MyListToolBar title={"User Setup"} />}
            sort={{ field: "id", order: "ASC" }}
            // filter={{ SubscriberId: switchSubscriberObj?.value ,UserType:filterValues?.UserType}}
            filter={{
                SubscriberId: switchSubscriberObj?.value,
                // ,UserType:"Manager",SearchCode:""
            }}

        >



            <UserSetupDataGrid />

        </MyList>
    );

};


const UserSetupDataGrid = () => {
    const navigate = useNavigate();
    const { displayedFilters, filterValues, setFilters, hideFilter, sort } =
        useListContext();
    const [selectedRecords, setSelectedRecords] = useState<number[]>([]);
    const [headerCheckbox, setHeaderCheckbox] = useState(false);
    console.log(filterValues, "filterValues")
    const [selectedAdminRecords, setSelectedAdminRecords] = useState<number[]>([]);
    const [selectedAgentRecords, setSelectedAgentRecords] = useState<number[]>([]);
    const [selectedManagerRecords, setSelectedManagerRecords] = useState<number[]>([]);
    const SelectAllHeader = ({ setSelectedRecords }) => {

        const { data } = useListContext();
        const [headerCheckbox, setHeaderCheckbox] = useState(false);
        console.log(data, "datauser")


        const handleHeaderCheckboxChange = (event) => {
            const isChecked = event.target.checked;

            if (filterValues.UserType === "All") { // Active Tab
                setHeaderCheckbox(isChecked);
                const allRecordIds = data.map(record => record.Id);
                if (isChecked) {
                    setSelectedAdminRecords(allRecordIds);
                } else {
                    setSelectedAdminRecords([]);
                }
            }

        };

        return (
            <Checkbox
                checked={headerCheckbox}
                onChange={handleHeaderCheckboxChange}
            />
        );
    };



    const removeCountryCode = (phoneNumber: any) => {
        // Regular expression to match the country code and the rest of the number
        const match = phoneNumber?.match(/^\+(\d+)\s*(.*)$/);

        if (match) {
            const countryCode = match[1];
            const number = match[2]?.trim();

            // If both country code and number are present, return the full phone number
            if (number) {
                return `+${countryCode} ${number}`;
            } else {
                // If only the country code is present without a number, return an empty string
                return '';
            }
        }

        // If the phone number doesn't match the pattern, return it as is
        return phoneNumber;
    };




    const handleCheckboxChange = (recordId: any) => {
        if (filterValues.UserType === "All") { // Active Tab
            setSelectedAdminRecords(prevSelectedRecords =>
                prevSelectedRecords.includes(recordId)
                    ? prevSelectedRecords.filter(id => id !== recordId)
                    : [...prevSelectedRecords, recordId]
            );
        }

    };

    const anyCheckboxChecked = selectedRecords.length > 0;
    useEffect(() => {

        if (anyCheckboxChecked) {
            console.log('At least one checkbox is checked', selectedRecords);
        } else {
            console.log('No checkboxes are checked');
        }
    }, [selectedRecords.length]);
    return (
        <>

            {filterValues.UserType === "All" && selectedAdminRecords.length > 0 && (
                <Box sx={{ padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                    <Stack direction="row" spacing={2}>
                        <MarkAsActiveSubscriber status1="Inactive" status="Deactivate" selectedIds={selectedAdminRecords} setSelectedIds={setSelectedAdminRecords} />
                        <MarkAsActiveSubscriber status1="Active" status="Activate" selectedIds={selectedAdminRecords} setSelectedIds={setSelectedAdminRecords} />
                    </Stack>
                </Box>
            )}


            <Datagrid
                sx={{

                    "& .RaDatagrid-headerCell": {
                        fontWeight: "bold",
                        fontSize: 12,
                        marginRight: 20,
                    },
                    "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
                    "& .MuiTableBody-root.datagrid-body.RaDatagrid-tbody tr td.MuiTableCell-root.MuiTableCell-body:nth-child(4), & .MuiTableBody-root.datagrid-body.RaDatagrid-tbody tr td.MuiTableCell-root.MuiTableCell-body:nth-child(3)":
                        { width: "200px" },
                }}
                bulkActionButtons={false}
                isRowSelectable={record => true}

            >

                <FunctionField
                    render={record => (<Checkbox
                        checked={
                            filterValues.UserType === "All"
                                ? selectedAdminRecords.includes(record.Id)

                                : false // Handle other tabs similarly
                        }
                        onChange={() => handleCheckboxChange(record.Id)}

                    />)}
                    label={<SelectAllHeader setSelectedRecords={setSelectedRecords} />}
                />


                <FunctionField
                    sortBy="Name"
                    label={"Name"}
                    render={(record) => record?.Name}
                    sx={{
                        minWidth: "90px",
                        whiteSpace: "normal",   // Allows text to wrap onto the next line
                        wordBreak: "break-word", // Breaks long email addresses onto a new line
                        overflowWrap: "break-word", // Ensures long content doesn't overflow
                    }} />

                <FunctionField
                    sortBy="Email"
                    label="Email"
                    render={(record) => record?.Email}
                    sx={{
                        minWidth: "90px",
                        whiteSpace: "normal",   // Allows text to wrap onto the next line
                        wordBreak: "break-word", // Breaks long email addresses onto a new line
                        overflowWrap: "break-word", // Ensures long content doesn't overflow
                    }}
                />
                <FunctionField
                    sortBy="Role"
                    label={"Role"}
                    render={(record) => record?.UserType}
                    sx={{ marginRight: 1 }}
                />


                <FunctionField
                    label={"Mobile No"}
                    render={(record) =>
                        removeCountryCode(record?.Mobile)
                    }
                    sx={{ marginRight: 3, minWidth: "130px", }}
                />

                <FunctionField
                    // sortBy="OtherPhone"
                    label={"Other Phone"}
                    render={(record) =>
                        removeCountryCode(record?.OtherPhone)
                    }
                    sx={{ marginRight: 3, minWidth: "130px", }}
                />


                <FunctionField
                    label="OFFICES"
                    render={(record) =>
                        (record?.Offices || record?.Properties)
                    }
                // sortBy="Offices"

                />

                <FunctionField
                    label="Agent"
                    render={(record) =>
                        record?.Agents
                    }
                // sortBy="Agent"


                />


                <FunctionField
                    sx={{ marginLeft: 0 }}
                    sortByOrder="ASC"
                    // sortBy={"Status"}
                    label={"Status"}
                    render={(record) => (
                        <>


                            <Box display={"flex"}>
                                {record.Active ? <ActiveBtn /> : <DeActiveBtn />}
                                <Box display={"flex"} sx={{ width: "100px" }}>
                                    <CopyLinkHoverDropdownUserSetup record={{ ...record }} />
                                    <IconButton
                                        aria-label="view details"
                                        color="primary"
                                        sx={{
                                            py: 0,
                                        }}
                                        onClick={() =>

                                            navigate(
                                                `/ats/settings/usersetup/updateuser/${record?.Id}/${record?.UserType}`,
                                                { state: { isAdding: false } }
                                            )


                                        }
                                        className="show_on_hover"
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </>
                    )}

                />

            </Datagrid>
        </>
    );
}
export default UserSetup;
