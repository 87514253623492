//Create Edit form with yup field messsage , submit button and cancel button
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { useCreate, useGetList, useNotify, useRefresh, useUpdate } from "react-admin";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InputTextArea from "../../componets/common/InputComponents/InputTextArea";

const EditPredefinedMessageAdmin = () => {   
    const navigate = useNavigate();
    const { id } = useParams();
    const [update, { isLoading: updateLoading, error: updateError }] = useUpdate();

    const methods = useForm({
        resolver: yupResolver(
            yup.object().shape({
                Message: yup.string().required("Message is required"),
            })
        ),
    });

    const onSubmit = (data: any) => {
        update("predefinedmessageAdmin", { 
            id: id,
            data: data,
        }, {
            onSuccess: (data) => {
                navigate(-1);
            }
        });
    };

    const {isLoading, error, data } = useGetList("predefinedMessageAdmin",{
        filter: {
            id: id
        }
    });
    useEffect(() => {
        if(data){
            methods.reset({...data[0]})
        }
    }, [data])
    if(isLoading){
        return <div>Loading...</div>
    }
    return (
        <div className="container mt-5"  >
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <h3 className="form-title">Edit Message</h3>
                    <br />
                    <InputTextArea
                        register={`Message`}
                        label="Type your message here"
                        placeholder="Type your message here"
                        labelClass={"lable-title"}
                        labelAsteriskClass={"color-red"}
                        additionalInputClass={"cus-control"}
                        styleProperty={{ height: "auto" }}
                        messageText={false}
                        requiredClass={{
                          fontSize: "70%",
                        }}
                        rows={4}
                    />
                    <br />
                    <Button type="submit" variant="contained">
                        Submit
                    </Button>
                    <Button
                        sx={{ ml: 2 }}
                        variant="outlined"
                        onClick={() => navigate(-1)}
                    >
                        Cancel
                    </Button>
                </form>
            </FormProvider>
        </div>
    );
};

export default EditPredefinedMessageAdmin;