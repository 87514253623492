import { useState } from "react";
import { Resource, useGetOne } from "react-admin";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { CgNotes } from "react-icons/cg";
import AuditLogsList from "../../Ats/AuditLogs/AuditLogsList";
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { JSONTree } from "react-json-tree";

const AuditlogModal = ({ record }) => {
  const [openAuditLogs, setOpenAuditLogs] = useState(false);
  const openAuditLogsHandler = () => setOpenAuditLogs(true);
 const trn_id= record?.trn_id;


  const { data: proofOfIdsData, isLoading: proofOfIdsDataLoading } = useGetOne(
    "proofOfIdsData",
    { id: trn_id },
    { enabled: trn_id != null, refetchOnWindowFocus: false }
  );
  const appName= proofOfIdsData?.applicant?.thirdPartyAppName;

  return (
    <>
   
      <div className="basic-subinfo px-2">
        <div className="modal-sm-button">
          <button
            type="button"
            className="p-0 mt-0"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={openAuditLogsHandler}
            style={{ lineHeight: "22px" }}
          >
            <CgNotes style={{ fontSize: "14px" }} /> <br />
            <span>Logs</span>
          </button>
        </div>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth={false}
        open={openAuditLogs}
        onClose={() => setOpenAuditLogs(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root .MuiDialogContent-root": { paddingTop: "0px" },
        }}
      >
        <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenAuditLogs(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
            {/* <span style={{fontSize:"12px"}}>Dismiss</span> */}
          <CloseIcon />
        </IconButton>
      </DialogTitle>

        <DialogContent>
        
       
          <AuditLogsList record={{...record,appName:appName}}/>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AuditlogModal;
