import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useCallback,
  useContext,
} from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormDataConsumer,
  useGetIdentity,
  useDataProvider,
  useNotify,
  Confirm,
  useGetOne,
  usePermissions,
} from "react-admin";
import * as Yup from "yup";
import {
  useForm,
  FormProvider,
  useFieldArray,
  Controller,
  useFormContext,
  useController,
} from "react-hook-form";
import { throttle } from 'lodash';
import { useMutation, useQueryClient } from "react-query";
import YesNoRadioButton from "../../componets/common/InputComponents/YesNoRadioButton";
import ShowComments from "./components/ShowComments";
import SelectInput from "./components/inputComponents/SelectInput";
import InputCurrencyField from "./components/inputComponents/InputCurrencyFiled";
import InputField2 from "../common/InputComponents/InputField2";
import AddDocuverusComment from "./Modals/AddDocuverusComment";
import DragDropDocumentButton from "./components/DragDropDocumentButton";
import Card from "@mui/material/Card";
import { CardHeader } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

import FormInstruction from "../common/FormInstruction";
import AlertDialog from "../common/AlertDialog";
import DeleteContainedBtn from "../common/DeleteContainedBtn";
import debounce from "lodash.debounce";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useSelector } from "react-redux";
import { RootState } from "../../Store/store";
import FilePreview from "../common/FilePreview";
import CustomPreviewUpload from "../common/CustomPreviewUpload";
import { useParams } from "react-router-dom";
import NoDocReasonRadioButtons from "../common/InputComponents/NoDocReasonRadioButtons";
import { JSONTree } from "react-json-tree";
import { useUserRole } from "../../ContextApi/UserRoleContext";
import FormsChildContext from "../../ContextApi/FormChildContext";
import AddDocuverusCommetApplicant from "./Modals/AddDocuverusCommetApplicant";
import ShowCommentsInternal from "./components/ShowCommentsInternal";

const ProofofOtherIncomeForm = forwardRef((props: any, ref) => {
  //
  const { identity, isLoading: identityLoading } = useGetIdentity();
  const { role: userRoleContext } = useUserRole();
  const userRoleRedux = useSelector((state: RootState) => state.users.role);
  const userRole = userRoleContext || userRoleRedux;
  const queryClient = useQueryClient();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { id, appType } = useParams();
  const invitationType: any = appType;
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteSource, setConfirmDeleteSource] = useState(false);
  const [source_id, setSourceId] = useState("");
  const [hasExecuted, setHasExecuted] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(true);
  const { role, required } = props;
  
  // const trn_id = props.trn_id ;//useSelector((state: RootState) => state.users.userTrnId);
  // Fix the co-applicant issue
  const userTrnId = useSelector((state: RootState) => state.users.userTrnId);
  const trn_id = props?.trn_id || userTrnId;
  const [disableAddSourceoption, setDisableAddSourceoption] = useState(false);
  const [disableAddSource, setDisableAddSource] = useState(true);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [disableNewBtn, setdisableNewBtn] = useState(false);
  useImperativeHandle(ref, () => ({ handleClickByRef, handleSubmitManual }));

  const localSchema = Yup.object({
    response: Yup.string().required("required").typeError("required"),
    otherincome: Yup.array().when("response", {
      is: (value) => {
        return value === "Yes";
      },
      then: Yup.array().of(
        Yup.object().shape({
          files: Yup.array().min(1, "At Least 1 Document Is Required"),
        })
      ),
    }),
  });

  const schema = Yup.object({
    response: Yup.string().required("required").typeError("required"),
  });

  const schemaNotRequired = Yup.object({
    response: Yup.string().nullable(),
  });

  const getSchema = () => {
    if (required) {
      if (role === "applicant") {
        return localSchema;
      } else {
        return schema;
      }
    } else {
      return schemaNotRequired;
    }
  };

  const methods = useForm<any>({
    resolver: yupResolver(getSchema()),
    mode: "onChange",
    defaultValues: {
      response: "",
      otherincome: [],
    },
  });

  async function handleClickByRef() {
    submitButtonRef?.current?.click();
    const isValidForm = await methods.trigger();

    return isValidForm;
  }
  const {
    data: otherIncomeData,
    isLoading,
    isFetching,
    isFetched,
    isRefetching,
  } = useGetOne(
    "otherIncomeDoc",
    { id: trn_id },
    { refetchOnWindowFocus: false, keepPreviousData: true, staleTime: 5 }
  );

  useEffect(() => {
    if (!isFetching) {
      const formData = methods.getValues();
      const response = formData.response ? formData.response : props.response; //? props.response : props.status == 1 ? 'Yes' : '';
      methods.reset({
        ...props,
        otherincome: props.otherincome.map((item, index) => ({
          ...item,
          other_income_type:
            methods.getValues(`otherincome.${index}.other_income_type`) ||
            item.other_income_type,
          pension_source:
            methods.getValues(`otherincome.${index}.pension_source`) ||
            item.pension_source,
          rental_assistance_type:
            methods.getValues(`otherincome.${index}.rental_assistance_type`) ||
            item.rental_assistance_type,
          other_rental_assistance:
            methods.getValues(`otherincome.${index}.other_rental_assistance`) ||
            item.other_rental_assistance,
          other_source:
            methods.getValues(`otherincome.${index}.other_source`) ||
            item.other_source,
          monthly_income:
            methods.getValues(`otherincome.${index}.monthly_income`) ||
            item.monthly_income,
          no_doc_reason:
            item?.no_doc_reason ||
            "I don't currently have access to document(s), but can provide later",
        })),
        response: response,
      });
    }
  }, [isFetching]);
  // !isLoading && props
  const {
    mutate: createNewIncomeSource,
    isLoading: loadingNewIncomeSource,
    isSuccess: NewIncomeSourceSuccess,
  } = useMutation(
    "NewIncomeSource",
    (data: any) =>
      dataProvider.create("income", {
        data: {
          document_id: data.document_id,
          source_id: data.source_id,
          trn_id: data.trn_id,
        },
      }),
    {
      onSuccess: (data) => {
        setDisableAddSourceoption(true);
        queryClient.invalidateQueries(["otherIncomeDoc"]);
        notify("New income source added successfully", {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        if (methods.watch("otherincome").length === 1) {
          setHasExecuted(false);
        }
      },
      onError: (data) => {
        alert("Fail adding new income source");
        setdisableNewBtn(false);
      },
      onMutate: () => {
        notify("Adding new income source...", {
          type: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
    }
  );

  const {
    mutate: deleteIncomeSource,
    isLoading: loadingdeleteIncomeSource,
    isSuccess: createdeleteIncomeSource,
  } = useMutation(
    "deleteIncomeSource",
    (data: any) =>
      dataProvider.deleteSequence("income", {
        document_id: data.document_id,
        source_id: data.source_id,
        trn_id: data.trn_id,
        subUrl: data.subUrl,
      }),
    {
      onSuccess: (data) => {

        setConfirmDeleteSource(false);
        queryClient.invalidateQueries(["otherIncomeDoc"]);
        notify("Income source deleted successfully", {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        if (methods.watch("otherincome").length === 1) {
          methods.setValue("response", "No");
          handleSubmitDocReason("No")
          setHasExecuted(false);
        }
      },
      onError: (data) => {
        setConfirmDeleteSource(false);
        alert("Fail deleting income source");
      },
      onMutate: () => {
        notify("Deleting income source...", {
          type: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
      onSettled: () => { },
    }
  );

  const {
    mutate: deleteAllSource,
    isLoading: loadingdeleteAllSource,
    isSuccess: createdeleteAllSource,
  } = useMutation(
    "deleteAllSource",
    (data: any) => {
      let promises: any = [];
      data?.otherincome?.map(async (otheri) => {
        promises.push(
          dataProvider.deleteSequence("income", {
            document_id: 7,
            source_id: otheri?.other_income_number,
            trn_id: trn_id,
            subUrl: "/income/deleteSource",
          })
        );
      });
      return Promise.all(promises);
    },
    {
      onSuccess: (data) => {

        handleSubmitDocReason("No");
        setConfirmDelete(false);
        setDisableAddSourceoption(false);
        // queryClient.invalidateQueries(['otherIncomeDoc'])
        notify("Income source deleted successfully", {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });

      },
      onError: (data) => {
        setConfirmDelete(false);
        alert("Fail deleting income source");
      },
      onMutate: () => {
        notify("Deleting income source...", {
          type: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
      onSettled: () => { },
    }
  );

  const {
    mutate: updateOtherIncome,
    isLoading: loadingupdateOtherIncome,
    isSuccess: createupdateOtherIncome,
  } = useMutation(
    "updateOtherIncome",
    (data: any) =>
      dataProvider.update("other_sections", {
        id: data.id,
        data: data.data,
        previousData: data.previousData,
      }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["otherIncomeDoc"]);
        if (role !== "applicant") {
          notify("Other income updated successfully", {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
      },
      onError: (data) => {
        alert("Fail updating other income details");
      },
      onMutate: () => {
        notify("Updating other income details333333333333...", {
          type: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        setDisableAddSourceoption(false);
        setDisableAddSource(true);
      },
    }
  );


  // const handleAddIncomeSource = async (source) => {
  //   const payload = {
  //     document_id: 7,
  //     source_id: props?.otherincome?.length + 1,
  //     trn_id: trn_id,
  //   };
  //   createNewIncomeSource(payload);
  // };

  useEffect(() => {

    setdisableNewBtn(false);
  }, [props?.otherincome?.length + 1])

  // useEffect(()=>{
  //   if(props?.otherincome?.length  === 0)
  //     methods.setValue("response", "No");
  // },[props?.otherincome?.length ]);

  const handleAddIncomeSource = useCallback(
    throttle(() => {
      if (methods.watch("otherincome")?.length < 3) {
        console.log("bankResopose", props?.banking)
        createNewIncomeSource({
          document_id: 7,
          source_id: props?.otherincome?.length + 1,
          trn_id: trn_id,
        });
      }
      //setdisableNewBtn(false);    
    }, 4000),
    [methods, props?.otherincome?.length + 1]
  );

  const handleAddIncomeSourceYesNo = async () => {
    if (hasExecuted) return;
    const payload = {
      document_id: 7,
      source_id: 1,
      trn_id: trn_id,
    };
    // createNewIncomeSource(payload);
    // setDisableAddSourceoption(false);

    try {
      await createNewIncomeSource(payload);
      setDisableAddSourceoption(false); // Set after success
    } catch (error) {
      console.error("Error in creating new employer:", error);
    }

    setHasExecuted(true);
  };

  const handleDeleteIncomeSource = async (s_id) => {
    deleteIncomeSource({
      document_id: 7,
      source_id: s_id,
      trn_id: trn_id,
      subUrl: "/income/deleteSource",
    });
  };

  const onSubmitEmpty = (val) => null;

  const handleSubmitManual = () => {
    onSubmit(methods.getValues());
  };

  const onSubmit = (values) => {
    if (values.response === "Yes") {
      const more_props = {
        availableToUpload: "Yes",
      };

      const payload = values.otherincome.map((obj) => ({
        ...obj,
        ...more_props,
        fileCount: obj.files.length,
        no_doc_reason:
          obj.files?.length > 0
            ? null
            : obj?.no_doc_reason == "other"
              ? obj?.other_reason
              : obj?.no_doc_reason,
        other_reason:
          obj?.availableToUpload !== "Yes" && obj?.no_doc_reason == "other"
            ? obj?.other_reason
            : null,
      }));

      const final_paylaod = {
        ...values,
        otherincome: payload,
        subUrl: "/income/saveOtherIncomeSection",
        trn_id: trn_id,
        response: "Yes",
      };

      delete final_paylaod.role;

      updateOtherIncome({
        id: trn_id,
        data: final_paylaod,
        previousData: [],
      });
    } else {
      const final_paylaod = {
        subUrl: "/income/saveOtherIncomeSection",
        trn_id: trn_id,
        Question: "Do you have any non-employment Income?",
        response: "No",
        otherincome: [],
        employerCount: 0,
      };
      //
      updateOtherIncome({
        id: trn_id,
        data: final_paylaod,
        previousData: [],
      });
    }
  };

  const handleSubmitDocReason = async (val) => {
    const final_paylaod = {
      subUrl: "/income/saveOtherIncomeSection",
      trn_id: trn_id,
      Question: "Do you have any non-employment Income?",
      response: val,
      otherincome: [],
      employerCount: 0,
    };
    //
    updateOtherIncome({
      id: trn_id,
      data: final_paylaod,
      previousData: [],
    });
  };

  const handleDeleteAllSource = async () => {
    deleteAllSource({
      otherincome: props.otherincome,
    });
  };
  const handleAddAPIDebounced = useCallback(
    debounce(
      (nextValue) =>
        (async () => {
          handleAddIncomeSourceYesNo();
        })(),
      1000
    ),
    [] // will be created only once initially
  );
  const [expandOtherIncomeSection, setExpandOtherIncomeSection] =
    useState(true);
  useEffect(() => {
    if (userRole === "applicant") setExpandOtherIncomeSection(true);
  }, [userRole]);

  useEffect(() => {
    if (methods.watch("response") === "Yes") setExpandOtherIncomeSection(true);
  }, [methods.watch("response")]);
  const formsChildContext = useContext(FormsChildContext);

  useEffect(() => {
    // formsChildContext?.updateFormAtIndex(props.index,{ ...formsChildContext?.forms, incomeSection: methods.formState?.errors })
    props.setChildFormError(methods.formState?.errors);
  }, [methods.formState]);
  const { permissions } = usePermissions();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    methods.setValue("response", "Yes");
    setOpen(false);
  };
  return (
    <>
      {props && (
        <FormProvider {...methods}>
          <div className="info-toggle">
            <div className="toggle-heads" style={{}}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex justify-content-between align-items-center">
                  <b className="mx-3">Proof of Other Income</b>
                  <br />
                  <div className="subcontent text-center p-1 ">
                    <div className="upload-subwrap">
                      {userRole !== "applicant" &&
                        methods.watch("response") !== "Yes" && (
                          <p className="prf-upload">
                            <strong style={{ color: "#FF8585" }}>
                              Note:{" "}
                            </strong>
                            No documents uploaded
                          </p>
                        )}
                    </div>
                  </div>
                </div>
                {userRole !== "applicant" && (
                  <IconButton
                    className="m-1 print-hide"
                    onClick={() => setExpandOtherIncomeSection((v) => !v)}
                    color="primary"
                  >
                    {!expandOtherIncomeSection ? <AddIcon /> : <RemoveIcon />}
                  </IconButton>
                )}
              </div>
              <div
                className={` ${!expandOtherIncomeSection ? "d-none" : "d-display"
                  }`}
              >
                <form
                  onSubmit={methods.handleSubmit(
                    role === "applicant" ? onSubmitEmpty : onSubmit
                  )}
                >
                  <>
                    <div className={`px-3 card-body cust-card-appl`}>
                      <div className="inner-block-flex w-100">
                        <YesNoRadioButton
                          required={props.required}
                          // label="Do you have any non-employment Income ? "
                          label={
                            invitationType == "1"
                              ? "Do you have any other income (including alimony, child support, investment, retirement, SSI, disability or rental assistance, etc)?"
                              : "Do you have any non-employment Income?"
                          }
                          source="response"
                          // disable={!disableAddSource }
                          disable={!disableAddSource && loadingNewIncomeSource || loadingdeleteIncomeSource || isFetching}
                          handleOnClick={(val) => {
                            if (
                              val === "Yes" &&
                              props?.otherincome?.length === 0 && !hasExecuted
                            ) {
                              handleAddAPIDebounced();
                            }
                            // if (
                            //   val === "No" &&
                            //   props?.otherincome?.length === 0 &&
                            //   (methods.watch("response") === "Yes" ||
                            //     !methods.watch("response"))
                            // ) {
                            //   handleSubmitDocReason(val);
                            // }
                            if (
                              val === "No" &&
                              props?.otherincome?.length > 0
                            ) {
                              // setConfirmDelete(true);
                              setOpen(true);
                            }
                          }}
                        />
                      </div>
                      {methods.watch("response") &&
                        methods.watch("response") === "Yes"
                         &&  userRole === "Docuverus Super Admin"
                        //  (props.otherincome?.[0]?.Internal == true ? permissions?.includes('view_internal_comments') : true)
                          && (
                          <span className="print-hide"><ShowComments
                            comment_heading="Docuverus Internal Comment"
                            section_comment={
                              props.otherincome?.[0]?.Internal_comment || []
                            }
                            role={role === "applicant" ? "applicant" : userRole}
                          /></span>
                        )}

                      {methods.watch("response") &&
                        methods.watch("response") === "Yes" && (
                          <ShowComments
                            section_comment={
                              props.otherincome?.[0]?.section_comment || []
                            }
                            role={role === "applicant" ? "applicant" : userRole}
                          />
                        )}
 
                        {(methods.watch("response") &&
                        methods.watch("response") === "Yes" && props.otherincome?.[0]?.section_comment['applicant'] !== "") && (
                          <ShowComments
                            section_comment={
                              props.otherincome?.[0]?.section_comment || []
                            }
                            role={role === "applicant" ? "applicant" : userRole}
                            comment_heading="Applicant Comment"
                          />
                        )}
                    </div>

                    <FormDataConsumer>
                      {({ formData, ...rest }) => {
                        if (formData.response === "Yes")
                          return (
                            <OtherIncomeSections
                              source="otherincome"
                              // loadingdeleteIncomeSource={loadingdeleteIncomeSource}
                              role={
                                role === "applicant" ? "applicant" : userRole
                              }
                              handleSubmitManual={handleSubmitManual}
                              formErrors={methods.formState.errors}
                              invitationType={invitationType}
                              Trnid={trn_id}
                              setHasExecuted={setHasExecuted}
                              setButtonVisible={setButtonVisible}
                              buttonVisible={buttonVisible}
                            />
                          );
                      }}
                    </FormDataConsumer>
                    {userRole === "applicant" && (
                      <button
                        type="submit"
                        ref={submitButtonRef}
                        className="btn btn-primary"
                        style={{ display: "none" }}
                      >
                        {" "}
                        Save
                      </button>
                    )}
                    <div className="d-flex flex-wrap justify-content-between print-hide pt-4">
                      <div className="">
                        {props?.otherincome?.length > 0 &&
                          methods.watch("response") === "Yes" &&
                          userRole !== "applicant" && (
                            <button
                              type="submit"
                              className="btn btn-primary print-hide"
                              style={{
                                backgroundColor: "rgb(45, 195, 232)",
                                color: "white",
                                marginLeft: 18,
                                padding: "8px 15px 8px 15px",
                              }}
                              disabled={loadingupdateOtherIncome}
                            >
                              {" "}
                              Save
                            </button>
                          )}
                      </div>
                      <div className="px-2">
                        {props?.otherincome?.length > 0 &&
                          methods.watch("response") === "Yes" && role == "applicant" && (
                            <>
                              {/* <JSONTree data={otherIncomeData?.other_income_details?.otherincome[0]?.section_comment?.applicant}/> */}
                              <div className="print-hide">
                                <AddDocuverusCommetApplicant
                                  label={
                                    `Add Comment`
                                  }
                                  title={
                                    `Add Comment`
                                  }
                                  trn_id={trn_id}
                                  is_applicant={
                                    true
                                  }
                                  defaultValue={
                                    otherIncomeData?.other_income_details?.otherincome[0]?.section_comment?.applicant
                                  }
                                  document_id={7}
                                  tag={"addComment_other_income"}
                                  invalidateQueries={"otherIncomeDoc"}
                                  emp_id={null}
                                  role={
                                    "applicant"
                                  }
                                />
                              </div>
                            </>
                          )}
                      </div>
                      <div className="px-2">
                        {props?.otherincome?.length > 0 &&
                          methods.watch("response") === "Yes" && role !== "applicant" && (
                            <>
                              <div className="print-hide">
                                <AddDocuverusComment
                                  label={
                                    userRole === "applicant"
                                      ? `Add Comment`
                                      : "Add Docuverus Comment"
                                  }
                                  title={
                                    userRole === "applicant"
                                      ? `Add Comment`
                                      : "Add Docuverus Comment"
                                  }
                                  trn_id={trn_id}
                                  is_applicant={
                                    userRole === "applicant" ? true : false
                                  }
                                  defaultValue={
                                    otherIncomeData?.other_income_details?.otherincome[0]?.section_comment?.admin
                                      ? otherIncomeData?.other_income_details?.otherincome[0]?.section_comment?.admin
                                      : ""
                                  }
                                  document_id={7}
                                  tag={"addComment_other_income"}
                                  invalidateQueries={"otherIncomeDoc"}
                                  emp_id={null}
                                  role={
                                    userRole === "applicant"
                                      ? "applicant"
                                      : userRole
                                  }
                                />
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  </>
                </form>
              </div>

              {/* <Dialog
                open={confirmDelete}
                onClose={() => {
                  methods.setValue("response", "Yes");
                  setConfirmDelete(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {`Are you sure want to delete income source ?`}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Warning! Once deleted cannot revert back .
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <button
                    className="btn btn-info text-light"
                    onClick={() => {
                      methods.setValue("response", "Yes");
                      setConfirmDelete(false);
                    }}
                  >
                    No
                  </button>
                  <button
                    className="btn btn-info text-light"
                    onClick={() => {
                      handleDeleteAllSource();
                    }}
                  >
                    Yes
                  </button>
                </DialogActions>
              </Dialog> */}
              <Card>
                <CardHeader sx={{ padding: 0 }} />
              </Card>
              {/* <Confirm
                isOpen={confirmDelete}
                sx={{
                  "& .RaConfirm-iconPaddingStyle": { display: "none" },
                }}
                title=" Are you sure want to delete income source ?"
                content={" Warning! Once deleted cannot revert back ."}
                onConfirm={() => {handleDeleteAllSource()
                  setDisableAddSource(false);
                }}
                onClose={() => {
                  methods.setValue("response", "Yes");
                  setConfirmDelete(false);
                }}
              /> */}
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"Warning!"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    You must delete all existing sources first.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      methods.setValue("response", "Yes");
                      // setConfirmDelete(false); // Ensures response does not change
                      handleClose();
                    }}
                    color="primary"
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
              {/* <Dialog
                open={confirmDeleteSource}
                onClose={() => {
                  setSourceId("");
                  setConfirmDeleteSource(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {`Delete Income Source ?`}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure, you want to delete income source ?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <button
                    className="btn btn-info text-light"
                    onClick={() => {
                      setSourceId("");
                      setConfirmDeleteSource(false);
                    }}
                  >
                    No
                  </button>
                  <button
                    className="btn btn-info text-light"
                    onClick={() => {
                      handleDeleteIncomeSource(source_id);
                    }}
                  >
                    Yes
                  </button>
                </DialogActions>
              </Dialog> */}
            </div>
            {props?.otherincome?.length < 3 &&
              methods.watch("response") === "Yes" && (
                <div
                  className={` ${!expandOtherIncomeSection ? "d-none" : "d-display"
                    } collpase-ic-new text-center print-hide`}
                >
                  {props?.otherincome?.length >= 1 && !isFetching && <button
                    style={{ fontSize: "1rem", fontFamily: "OpenSans-Bold" }}
                    disabled={disableNewBtn || !buttonVisible}
                    onClick={() => {
                      setdisableNewBtn(true);
                      handleAddIncomeSource();
                      setDisableAddSource(true);

                    }}
                  >
                    + Add Source
                  </button>}
                </div>
              )}
          </div>
        </FormProvider>
      )}
    </>
  );
});

const OtherIncomeSections = ({
  source,
  role,
  formErrors,
  handleSubmitManual,
  invitationType,
  Trnid = null,
  setHasExecuted,
  setButtonVisible,
  buttonVisible,
}) => {
  const handleAPIDebounced = useCallback(
    debounce(
      (nextValue) =>
        (async () => {
          handleSubmitManual();
        })(),
      5000
    ),
    [] // will be created only once initially
  );
  const userRole = useSelector((state: RootState) => state.users.role);
  const [disableAddSourceoption, setDisableAddSourceoption] = useState(false);
  const [filePreview, setFilePreview] = useState<any>({
    proof_of_income: "",
    proof_of_income_filePath: "",
    index: "",
  });
  const {
    control,
    register,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  // Fix the co-applicant issue
  const userTrnId = useSelector((state: RootState) => state.users.userTrnId);
  const trn_id = Trnid || userTrnId;
  const isOpeningForDashboard = useSelector(
    (state: RootState) => state.app.isOpeningForDashboard
  );
  const [confirmDeleteSource, setConfirmDeleteSource] = useState(false);
  const [otherIncomeNumber, setOtherincomeNumber] = useState(0);
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      name: source,
    }
  );
  const dataProvider = useDataProvider();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const {
    mutate: updateOtherIncome,
    isLoading: loadingupdateOtherIncome,
    isSuccess: createupdateOtherIncome,
  } = useMutation(
    "updateOtherIncome",
    (data: any) =>
      dataProvider.update("other_sections", {
        id: data.id,
        data: data.data,
        previousData: data.previousData,
      }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["otherIncomeDoc"]);
        if (role !== "applicant") {
          notify("Other income updated successfully", {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
      },
      onError: (data) => {
        alert("Fail updating other income details");
      },
      onMutate: () => {
        notify("Updating other income details...", {
          type: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        setDisableAddSourceoption(false);

      },
    }
  );

  const handleSubmitDocReason = async (val) => {
    const final_paylaod = {
      subUrl: "/income/saveOtherIncomeSection",
      trn_id: trn_id,
      Question: "Do you have any non-employment Income?",
      response: val,
      otherincome: [],
      employerCount: 0,
    };
    //
    updateOtherIncome({
      id: trn_id,
      data: final_paylaod,
      previousData: [],
    });
  };

  const {
    mutate: deleteIncomeSource,
    isLoading,
    isSuccess: createdeleteIncomeSource,
  } = useMutation(
    "deleteIncomeSource",
    (data: any) =>
      dataProvider.deleteSequence("income", {
        document_id: data.document_id,
        source_id: data.source_id,
        trn_id: data.trn_id,
        subUrl: data.subUrl,
      }),
    {
      onSuccess: (data) => {
        console.log(watch("otherincome").length, "otherincome13")
        setOtherincomeNumber(0);
        setConfirmDeleteSource(false);
        queryClient.invalidateQueries(["otherIncomeDoc"]);
        notify("Income source deleted successfully", {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });

        if (watch("otherincome").length === 1) {
          setValue("response", "No");
          handleSubmitDocReason("No");
          setHasExecuted(false);

        }
      },
      onError: (data) => {
        setOtherincomeNumber(0);
        setConfirmDeleteSource(false);
        alert("Fail deleting income source");
      },
      onMutate: () => {
        notify("Deleting income source...", {
          type: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
    }
  );
  // const [buttonVisible, setButtonVisible] = useState(true);
  const handleDeleteIncomeSource = async () => {
    // if (!isOpeningForDashboard)
    if (otherIncomeNumber !== 0) {
      deleteIncomeSource({
        document_id: 7,
        source_id: otherIncomeNumber,
        trn_id: trn_id,
        subUrl: "/income/deleteSource",
      });
      setButtonVisible(false);
      setTimeout(() => {
        setButtonVisible(true);
      }, 5000);
    }
  };

  //
  return (
    <>
      <div className="px-3 pt-3">
        {fields.map((field: any, index) => {
          const other_income_number = index + 1;

          return (
            <>
              <div key={index} className="login-radio-types">
                <div className="row">
                  <div className="col-12">
                    <Card
                      sx={{
                        background: "#f5fdff",
                        boxShadow: "none",
                        borderBottom: "1px solid black",
                        borderRadius: 0,
                      }}
                    >
                      <CardHeader
                        sx={{ padding: 0 }}
                        title={
                          <b style={{ fontSize: 13 }}>
                            {other_income_number}. Other Income Source
                          </b>
                        }
                        action={
                          <DeleteContainedBtn
                            onClick={() => {
                              setOtherincomeNumber(other_income_number);
                              setConfirmDeleteSource(true);
                            }}
                            disable={isLoading}
                          />
                        }
                      />
                    </Card>
                    {buttonVisible && <Confirm
                      isOpen={confirmDeleteSource}
                      // loading={isLoading}
                      sx={{
                        "& .RaConfirm-iconPaddingStyle": { display: "none" },
                      }}
                      title="Confirm"
                      content={
                        "Are you sure you want to delete Other income source ?"
                      }
                      onConfirm={() => handleDeleteIncomeSource()}
                      onClose={() => {
                        setOtherincomeNumber(0);
                        setConfirmDeleteSource(false);
                      }}
                    />}
                  </div>
                  <div
                    className={
                      filePreview.proof_of_income !== ""
                        ? `col-md-8 col-sm-12 px-4 card-body pt-2`
                        : ` px-4 card-body pt-2`
                    }
                  >
                    <div className="row add-md-form mr-t10">
                      <div className="col-sm-6 col-6 col-md-4">
                        <SelectInput
                          required={false}
                          onChangeCallback={handleAPIDebounced}
                          label="Source of Income Type"
                          source={`otherincome.${index}.other_income_type`}
                          placeholder="Select income source"
                          options={[
                            { value: "Alimony", name: "Alimony" },
                            { value: "Child Support", name: "Child Support" },
                            { value: "Disability", name: "Disability" },
                            {
                              value: "Pension/retirement",
                              name: "Pension/retirement",
                            },
                            {
                              value: "Rental Assistance",
                              name: "Rental Assistance",
                            },
                            {
                              value: "Social Security",
                              name: "Social Security",
                            },
                          ]}
                        />
                      </div>
                      <FormDataConsumer>
                        {({ formData, ...rest }) => (
                          <>
                            {formData.otherincome?.[index]
                              ?.other_income_type === "Pension" ? (
                              <>
                                <div className="col-sm-6 col-6 col-md-4">
                                  <InputField2
                                    handleOnChange={() => handleAPIDebounced()}
                                    type="text"
                                    label="Pension Source"
                                    source={`otherincome.${index}.pension_source`}
                                    placeholder="Enter pension source"
                                  />
                                </div>
                              </>
                            ) : null}
                            {formData.otherincome?.[index]
                              ?.other_income_type === "Rental Assistance" ? (
                              <>
                                <div className="col-sm-6 col-6 col-md-4">
                                  <SelectInput
                                    onChangeCallback={() =>
                                      handleAPIDebounced()
                                    }
                                    label="Rental assistance type"
                                    source={`otherincome.${index}.rental_assistance_type`}
                                    className="col-lg-4 col-md-6 col-sm-6 col-6"
                                    placeholder="Rental assistance type "
                                    options={[
                                      { value: "TRA", name: "TRA" },
                                      {
                                        value: "Social Services/Welfare",
                                        name: "Social Services/Welfare",
                                      },
                                      {
                                        value: "Section 8",
                                        name: "Section 8",
                                      },
                                      { value: "Other", name: "Other" },
                                    ]}
                                  />
                                </div>
                                {formData.otherincome?.[index]
                                  ?.rental_assistance_type === "Other" ? (
                                  <>
                                    <div className="col-sm-6 col-6 col-md-4">
                                      <InputField2
                                        handleOnChange={() =>
                                          handleAPIDebounced()
                                        }
                                        type="text"
                                        label="Other Rental Assistance"
                                        source={`otherincome.${index}.other_rental_assistance`}
                                        placeholder="Other Rental Assistance"
                                      />
                                    </div>
                                  </>
                                ) : null}
                              </>
                            ) : null}
                            {formData.otherincome?.[
                              index
                            ]?.other_income_type?.trim() === "Other" ? (
                              <div className="col-sm-6 col-6 col-md-4">
                                <InputField2
                                  handleOnChange={() => handleAPIDebounced()}
                                  type="text"
                                  label="Other Source"
                                  source={`otherincome.${index}.other_source`}
                                  placeholder="Other Source"
                                />
                              </div>
                            ) : null}
                            <div className="col-sm-6 col-6 col-md-4">
                              <InputCurrencyField
                                handleOnChange={() => handleAPIDebounced()}
                                type="number"
                                label="Monthly Income"
                                source={`otherincome.${index}.monthly_income`}
                                placeholder="Enter monthly Income"
                              />
                            </div>
                          </>
                        )}
                      </FormDataConsumer>
                    </div>
                    {/* 2nd que stated */}
                    {invitationType == "1" ? (
                      <div className="mr-t20 bullet-style">
                        <YesNoRadioButton
                          required={true}
                          label="Do you have a Proof of Other Income available to upload ?"
                          source={`otherincome.${index}.availableToUpload`}
                          disable={getValues()?.otherincome[index]?.fileCount}
                          handleOnClick={(val) => {
                            // handleAPIDebounced();
                            if (val === "No") {
                              handleAPIDebounced();
                            }
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {invitationType == "1" &&
                      watch(`otherincome.${index}.availableToUpload`) ==
                      "No" && (
                        <>
                          <div className="login-radio-types p-2">
                            <NoDocReasonRadioButtons
                              handleOnchange={() => handleAPIDebounced()}
                              source={`otherincome.${index}.no_doc_reason`}
                              options={[
                                {
                                  name: "I don't currently have access to document(s), but can provide later",
                                  value:
                                    "I don't currently have access to document(s), but can provide later",
                                },
                                {
                                  name: "I do not have proof of employment",
                                  value: "I do not have proof of employment",
                                },
                                {
                                  name: "Other",
                                  value: "other",
                                },
                              ]}
                              section_id={5}
                            />
                            {watch(`otherincome.${index}.no_doc_reason`) ==
                              "other" && (
                                <div className="col-12 col-md-4 col-lg-4 mr-t20 mr-l30 mr-b10">
                                  <InputField2
                                    handleOnChange={() => handleAPIDebounced()}
                                    source={`otherincome.${index}.other_reason`}
                                    label={"Other reason"}
                                    placeholder=""
                                  />
                                </div>
                              )}
                          </div>
                        </>
                      )}
                    {/* 2nd ques end */}
                    {(invitationType == "1" &&
                      watch()?.otherincome[index]?.availableToUpload ==
                      "Yes") ||
                      invitationType !== "1" ? (
                      <div className="row ">
                        <div
                          className="col-sm-12 col-md-10 print-hide"
                          style={{
                            fontSize: "11px",
                            color: "#606060",
                            paddingLeft: "16px",
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <strong>
                              {`${index + 1
                                }. ${"Proof of Non-Employment Income "}`}
                              :*
                            </strong>
                            <AlertDialog
                              message={
                                "PDF bank statements covering 3 most recent months downloaded directly from your bank account website are required to verify non-employment income. Please be sure to add bank account information below and please be sure to upload PDF bank statements for each bank account entered."
                              }
                            />
                          </div>
                          <FormInstruction
                            message={
                              "IMPORTANT! PDF proof of income can be uploaded here. Please refrain from uploading pictures of documents. PDF bank statements downloaded directly from bank account website should also be uploaded in the Banking section below to prove non-employment income."
                            }
                          />
                        </div>
                        {/* <div className="col-sm-12 col-md-2 mb-2 print-hide">
                        <DragDropDocumentButton
                          SourceId={other_income_number}
                          documentId={7}
                          fetchRecord={() => null}
                          acceptedFiles={`image/*,application/pdf`}
                          invalidateQueries={"otherIncomeDoc"}
                          tag={`fileOprations_other_income`}
                          trn_id={trn_id}
                          fileError={
                            formErrors?.otherincome?.[other_income_number - 1]
                              ? true
                              : false
                          }
                        />
                      </div> */}
                        <div className="col-md-12 col-sm-12 row mt-2 " key={index}>
                          <div className="d-flex flex-wrap " >
                            <div>
                              <DragDropDocumentButton
                                key={index}
                                SourceId={other_income_number}
                                documentId={7}
                                fetchRecord={() => null}
                                acceptedFiles={`image/*,application/pdf`}
                                invalidateQueries={"otherIncomeDoc"}
                                tag={`fileOprations_other_income`}
                                trn_id={trn_id}
                                fileError={
                                  formErrors?.otherincome?.[
                                    other_income_number - 1
                                  ]
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            {field?.files?.map((item, i) => {
                              return (
                                <div
                                  className="px-1"
                                  key={item.application_document_id}
                                //key={index}
                                >

                                  <FilePreview
                                    key={item.application_document_id}
                                    //key={index}
                                    source_index={index}
                                    file_name={item.file_name}
                                    source_name={"other_income"}
                                    doc_id={item.application_document_id}
                                    img={item.path_image}
                                    is_Duplicate={item.is_Duplicate}
                                    tag={"delete_document_other_income"}
                                    invalidateQueries={"otherIncomeDoc"}
                                    file={item.path}
                                    handleClick={() =>
                                      setFilePreview({
                                        ...filePreview,
                                        proof_of_income:
                                          item.application_document_id,
                                        proof_of_income_filePath:
                                          item.path,
                                        id: field.fieldId
                                      })
                                    }
                                  // handleOnChange={() => {
                                  //   const isCurrentFile =
                                  //     filePreview.proof_of_income_filePath ==
                                  //     item?.path
                                  //       ? true
                                  //       : false;
                                  //   const isCurrentIndex =
                                  //     filePreview.index == index
                                  //       ? true
                                  //       : false;

                                  //   if (isCurrentFile && isCurrentIndex) {
                                  //     setFilePreview({
                                  //       proof_of_income: null,
                                  //       proof_of_income_filePath: null,

                                  //       index: null,
                                  //     });
                                  //   }
                                  // }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                          {/* </div> */}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {field.files.findIndex(file => file.application_document_id === filePreview.proof_of_income) !== -1 && filePreview.id == field?.fieldId && (
                    <div className="col-md-4 col-sm-12 mt-2">
                      <CustomPreviewUpload
                        filePath={filePreview.proof_of_income_filePath}
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          );
        })}
      </div>
      {/* <ReactQueryDevtools /> */}
    </>
  );
};

export default ProofofOtherIncomeForm;
