import { Button, Datagrid, DateField, FunctionField, List, ListBase, ListToolbar, NumberField, Pagination, Resource, TextField, useGetList, useGetOne, useListContext, useStore } from 'react-admin';
import {
    Box, TextField as MuiTextField,
    Button as MuiButton, Grid, Typography,
} from "@mui/material";
import StatsAndGraph from './DocuploadReportCount';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
import FilterReports from './FilterReports';
import ActionsReports from './ActionsReports';
import FilterChips from '../Ats/SuperAdminInbox/FilterChips';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import FilterModal from '../Settings/PropertySetup/FilterModal';
import { JSONTree } from 'react-json-tree';
import { useEffect, useState } from 'react';
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ScheduleReportFilter from './ScheduleReportFilter';

export const ScheduleReportResource = () => {
    return <Resource name="getScheduleReport" list={ScheduleReport} />
}
const MyListToolBar = () => {
    const { displayedFilters, filterValues, setFilters, hideFilter, sort } =
        useListContext();
    const [searchValue, setSearchValue] = useState('');
    const handleSearchChange = (event) => {
      
        const value = event.target.value;
        setSearchValue(value)
        setFilters({ ...filterValues, q: value }, []);
        // debouncedSearch(value);
    };
    useEffect(() => {
        console.log("filterValues.q_email1", filterValues.q_email)
        if (filterValues.q == undefined) {
            console.log("filterValues.q_email2", filterValues.q_email)
            setSearchValue('');
        } else {
            console.log("filterValues.q_email3", filterValues.q_email)
            setSearchValue(filterValues.q);
        }
    }, [filterValues.q]);
    const [switchSubscriberObj] = useStore("switchSubscriberName");
    // Debounce the search function
    // const debouncedSearch = debounce((value) => {
    //   // Perform your search logic here
    //   setFilters({ ...filterValues, q: value }, []);
    // }, 500); // 500ms debounce delay

    // const handleSearchChange = (event) => {
    //   const value = event.target.value;
    //   debouncedSearch(value);
    // };
    const navigate = useNavigate();

    return (
        <Grid style={{ marginTop: 10, marginBottom: 8 }} container spacing={3}>
            {/* Left side with title */}
            <Grid item xs={12} md={2}>
                <Typography variant="h6">Schedule Report</Typography>
            </Grid>

            {/* Right side with search input, filter button, and add button */}
            <Grid
                item
                xs={12}
                md={10}
                container
                justifyContent="flex-end"
                alignItems="center"
            >
                <div className="group-btn">
                    {/* Search Input */}
                    <MuiTextField
                        placeholder="search"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        value={searchValue}
                        onChange={handleSearchChange}
                        variant="outlined"
                        size="small"
                    />

                    <ScheduleReportFilter />
                    <button
                        type="button"
                        onClick={() =>
                            navigate(`/ats/managementreport/ScheduleReport/addSchedule`, {
                                state: { isAdding: true, thirdPartyAppId: 1 },
                            })
                        }
                        className="blue-btn px-3"
                    >
                        <svg
                            className="mr-r10"
                            width="10"
                            height="11"
                            viewBox="0 0 10 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M4.33337 4.83333V0.833334H5.66671V4.83333H9.66671V6.16667H5.66671V10.1667H4.33337V6.16667H0.333374V4.83333H4.33337Z"
                                fill="white"
                            />
                        </svg>
                        Schedule
                    </button>
                </div>
            </Grid>
        </Grid>
    );
};
const MyList = ({ children, toolBar, ...props }) => {



    return (
        <ListBase
            {...props}
            perPage={100} // Number of items per page
        >
            {toolBar}
            {/* <StatsAndGraph /> */}
            <FilterChips excludeKeys={['app_invitation_type']} />
            {children}
            <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
            <br />
            <br />
            <br />
            <br />
        </ListBase>
    );
};
const ActiveBtn = () => {
    const myStyles = {
        display: "flex",
        width: "79px",
        padding: "8px 11px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "4px",
        background: "#E6FDF0",
    };
    const myTextStyles = {
        color: "var(--Success, #46A96F)",
        // textAlign: 'center',
        fontFamily: "Open Sans",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
    };
    return (
        <div style={myStyles}>
            <div style={myTextStyles}>Active</div>
        </div>
    );
};
const DeActiveBtn = () => {
    const myStyles = {
        display: "flex",
        width: "79px",
        padding: "8px 11px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "4px",
        background: "#FFF1F1",
    };
    const myTextStyles = {
        color: "var(--Success, #FF4848)",
        // textAlign: 'center',
        fontFamily: "Open Sans",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
    };
    return (
        <div style={myStyles}>
            <div style={myTextStyles}>Inactive</div>
        </div>
    );
};

const ScheduleReport = () => {
    const navigate = useNavigate();
    const [switchSubscriberObj] = useStore("switchSubscriberName");
    const isRealEstate = switchSubscriberObj?.companyType;
    return (
        <>
            <br />
            <br />
            <MyList
                toolBar={<MyListToolBar />}
                // filters={<FilterReports />}
                // actions={<ActionsReports />}
                sort={{ field: "id", order: "ASC" }}>
                {/* // filterDefaultValues={{ subscriberID: 1 }} */}
                <Datagrid bulkActionButtons={false} sx={{
                    "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
                    "& .RaDatagrid-expandIcon": { color: "#fff" },
                    "& .RaDatagrid-expandIconCell": { backgroundColor: "primary.main" },
                    "& .RaDatagrid-headerCell": {
                        fontWeight: "bold",
                        fontSize: 12,
                    },
                    "& .RaDatagrid-table": {
                        fontSize: 12,
                    },
                    "& .MuiToggleButton-root.Mui-selected": {
                        fontWeight: 700,
                    },
                }}>
                    <TextField source="Name" label={'Schedule Name'} />
                    <TextField source="ReportType" label={'Report'} />
                    <FunctionField
                        label={"Office/Properties"}
                        sortBy="OfficeName"
                        render={(record) => {
                            return <span>{record.Offices || record.Propertys}</span>
                        }
                        }
                    />
                    {/* <TextField source="id" /> */}
                  {isRealEstate == "Real Estate" && <TextField source="RoleNames" label={'Role'} />}
                  {isRealEstate == "Real Estate" &&<TextField source="Users" label={'User'} />}
                    <TextField source="ReportDuration" label={'Report Duration'} />
                    <TextField source="Frequency" label={'Frequency'} />

                    <FunctionField
                        // sortBy="AppDate"
                        label={
                            <>
                                <div>Start Date</div>
                                <div>End Date</div>
                            </>
                        }
                        render={(record) => {
                            // Format the AppDate using moment.js
                            const formattedDate = record.StartDate != null ? moment.utc(record.StartDate).format('MM/DD/YYYY') : "";
                            const formattedEndDate = record.EndDate != null ? moment.utc(record.EndDate).format('MM/DD/YYYY') : "";

                            return (
                                <>


                                    {/* Display Start Date */}
                                    {formattedDate && <p> {formattedDate}</p>}

                                    {/* Display End Date */}
                                    {formattedEndDate && <p>{formattedEndDate}</p>}
                                </>
                            );
                        }}
                    />

                    <FunctionField
                        sx={{ marginLeft: 0 }}
                        sortByOrder="ASC"
                        // sortBy={"Status"}
                        label={"Status"}
                        render={(record) => (
                            <>


                                <Box display={"flex"}>
                                    {record.Status ? <ActiveBtn /> : <DeActiveBtn />}
                                    <Box display={"flex"} sx={{ width: "100px" }}>
                                        {/* <CopyLinkHoverDropdownUserSetup record={{ ...record }} /> */}
                                        <IconButton
                                            aria-label="view details"
                                            color="primary"
                                            sx={{
                                                py: 0,
                                            }}
                                            onClick={() =>

                                                navigate(
                                                    `/ats/managementreport/scheduleReport/updateSchedule/${record?.id}`,
                                                    { state: { isAdding: false } }
                                                )


                                            }
                                            className="show_on_hover"
                                        >
                                            <ArrowForwardIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </>
                        )}

                    />


                </Datagrid>
            </MyList>


        </>
    )
}


export default ScheduleReport;