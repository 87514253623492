import React, { Suspense, useContext, useEffect, useState } from "react";
import "../../../App.css";
import ArchiveIcon from "@mui/icons-material/Archive";
import { MdUnarchive } from "react-icons/md";
import {
  ListBase,
  Title,
  ListToolbar,
  Pagination,
  ChipField,
  CustomRoutes,
  Datagrid,
  DateField,
  defaultTheme,
  DeleteButton,
  FunctionField,
  List,
  ListGuesser,
  NumberField,
  Resource,
  SearchInput,
  TextField,
  useDataProvider,
  useGetList,
  useGetOne,
  useListContext,
  useRecordContext,
  useGetIdentity,
  useCreate,
} from "react-admin";
import {
  Box,
  // MuiButton,
  ToggleButton,
  ToggleButtonGroup,
  Button as MuiButton,
} from "@mui/material";
import { NumericFormat } from "react-number-format";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  TextInput,
  required,
  useUpdate,
  useNotify,
  useRefresh,
} from "react-admin";
import { useForm, FormProvider, get } from "react-hook-form";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import FilterModel from "./ActionSubscriberInbox";
import { TableHead, TableRow, TableCell, Checkbox } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import { DatagridBody, RecordContextProvider } from "react-admin";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import { useStore } from "react-admin";
import WarningIcon from "@mui/icons-material/Warning";
import { GetsubscriberinboxListDetails } from "../GetsubscriberinboxListDetails";
import Avatar from "@mui/material/Avatar";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Archive from "../Archive";
import Paper from "@mui/material/Paper";
import NextPlanOutlinedIcon from "@mui/icons-material/NextPlanOutlined";
import TransferApp from "../TransferApp";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { useParams, useNavigate } from "react-router-dom";
import AddCoapplicant from "../AddCoapplicant";
import { Card } from "@mui/material";
import AtsDashboard from "../../../AtsDashboard";
import AddressRentEdit from "../AddressRentEdit";
import NewApplicationModel from "../NewApplicationModel";
import WarningPrelimNotice from "../WarningPrelimNotice";
import { JSONTree } from "react-json-tree";
import remove from "../../../assets/images/remove.svg";
import ArchiveAplicant from "../ArchiveAplicant";
import CancelApp from "../CancelApp";
import LinearProgress from "@mui/material/LinearProgress";
import ShareAppReport from "../ShareAppReport";
import { useSelector } from "react-redux";
import Header from "../../common/Header/Header";
import LastSeen from "../LastSeen";
import { useLocation } from "react-router-dom";
import DateComponent from "../DateComponent";
import { DOCUPLOAD_SUB_URL_FRONT } from "../../../config";
import LaunchIcon from "@mui/icons-material/Launch";
import InputField2 from "../../common/InputComponents/InputField2";
import MainFilter from "./MainFilters";
import ActionSubscriberInbox from "./ActionSubscriberInbox";
import FilterChips from "../SuperAdminInbox/FilterChips";
const ListBaseSubscriber = (props) => {
  const { children, actions, filters, title, enableDashboardGraph = true, ...rest } = props;
  const filterExludeList = ['app_invitation_type', 'dashboardOption', 'inbox_type', 'subscriberid', 'archiveCancelApp'];
  return (
    <ListBase perPage={100} {...props}>
      <ListToolbar
        filters={filters}
        actions={actions}
      />
      {enableDashboardGraph && <AtsDashboard />}
      {!enableDashboardGraph && <br />}
      
      <FilterChips excludeKeys={filterExludeList} />
      <div style={{marginTop:'5px'}}>
      {children}
      </div>
      <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
      <br />
      <br />
      <br />
      <br />
    </ListBase>
  );
};

export default ListBaseSubscriber;