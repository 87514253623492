import React, { useEffect, useState, useRef, useMemo, forwardRef, useImperativeHandle } from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DevTool } from "@hookform/devtools";
import { toast, ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Accordion,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  AccordionDetails,
  Snackbar,
} from "@mui/material";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsCheck2Circle } from "react-icons/bs";
import MuiAlert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { RiInformationLine } from "react-icons/ri";
import { JSONTree } from "react-json-tree";

// New import
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useCreate, useGetIdentity, useGetOne, useNotify, useRefresh } from "react-admin";
// import PersonalDetailSection from "./PersonalDetailSection";
import debouce from "lodash.debounce";
import SsnSection from "./SsnSection";
import InputField2 from "../../common/InputComponents/InputField2";
import { RootState } from "../../../Store/store";
import { useUserRole } from "../../../ContextApi/UserRoleContext";


function validateDOB(dob) {
  var today = new Date();
  var minAge = new Date(today.getFullYear() - 100, today.getMonth(), today.getDate());
  var maxAge = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

  if (dob > maxAge || dob < minAge) {
    return false;
  }
  return true;
}

const SsnAccordion = forwardRef((props: any, ref) => {

  const submitBtnRef = useRef<HTMLButtonElement>(null);

  // const [saveIdDetails, result] = useSaveIdDetailsMutation();
  const refresh = useRefresh();
  const notify = useNotify();
  const [create, { isLoading, error }] = useCreate();
  const saveIdDetails = (payload: any) => {
    return create(
      "saveiddetail",
      { data: payload },
      {
        onSuccess: (data) => {
          notify(`Request processed successfully.`, { type: "success", anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          refresh();
          // reset();
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, { type: "error", anchorOrigin: { vertical: 'top', horizontal: 'right' } }),
      }
    );
  };
  // const trn_id = useSelector(
  //   (state) => state.persistedReducer.loginSlice.trnId
  // );

  const trn_id = props.Trnid; //useSelector((state: any) => state?.users?.userTrnId);

  // Need to define in redux
  const nameMatched = {
    applicantNameMatched: true,
    applicantDOBMatched: true,
  };

  // get Identification Data
  const { data: getIdentificationData, isLoading: isLoadingData } = useGetOne(
    "ssnGetidentification",
    {
      // id:27783,
      id: trn_id,
    }, {
    refetchOnWindowFocus: false
  }
  );
  // const getIdentificationData = useGetIdentificationDataQuery(trn_id);

  // const formSectionSubmit = useSelector(
  //   (state) => state.formSubmit.fromSectionSubmit
  // );
  const dispatch = useDispatch();

  const notEmptyFieldsMethod = (
    value,
    path,
    createError,
    myFailureMessage,
    setNameError
  ) => {
    try {
      if (value) {
        // 
        setNameError(false);
        return true;
      }
      // 

      setNameError(true);
      return createError({ path, message: myFailureMessage });
    } catch (error) {
      // console.error("🚀 ~ file: identification.js ~ line 96 ~ error", error);
    }
  };


  const validateSSNFormates = (ssnNo, path, createError, myFailureMessage) => {
    try {
      switch (ssnNo) {
        case "111-11-1111":
      
          return createError({ path, message: myFailureMessage });
        case "222-22-2222":
      
          return createError({ path, message: myFailureMessage });
        case "333-33-3333":
      
          return createError({ path, message: myFailureMessage });
        case "444-44-4444":
      
          return createError({ path, message: myFailureMessage });
        case "555-55-5555":
      
          return createError({ path, message: myFailureMessage });
        case "666-66-6666":
      
          return createError({ path, message: myFailureMessage });
        case "777-77-7777":
      
          return createError({ path, message: myFailureMessage });
        case "888-88-8888":
      
          return createError({ path, message: myFailureMessage });
        case "999-99-9999":
      
          return createError({ path, message: myFailureMessage });
        case "123-45-6789":
      
          return createError({ path, message: myFailureMessage });
        default:
          return true;
      }
    } catch (error) {
      console.error("🚀 ~ file: identification.js ~ line 160 ~ error", error);
    }
  };

  const ssn_data_schema = yup.object().shape({
    isUS_citizen: yup.string().nullable(), //typeError("required").required("required"),
    SSNNumber: yup
      .string()
      .nullable()
    .when("isUS_citizen", {
      is: (value) => {
        return value === "Yes";
      },
      then: yup
        .string()
        .typeError("required")
        .required("required")
        // .matches(/^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$/, 'Please Enter valid SSN Number')
        .matches(/^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/, 'Please Enter valid SSN Number')
        .test("validate-ssn", function (value) {
          const { path, createError } = this;
          const myFailureMessage = "Please Enter valid SSN Number";
          try {
            if (value) {
              return validateSSNFormates(
                value,
                path,
                createError,
                myFailureMessage
              );
            }
          } catch (error) {
            // 
          }
        }),
    }),
    availableToUpload_ssn: yup
      .string()
      .typeError("required")
      .required("required"),
    file: yup
      .string()
      .nullable()
      .typeError("front file required")
      .when("availableToUpload_ssn", {
        is: (value) => {
          return value == "Yes";
        },
        then: yup
          .string()
          .typeError("SSN file is required")
          .required("SSN file is required"),
      }),
    other_reason: yup.string().when("no_doc_reason", {
      is: (value) => {
        return value == "other";
      },
      then: yup.string().typeError("required").required("Required")
        .matches(/^$|^\S+.*/g, "Enter a valid value"),
      otherwise: yup.string().nullable(),
    }),
  });
  const { role } = useUserRole();

  // const role = 'applicant'
  const { identity, isLoading: identityLoading } = useGetIdentity();
  const userRoleRedux = useSelector((state: RootState) => state?.users?.role);
  const userRole = role || userRoleRedux;
  const schema = yup.object().shape({
    ssn_data: ssn_data_schema
  });
  const schemaNotRequired = yup.object({
    availableToUpload_id: yup.string().nullable(),
  });
  const getSchema = () => {
    // if (userRole == "Docuverus Super Admin") return schemaNotRequired;
    // if (props?.required) {
      // console.log("check one")
      // if (userRole == "applicant") return schema;
       return schema;
    // }
    // return schemaNotRequired;
  };

  const methods = useForm({
    // resolver: !viewOnlyFlag && yupResolver(schema),
    resolver: yupResolver(getSchema()),
    mode: "onChange",
  });
  const { setValue, getValues, watch } = methods;
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userDOB, setUserDOB] = useState("");

  useEffect(() => {
    const data_form_value = methods.getValues();
    const api_data = getIdentificationData?.data;
    // 
    // 
    if (Object.keys(data_form_value)?.length) {
      const mapData = {
        ...api_data,
        ssn_data: {
          ...api_data?.ssn_data,
          SSNNumber: !data_form_value?.ssn_data.SSNNumber
            ? api_data?.ssn_data?.SSNNumber
            : data_form_value?.ssn_data?.SSNNumber,
          isUS_citizen: !data_form_value?.ssn_data?.isUS_citizen
            ? api_data?.ssn_data?.isUS_citizen
            : data_form_value?.ssn_data?.isUS_citizen,
          availableToUpload_ssn: !data_form_value?.ssn_data?.availableToUpload_ssn
            ? api_data?.ssn_data?.availableToUpload_ssn
            : data_form_value?.ssn_data?.availableToUpload_ssn,
        },
      };
      methods.reset(mapData);
      setUserFirstName(
        !data_form_value?.applicant?.first_name
          ? api_data?.applicant?.first_name
          : data_form_value?.applicant?.first_name
      );
      setUserLastName(
        !data_form_value?.applicant?.last_name
          ? api_data?.applicant?.last_name
          : data_form_value?.applicant?.last_name
      );
      setUserDOB(
        !data_form_value?.applicant?.DOB
          ? moment(api_data?.applicant?.DOB).format("MM/DD/YYYY")
          : moment(data_form_value?.applicant?.DOB).format("MM/DD/YYYY")
      );
    } else {
      methods.reset(api_data);
    }
  }, [getIdentificationData?.data, methods]);

  const onSubmitDetails = async (fromData) => {

    if (fromData?.ssn_data?.no_doc_reason === "other") {
      fromData.ssn_data.no_doc_reason = fromData?.ssn_data?.other_reason;
    }
    const payload = {
      trn_id,
      ...fromData,
    };
    saveIdDetails(payload);
  };

  const onSubmit = async (fromData) => {
    const postData = {
      ...fromData,
      ssn_data: {
        ...fromData?.ssn_data,
        other_reason: fromData?.ssn_data?.availableToUpload_ssn == "No" && fromData?.ssn_data?.no_doc_reason == "other" ? fromData?.ssn_data?.other_reason : null,
      }
    }
    // setOpen(false);
    onSubmitDetails(postData);


  };

  //  new states
  const [expandIdSection, setExpandIdSection] = React.useState(true);

  const handleSubmitManual = () => {
    // onSubmit(methods.getValues());
    submitBtnRef?.current?.click();
  };
  const handleAPIDebounced = useMemo(() => {
    return debouce(handleSubmitManual, 3000);
  }, []);

  async function handleClickByRef() {
    submitBtnRef?.current?.click();
    const isValidForm = await methods?.trigger();



    return isValidForm;
  }

  useImperativeHandle(ref, () => ({ handleClickByRef, handleSubmitManual }));

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <fieldset>
          <div className="info-toggle">
            <div className="toggle-heads">
              <div className="row align-items-center justify-content-between ">
                <div className="col">
                  <div className="d-flex align-items-center">
                    <b className="ms-3 me-2">Social Security Number</b>
                  </div>
                </div>

                <div className="col text-end m-1">
                  <IconButton
                    className="print-hide"
                    onClick={() => setExpandIdSection((v) => !v)}
                    color="primary"
                  >
                    {!expandIdSection ? <AddIcon /> : <RemoveIcon />}
                  </IconButton>
                </div>
              </div>

              <div
                className={` ${!expandIdSection ? "d-none" : "d-display"
                  } card-body cust-card-appl`}
              >
                {/* <form> */}
                <div
                  className="card card-body w-100"
                  style={{ paddingTop: 0 }}
                >
                  <>
                    <SsnSection required={props?.required} Trnid={props?.Trnid} handleAPIDebounced={handleAPIDebounced} />
                    <button
                      type="submit"
                      ref={submitBtnRef}
                      className="d-none"
                    >
                      submit
                    </button>
                    <div className="d-flex flex-wrap justify-content-between pb-2 print-hide">
                      {userRole !== 'applicant' && <button
                        type="submit"
                        className="btn btn-primary print-hide"
                        style={{
                          backgroundColor: "#2dc3e8",
                          fontFamily: "OpenSans-Bold",
                          color: "white",
                          marginLeft: 18,
                          padding: "8px 15px 8px 15px",
                        }}
                      >
                        {" "}
                        Save
                      </button>}
                    </div>
                  </>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </FormProvider>
  )
})

export default SsnAccordion
