import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/DocuVerusLogoorg.png";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { FormProvider, useForm } from "react-hook-form";

import { Outlet, Link, useNavigate, Navigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import InputField2 from "../../componets/common/InputComponents/InputField2";
import { Button, useCreate, useNotify, useRefresh, useGetList } from "react-admin";
import { CircularProgress } from "@mui/material";
import { useLogin, Notification, useLogout } from "react-admin";
import "../../assets/styles/style.css";
import { JSONTree } from "react-json-tree";


const TempPassWordResetUserSetup = (props) => {
  const login = useLogin();
  const notify = useNotify();
  const [loginResponse, setLoginResponse] = useState<any>(null);
  const [create, { isLoading, error }] = useCreate();
  const [errorMessage, setErrorMessage] = useState(" ");
  const [style, setStyle] = useState({ color: "red", percentage: "0%" });

  const schema = yup.object().shape({
    // username: yup.string().required("required"),
    oldpassword: yup.string().required("required"),
    newpassword: yup.string().required("required").notOneOf([yup.ref("oldpassword"), null], "New password must be different from the old password.").matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Use 8 or more characters with a mix of letters, numbers & symbols"
    ),
    confirmpassword: yup.string().required("required").oneOf([yup.ref("newpassword"), null], "Password must match"),
  });

  const methods = useForm({
    defaultValues: {
      // username: "",
      oldpassword: "",
      newpassword: "",
      confirmpassword: "",
    },
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  useEffect(() => {
    document.title = "Docuverus";
  }, []);
  const logout = useLogout();

  const onSubmit = (data) => {

    const sendData = {
      // username: data?.username,
      oldpassword: data?.oldpassword,
      newpassword: data?.newpassword,
      // confirmpassword: data?.confirmpassword,
    };
    create(
      "changePassword",
      { data: sendData },
      {
        onSuccess: (data) => {
          notify(`${data?.message}`, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          setErrorMessage("");
          methods?.reset();
          logout();
        },

        onError: (e: any) =>
          notify(`Operation fail:${e.message}`,
            { type: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })

      }
    );

  };

  return (
    <>
      <section className="login-wrap">
      {/* <div className="alert alert-warning alert-dismissible fade show" role="alert">
          <strong>Docuverus has released our 4.0 upgrade!</strong> Please <a href="#" onClick={(e) => {
            e.preventDefault(); localStorage.clear(); setTimeout(() => {
              window.location.href = 'https://app.dvapply.com/'
            }, 300)
          }}>click this</a> to reset your browser cache. You will not need to do this again. We apologize for the inconvenience.
          <button type="button" className="btn close" data-dismiss="alert" aria-label="Close">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
            </svg>
          </button>
        </div> */}
        <div className="login-cell">
          <div className="login-block">
            <div className="l-logo-block text-center">
              <img src={Logo} alt="Docuverus" title="Docuverus" />
            </div>
            <br />
            {errorMessage && <p style={{ color: "red", textAlign: 'center', fontSize: '12px', fontWeight: 'bold', opacity: '10px' }}>{errorMessage}</p>}
            <div className="auth-form mr-t30">

              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  {/* <div className="form-group cus-input-group mr-b20">
                    <InputField2
                      label="User Name"
                      source={`username`}
                      placeholder="Enter Username here"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                    />
                  </div> */}
                  <div className="form-group cus-input-group mr-b20">
                    <InputField2
                      label="Old Password"
                      source={`oldpassword`}
                      type="password"
                      placeholder="Enter Old Password here"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                    />
                  </div>

                  <div className="form-group cus-input-group mr-b20">
                    <InputField2
                      label="New Password"
                      source={`newpassword`}
                      type="password"
                      placeholder="Enter New Password here"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                      handleOnChange={(e) => {
                        const strongRegex = new RegExp(
                          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                        );
                        const mediumRegex = new RegExp(
                          "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
                        );
                        if (strongRegex.test(e.target.value)) {
                          setStyle({ color: "green", percentage: "100%" });
                        } else if (mediumRegex.test(e.target.value)) {
                          setStyle({ color: "yellow", percentage: "70%" });
                        } else {
                          const percentage = (e.target.value.length / 8) * 40;
                          setStyle({
                            color: "red",
                            percentage: percentage + "%",
                          });
                        }
                      }}
                    />
                    {methods.watch("newpassword") !== "" && (
                      <div className="pass-progress mr-t10">
                        <div
                          className="pass-progress-bar"
                          style={{
                            width: style.percentage,
                            background: style.color,
                          }}
                        ></div>
                      </div>
                    )}
                    {methods?.formState?.errors?.newpassword?.message && (
                      <div className="pass-msg-box">
                        <h5>
                          <span>Weak</span> -{" "}
                          {`${methods?.formState?.errors?.newpassword?.message}`}
                        </h5>
                      </div>
                    )}
                  </div>
                  <div className="form-group cus-input-group mr-b20">
                    <InputField2
                      label="Confirm Password"
                      source={`confirmpassword`}
                      type="password"
                      placeholder="Enter Confirm Password here"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                    />
                  </div>

                  {isLoading ? (
                    <div className="static-d-info d-flex justify-content-center align-items-center">
                      <CircularProgress size="20px" />
                      <h5>&nbsp; &nbsp; Please wait...</h5>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center">
                      <button type="submit" className="l-btn">
                        Set Password
                      </button>
                    </div>
                  )}
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default TempPassWordResetUserSetup;
