import React, { createContext, useEffect, useState } from 'react';
import '../../App.css';
import {
    Datagrid,
    DatagridBody,
    DateField,
    LinearProgress,
    ListBase,
    ListToolbar,
    Pagination, RecordContextProvider, SearchInput, TextField, useListContext,
    useNotify,
    useStore,
} from 'react-admin';
import { Grid, ToggleButton, ToggleButtonGroup, TextField as MuiTextField, InputAdornment,} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Card } from '@mui/material';
import { Box } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { TableHead, TableRow, TableCell } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import LogReportsDashboard from './LogReportsDashboard';
import { debounce } from 'lodash';

import SearchIcon from "@mui/icons-material/Search";

export const ToogleFilterInboxType = (props) => {

    const { setFilters, filterValues } = useListContext();
    useEffect(() => {
        if (!filterValues.app_invitation_type) {
            setFilters({ 'app_invitation_type': "1" }, ['app_invitation_type'])
        }
    }, [])

    return (
        <>
            <ToggleButtonGroup
                sx={{ paddingBottom: 0.5, '& .Mui-selected': { fontWeight: 700 } }}
                size="small"
                value={filterValues.app_invitation_type}
                color="primary"
                exclusive
                onChange={(event: React.MouseEvent<HTMLElement>,
                    newAlignment: string) => {
                    if (newAlignment) {
                        setFilters({ 'app_invitation_type': newAlignment }, ['app_invitation_type'])
                    }
                }}
                aria-label="Inbox"
            >
                <ToggleButton value={"1"} aria-label="Application">
                    Application
                </ToggleButton>
                <ToggleButton value={"2"} aria-label="Docupload">
                    Docupload
                </ToggleButton>
                <ToggleButton value={"3"} aria-label="Docupload">
                    Guest Card
                </ToggleButton>
            </ToggleButtonGroup>
        </>)
};

export const FilterListInbox = (props) => {
    return <>
        <IconButton sx={{
            border: '1px solid',
            borderRadius: ' 5px',
            padding: '6px',
            marginLeft: '-10px',
            marginBottom: '4.4px'
        }} size='medium' aria-label="delete">
            <FilterListIcon />
        </IconButton>
    </>
}

export const filters = [
    <ToogleFilterInboxType source="app_invitation_type" alwaysOn />,
    <SearchInput variant="outlined" sx={{ backgroundColor: '#fff' }} placeholder="Search" InputLabelProps={{
        shrink: true,
    }} source="q" alwaysOn />,
];

const searchFilters = [
    <SearchInput variant="outlined" sx={{ backgroundColor: '#fff' }} placeholder="Search" InputLabelProps={{
        shrink: true,
    }} source="q" alwaysOn />,
];

const MyListToolBar = () => {
    const { displayedFilters, filterValues, setFilters, hideFilter, sort } =
      useListContext();
    const navigate = useNavigate();
    const [q, setQ] = React.useState<null | string>(filterValues?.q);
    // Debounce the search function
    const debouncedSearch = debounce((value) => {
      // Perform your search logic here
      setFilters({ ...filterValues, q: value }, []);
    }, 500); // 500ms debounce delay
  
    const handleSearchChange = (event) => {
      const value = event.target.value;
      debouncedSearch(value);
    };
  
    
    return (
      <Grid style={{ marginTop: 10, marginBottom: 8 }} container spacing={3}>
        {/* Left side with title */}
        <Grid item xs={12} md={6}>
        
        <p style={{font:"Open Sans",fontWeight:600,fontSize:'18px'}}>User Activity Logs</p>
     
      
  
        </Grid>
  
        {/* Right side with search input, filter button, and add button */}
        <Grid
          item
          xs={12}
          md={6}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          <div className="group-btn">
            {/* Search Input */}
            <MuiTextField
              placeholder="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              defaultValue={filterValues.q}
              onChange={handleSearchChange}
              variant="outlined"
              size="small"
            />
            
            
           
  
    
          </div>
        </Grid>
      </Grid>
    );
  };


const MyList = (props) => {
    const { children } = props;
    return (
        <ListBase perPage={100} {...props}>
            {/* <Header /> */}
            <br />
           <MyListToolBar />
            <LogReportsDashboard />
            <br />
            <Card>
                {children}
            </Card>
            <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
        </ListBase>
    )
};

const MyListAditLog = (props) => {
    const { children } = props;
    return (
        <ListBase perPage={100} {...props}>
            {/* <Header /> */}
            <br />
            <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="text.primary"> Audit Logs</Typography>
                </Breadcrumbs>
            </div>
            <br />
            <Card>
                {children}
            </Card>
            <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
        </ListBase>
    )
};

const MyDatagridRow = (props) => {
    const { record, id, onToggleItem, children, selected, selectable, isRowExpandable, isRowSelectable, expand } = props;
    return (
        <RecordContextProvider value={record}>
            <TableRow className={props.className}>
                {React.Children.map(children, field => {
                    if (field)
                        return (
                            <TableCell data-label={field?.props?.label || field?.props?.source} key={`${id}-${field?.props?.source || field?.props?.label}`}>
                                {field}
                            </TableCell>
                        )
                })}
            </TableRow>
        </RecordContextProvider>
    )
};


const MyDatagridTableHeaderCell = ({ child }) => {
    const {
        sort,
        setSort
    } = useListContext();
    const sortBy = child.props.sortBy || child.props.source;
    return (<>
        <Box display={'flex'} alignItems={'center'} justifyContent={'start'}>
            <span style={{ padding: '0px 2px' }}>
                {sortBy === sort.field ? sort.order == "ASC" ? <ArrowUpwardIcon style={{ fontSize: 18 }} /> : <ArrowDownwardIcon style={{ fontSize: 18 }} /> : null}
            </span>
            <span style={{ cursor: 'pointer', fontWeight: 'bold', color: sortBy === sort.field ? '#000' : '#909090' }} onClick={() => { sortBy && setSort({ 'field': sortBy, 'order': 'ASC' }) }}>{child.props.label || child.props.source}</span>
        </Box>

    </>);
}


const MyDatagridHeader = (props) => {
    const { children, isRowSelectable, isRowExpandable } = props;
    return (
        <TableHead sx={{ height: 50, backgroundColor: '#FFFFFF', border: '0.5px solid #E0E0E0' }}>
            <TableRow>
                {isRowSelectable && <TableCell></TableCell>}{/* empty cell to account for the select row checkbox in the body */}
                {React.Children.map(children, child => {
                    if (child)
                        return (
                            <TableCell sortDirection="asc" key={child.props.source}>
                                <MyDatagridTableHeaderCell child={child} />
                            </TableCell>
                        )
                })}
                {isRowExpandable && <TableCell></TableCell>}
            </TableRow>
        </TableHead>
    )
};


const MyDatagridBody = props => <DatagridBody {...props} row={<MyDatagridRow {...props} />} />;
const MyDatagrid = props => <Datagrid {...props} body={<MyDatagridBody {...props} />} />;

export const GetLogActivityList = () => {
    const defaultFilterValue = { app_invitation_type: 2, dashboardOption: 1 }
    return (
        <MyList sort={{ field: 'Lastlogindate', order: 'DESC' }} actions={null} filter={defaultFilterValue} filterDefaultValues={defaultFilterValue} exporter={false} title={'Inbox'}>
            <DatagridTable />
        </MyList>
    )
};

export const GetAuditLogist = () => {
    const { applicantId } = useParams();

    const defaultFilterValue = { applicantId }
    return (<>
        <MyListAditLog sort={{ field: 'CreatedDate', order: 'DESC' }} actions={null} filter={defaultFilterValue} filterDefaultValues={defaultFilterValue} exporter={false} title={'Inbox'}>
            <DatagridTableAuditLog />
        </MyListAditLog>
    </>

    )
};

const DatagridTableAuditLog = (props) => {

    const { data, isLoading, isFetching } = useListContext();
    useEffect(() => {
        if (isFetching || isLoading)
            window.document.title = "Loading";
        else {
            window.document.title = "Docuverus Dashboard";
        }
    }, [isFetching, isLoading])
    // get user role from  global store

    return (
        <>
            {(isFetching || isLoading) && <LinearProgress />}
            <MyDatagrid isRowExpandable={true} isRowSelectable={false}
                header={<MyDatagridHeader />}
                sx={{
                    '& .RaDatagrid-rowEven': { backgroundColor: "#F9F9F9" },
                    '& .RaDatagrid-expandIcon': { color: '#fff' },
                    '& .RaDatagrid-expandIconCell': { backgroundColor: "primary.main" },
                    '& .RaDatagrid-headerCell': {
                        fontWeight: 'bold',
                        fontSize: 12
                    },
                    '& .RaDatagrid-table': {
                        fontSize: 12
                    },
                    '& .MuiToggleButton-root.Mui-selected': {
                        fontWeight: 700
                    },
                    '& .MuiTableCell-root.MuiTableCell-head:last-child': { width: "12%" },
                }}>

                <TextField label={'Id'} source="id" />
                <TextField source="UserName" />
                <TextField source="ActionArea" label="Action" />
                <TextField source="Browser_Version" label={'browser'} />
                <TextField source="Device" />
                <TextField source="OS" />
                <TextField source="IP_Location" label={'Ip address'} />
                <TextField source="NewValue" />
                <TextField source="OldValue" />
                <TextField source="ColumnName" />

                <DateField source="CreatedDate" label="Created" locales="en-US" />

            </MyDatagrid></>)
};


const DatagridTable = (props) => {

    const { data, isLoading, isFetching } = useListContext();
    useEffect(() => {
        if (isFetching || isLoading)
            window.document.title = "Loading";
        else {
            window.document.title = "Docuverus Dashboard";
        }
    }, [isFetching, isLoading])
    // get user role from  global store

    return (
        <>
            {/* {(isFetching || isLoading) && <LinearProgress />} */}
            <MyDatagrid isRowExpandable={true} isRowSelectable={false}
                header={<MyDatagridHeader />}
                sx={{
                    '& .RaDatagrid-rowEven': { backgroundColor: "#F9F9F9" },
                    '& .RaDatagrid-expandIcon': { color: '#fff' },
                    '& .RaDatagrid-expandIconCell': { backgroundColor: "primary.main" },
                    '& .RaDatagrid-headerCell': {
                        fontWeight: 'bold',
                        fontSize: 12
                    },
                    '& .RaDatagrid-table': {
                        fontSize: 12
                    },
                    '& .MuiToggleButton-root.Mui-selected': {
                        fontWeight: 700
                    },
                    '& .MuiTableCell-root.MuiTableCell-head:last-child': { width: "12%" },
                }}>
                <TextField source="Username" />
                <TextField source="Subscriber" />
                <TextField source="Officeproperty" label="PROPERTY/OFFICE" />
                <TextField source="Browser" />
                <TextField source="Device" />
                <TextField source="OS" />
                <TextField source="Location" />
                <TextField source="Ipaddress" label="IP ADDRESS" />
                <DateField source="Lastlogindate" label="LAST LOGIN" locales="en-US" />

            </MyDatagrid></>)
};


