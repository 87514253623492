import React, { useEffect, useState } from "react";
import {
  Datagrid,
  FunctionField,
  TextField,
  useResourceDefinition,
  useListContext,
  useStore,
} from "react-admin";
import { ActiveBtn, DeActiveBtn, MyList } from "./PropertyList";
import {
  Grid,
  Typography,
  TextField as MuiTextField,
  Button,
  Box,
  IconButton,
  Link
} from "@mui/material";
import { debounce, property } from "lodash";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ButtonBase from '@mui/material/ButtonBase';
import { useNavigate, useParams } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CopyLinkHoverDropdown from "./CopyLinkHoverDropdown";
import UnitsFilterModal from "./UnitsFilterModal";
import { NumericFormat } from "react-number-format";
import { ATS_API_URL } from "../../../config";
import DownloadSampleButton from "../../common/DownloadSampleButton";
import InputFileImport from "../../common/InputFileImport";

// Define a filter component for the list view
const MyListToolBar = ({ title, ...props }) => {
  const { displayedFilters, filterValues, setFilters, hideFilter, sort } =
    useListContext();
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const [tab, setTab] = useStore("unitFloorTab");
  // useEffect(()=>{
  //   setTab(1);
  // },[])
 
  // Debounce the search function
  const debouncedSearch = debounce((value) => {
    // Perform your search logic here
    setFilters({ ...filterValues, q_units: value }, []);
  }, 500); // 500ms debounce delay

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchText(value);
    debouncedSearch(value);
  };

  return (
    <Grid style={{ marginBottom: 14 }} container spacing={3}>
      {/* Left side with title */}
      <Grid item xs={12} md={2}>
        <Typography variant="h6">{title}</Typography>
      </Grid>

      {/* Right side with search input, filter button, and add button */}
      <Grid
        item
        xs={12}
        md={10}
        container
        justifyContent="flex-end"
        alignItems="center"
      >
        <div className="group-btn">
          {/* Search Input */}
          <MuiTextField
            placeholder="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            defaultValue={filterValues.q_units}
            onChange={handleSearchChange}
            variant="outlined"
            size="small"
          />

          {/* Filter Button */}
          <UnitsFilterModal />
          {/* Filter Button */}
          {/* file export Button */}
          <button
            onClick={() =>
              window.open(
                `${ATS_API_URL}/subscriber/download-xls?downloadType=Unit&subscriberID=${
                  switchSubscriberObj?.value
                }&propertyID=${id}&_sort=${sort?.field || ""}&_order=${
                  sort?.order || ""
                }&_search=${filterValues.q_units || ""}&status=${
                  filterValues?.unitsStatus
                    ? filterValues?.unitsStatus.toString()
                    : ""
                }`
              )
            }
            type="button"
            className="g-line-btn"
            title="Export all data"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_14375_6193)">
                <path
                  d="M8.66667 8.4987H10.6667L8 11.1654L5.33333 8.4987H7.33333V5.83203H8.66667V8.4987ZM10 3.16536H3.33333V13.832H12.6667V5.83203H10V3.16536ZM2 2.49336C2 2.12803 2.298 1.83203 2.666 1.83203H10.6667L14 5.16536V14.494C14.0006 14.5816 13.984 14.6684 13.951 14.7495C13.9181 14.8306 13.8695 14.9045 13.808 14.9668C13.7466 15.0291 13.6734 15.0788 13.5928 15.1128C13.5121 15.1469 13.4255 15.1648 13.338 15.1654H2.662C2.48692 15.1641 2.31934 15.0941 2.19548 14.9704C2.07161 14.8466 2.0014 14.6791 2 14.504V2.49336Z"
                  fill="#2DC3E8"
                />
              </g>
              <defs>
                <clipPath id="clip0_14375_6193">
                  <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
          {/*  Import CSV button */}
          {/* Download Sample Button*/}
          <DownloadSampleButton downloadType={"Unit"} />
          {/* Download Sample Button*/}
          {/* Import CSV Button*/}
          <InputFileImport
            buttonText={"Import CSV"}
            importType={"Unit"}
            propertyID={id}
          />
          {/* Import CSV Button*/}
          {/* Add unit button */}
          <button
            type="button"
            onClick={() =>
              navigate(
                `/ats/settings/propertySetup/propertyDetails/${id}/addNewUnit`,
                { state: { isAdding: true, propertyId:id } }
              )
            }
            className="blue-btn px-3"
          >
            <svg
              className="mr-r10"
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.33337 4.83333V0.833334H5.66671V4.83333H9.66671V6.16667H5.66671V10.1667H4.33337V6.16667H0.333374V4.83333H4.33337Z"
                fill="white"
              />
            </svg>
            Unit
          </button>
        </div>
      </Grid>
    </Grid>
  );
};

const UnitsList = (props) => {
  const { options } = useResourceDefinition();
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <MyList
      toolBar={<MyListToolBar title={"Units List"} />}
      filter={{ subscriberID: switchSubscriberObj?.value, propertyID: id }}
    >
      <Datagrid
        sx={{
          "& .RaDatagrid-headerCell": {
            fontWeight: "bold",
            fontSize: 12,
          },
          "& .RaDatagrid-rowOdd": { backgroundColor: "#FFFFFF" },
        }}
        bulkActionButtons={false}
      >
        {/* <TextField source="UnitName" /> */}
        <FunctionField 
        sortByOrder="ASC"
        sortBy={"UnitName"}
        label={"Unit Name"}
        render={(record)=>(
          <Link   onClick={() =>
            navigate(
              `/ats/settings/propertySetup/propertyDetails/${id}/unitDetails/${record?.id}`,
              { state: { isAdding: false, propertyId:id } }
            )
          } underline="none" style={{cursor:"pointer"}} rel="noopener noreferrer" >{record.UnitName} </Link>
        )}/>
        <FunctionField
          source="MinimumRent"
          render={(record) => (
            <NumericFormat
              thousandSeparator=","
              displayType="text"
              thousandsGroupStyle="thousand"
              decimalScale={2}
              prefix="$"
              fixedDecimalScale
              value={record.MinimumRent}
            />
          )}
        />
        <FunctionField
          source="MaximumRent"
          render={(record) => (
            <NumericFormat
              thousandSeparator=","
              displayType="text"
              thousandsGroupStyle="thousand"
              decimalScale={2}
              prefix="$"
              fixedDecimalScale
              value={record.MaximumRent}
            />
          )}
        />
        <FunctionField
          source="Deposit"
          label="Deposit"
          sortBy="Deposit"
          render={(record) => (
            <NumericFormat
              thousandSeparator=","
              displayType="text"
              thousandsGroupStyle="thousand"
              decimalScale={2}
              prefix="$"
              fixedDecimalScale
              value={record.Deposit}
            />
          )}
        />
        <FunctionField
          sortByOrder="DESC"
          sortBy="Status"
          label={"Status"}
          render={(record) => (record.Active ? <ActiveBtn /> : <DeActiveBtn />)}
        />
        <FunctionField
          label={""}
          render={(record) => (
            // <Box display={"flex"}>
            <Box display={"flex"} sx={{ width: "80px" }}>
              <CopyLinkHoverDropdown record={{...record, linkType: "unCompleted",applicationUrl:true}} />
              <IconButton
                aria-label="view details"
                color="primary"
                sx={{
                  py: 0,
                }}
                className="show_on_hover"
                onClick={() =>
                  navigate(
                    `/ats/settings/propertySetup/propertyDetails/${id}/unitDetails/${record?.id}`,
                    { state: { isAdding: false, propertyId:id } }
                  )
                }
              >
                <ArrowForwardIcon />
              </IconButton>
            </Box>
          )}
        />
        {/* Add more fields as needed */}
      </Datagrid>
    </MyList>
  );
};

export default UnitsList;
