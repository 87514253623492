import React, { useEffect } from "react";
import EmailIcon from "@mui/icons-material/Email";
import { Box, Button, InputAdornment } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  TextInput,
  required,
  useUpdate,
  useNotify,
  useRefresh,
  useGetList,
  SelectField,
  SelectInput,
  useCreate,
} from "react-admin";
import { useForm, FormProvider, get } from "react-hook-form";
import { Button as MuiButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import mail from "../../assets/images/mail.svg";
import InputSelectField from "../../common/InputComponents/InputSelectField";
import InputTextArea from "../../common/InputComponents/InputTextArea";
import LoadingSpinner from "../../common/loadingSpinner";
import { Add } from "@mui/icons-material";
import { JSONTree } from "react-json-tree";

type SendEmailFormProps = {
  handleClose: () => void;
  record: any[];
};

// validation schema here
const validationSchema = Yup.lazy((value) =>
  Yup.object().shape({
    pre_message: Yup.object()
      .shape({
        value: Yup.string().nullable(),
        label: Yup.string().nullable(),
      })

      .nullable(),

    message: Yup.string()
      .nullable()
      .trim("Message is required!")
      .max(5000, "maximum 5000 characters is required")
      .required("Message is required!")
  })
);

const AddCommentForm = (props) => {
  console.log("props.document_id", props.document_id)
  const documentId = [2, 1, 11, 10].includes(props.document_id) ? 2 : props.document_id;
  console.log("documentId", documentId)

  // call get pre define message data
  const { data: preDefinedMessageList, isLoading: isLoadingMessageLoading } = useGetList("getDocumentTypeWiseMessage", { filter: { document_id: documentId } });
  //   {
  //     "trn_id":62132,
  //     "id":0,
  //     "document_id":6,
  //     "source_id":1,
  //     "message":"test1 custome message updated"
  // }
  // form initial states

  const form = useForm({
    defaultValues: {
      ...props,
      pre_message: "",
      message: ""
    },
    resolver: yupResolver(validationSchema),
  });

  // Destructuring form
  const { register, watch } = form;

  const refresh = useRefresh();
  const notify = useNotify();

  // call usecreate
  const [create, { isLoading, error }] = useCreate();

  // form on submit handler
  const onSubmit = (value) => {
    // const { message_type, ...rest } = value;
    const sendData = {
      document_id: value.document_id,
      source_id: value.source_id,
      id: value.ID || 0,
      trn_id: value.trn_id,
      message: value.message,
      userRole: !!props?.isApplicantTitle ? 'applicant' : 'admin'
    };
    // alert("dfddffd")
    create(
      "addCustomValidationMessage",
      { data: sendData },
      {
        onSuccess: (data, error) => {
          notify(`Message has been sent`, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          refresh();
          props?.handleClose();
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );

  };
  if (isLoadingMessageLoading) return <>Loading...</>;
  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12 mr-b20">
             <InputSelectField
                label="Predefined Message"
                source={`pre_message`}
                options={preDefinedMessageList?.map((item) => ({
                  value: item?.Message,
                  label: item?.Message,
                }))}
                required={false}
                labelAsteriskClass={"color-red"}
                labelClass={"lable-title"}
                requiredClass={{
                  fontSize: "70%",
                }}
                onChangeCallback={() => {
                  const preMessage: any = form.getValues("pre_message");
                  const currentMailBody: any = form.getValues("message");

                  const updatedMailBody = `${currentMailBody}  ${preMessage.value}`;
                  const trimUpdatedMailBody = updatedMailBody.trim();

                  form.setValue("message", trimUpdatedMailBody, {
                    shouldValidate: true,
                    shouldTouch: true,
                  });
                }}
                fontsize="14px"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mr-b30">
              <InputTextArea
                register={"message"}
                label="Validation Message"
                placeholder="Message Text"
                labelClass={"lable-title"}
                labelAsteriskClass={"color-red"}
                additionalInputClass={"cus-control"}
                styleProperty={{ height: "auto" }}
                messageText={false}
                requiredClass={{
                  fontSize: "70%",
                }}
            />
            </div>
          </div>
          <Box display={"flex"} justifyContent={"end"}>
            <Button
              sx={{ mx: 1 }}
              onClick={props.handleClose}
              variant="outlined"
              color="secondary"
              type="button"
            >
              Cancel
            </Button>
            <Button
              startIcon={<Add />}
              sx={{ mx: 1 }}
              variant="contained"
              type="submit"
            >
              Add
            </Button>
          </Box>
        </form>
      </FormProvider>
      {/* <JSONTree data={preDefinedMessageList} /> */}
      {isLoading ? <LoadingSpinner /> : ""}
    </>
  );
};

export default function AddValidationComment(props) {
  const notify = useNotify();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [update, { data, isLoading, error }] = useUpdate();

  return (
    <>
      <MuiButton
        // sx={{
        //   ".MuiButton-startIcon": { paddingLeft: "10px" },
        //   minWidth: "80px",
        //   justifyContent: "center",
        //   display: "flex",
        //   flexDirection: "column",
        //   textTransform: "capitalize",
        // }}
        onClick={handleOpen}
        startIcon={<Add />}
        variant="text"
      >
        Add
      </MuiButton>

      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Validation Message</DialogTitle>
        <DialogContent>
          <AddCommentForm
            {...props}
            handleClose={handleClose}
            // email={props?.record?.length > 0 ? props?.record[0]?.Email : ""}
            record={props?.record}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
