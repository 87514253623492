import { useGetList, useGetOne } from "react-admin";
import { JSONTree } from "react-json-tree";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser";
import ApplyRental from "./ApplyRental";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
} from "@mui/material";
const ViewAdverseLetter = () => {
  const location = useLocation();
  const record = location.state;
  const navigate = useNavigate();

  const { data: applicantData, isLoading } = useGetList(
    "get_subscriber_inbox_detials",
    {
      filter: {
        ApplicationNumber: record?.id,
        subscriberid: record?.SubscriberID,
      },
    }
  );
  const applicantId = applicantData?.filter(
    (item) => item.ApplicantType == "Applicant"
  )[0]?.id;
  const { data } = useGetOne(
    "viewadverseletter",
    { id: record?.applicantId || applicantId, meta: { ApplicationStatus: record?.ApplicationStatus } },
    { enabled: !isLoading && !!applicantId }
  );
  return (
    <>
      <div className="home_content">
        <div className="row">
          <div className="col-12">
            <Card>
              <Typography color="primary"  onClick={() => navigate(-1)} style={{fontSize:"14px",marginTop:"10px",marginLeft:"20px",fontWeight:600,cursor: "pointer"}} >
               Back
              </Typography>
              <CardContent>
                <ApplyRental edit={true} data={record} />
              </CardContent>
            </Card>
          </div>
        </div>
        <br />
        <br />
        {parse(data?.data || "")}
      </div>
    </>
  );
};

export default ViewAdverseLetter;
